import styled from 'styled-components';

interface IEventDate {
	color?: string;
}

export const EventDate = styled.p<IEventDate>`
	color: ${ props => props.color || '#fff' };;
	font-family: ${ ( { theme } ) => theme.fonts.semiBold };
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 12px;
	text-align: center;
	text-transform: uppercase;
`;
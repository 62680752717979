/**
 * We need to remove it once uat-team-night branch merged to UAT
 */

export const SUFFIX_PUB_NUB = ( process.env.REACT_APP_PUBNUB_CHANNEL_SUFFIX || '' );

/**
 * bww-trivia-question-uat-east
 */
export const PUB_NUB_CHANNELS = {
	questions: `bww-trivia-question-${SUFFIX_PUB_NUB}`,
	answers: `bww-trivia-correct-answer-choices-${SUFFIX_PUB_NUB}`,
	events: `bww-trivia-events-${SUFFIX_PUB_NUB}`,
	herds: `bww-trivia-team`
};

import {SagaIterator} from 'redux-saga';
import {call, put, select, take} from 'redux-saga/effects';
import {fetchStatsFailed, fetchStatsSuccess, globalError, postUserLoginSuccess} from 'modules/actions';
import {PlayHubApi} from 'modules/utils';
import {ApiError} from 'modules/utils/Api';
import {isLoggedIn} from 'modules/selectors/user';

export const fetchStatsSaga = function* (): SagaIterator {
	const isUserLoggedIn = yield select( isLoggedIn );
	if ( !isUserLoggedIn ) {
		yield take( postUserLoginSuccess );
	}
	try {
		const response = yield call(PlayHubApi.Dashboard.stats);
		ApiError.CHECK(response);
		yield put(fetchStatsSuccess(response.result));
	} catch (e: any) {
		yield put( globalError( e ) );
		yield put( fetchStatsFailed() );
	}


};

import styled from 'styled-components';

export const EventCardWrapper = styled.section`
	border-radius: 10px;
	background-color: #E3E1DB;
	margin: 0 auto 30px;
	overflow: hidden;
	//min-height: 360px;
	position:relative;
	max-width: 320px;
	transition: all 0.3s ease;
	&.initial {
		background-color: #fff;
		box-shadow: 0 20px 20px 0 rgba(28,28,28,0.2);
	}
`;

import React, {Fragment} from 'react';
import styled from 'styled-components';

const UnitDisplay = styled.div`
	color: #fff;
	font-family: ${ ({theme}) => theme.fonts.normal };
	font-size: 10px;
	line-height: 10px;
	text-align: center;
	.unit {
		font-family: ${ ({theme}) => theme.fonts.bold };
		font-size: 16px;
		font-weight: bold;
		line-height: 16px;
	}
`;

interface IProps {
	days: number;
	hours_remaining: number;
	mins_remaining: number;
	seconds_remaining: number;
}


const isShowDays = (hours_remaining: number) => hours_remaining > 24;
const isShowHours = (days: number, hours_remaining: number) => days < 1 && hours_remaining > 0;
const isShowMins = (hours_remaining: number, mins_remaining: number) => hours_remaining === 0 && mins_remaining >= 1;
const isShowSeconds = (hours_remaining: number, mins_remaining: number, seconds_remaining: number) => (
	hours_remaining === 0 && mins_remaining < 1 && seconds_remaining > 0
);

export const EventTimeRemain: React.FC<IProps> = ({days, hours_remaining, mins_remaining, seconds_remaining}: IProps) => (
	<Fragment>
		{isShowDays(hours_remaining) ? (
			<UnitDisplay>
				<div className={'unit'}>{days}</div>
				<div>days</div>
			</UnitDisplay>
		) : null}
		{isShowHours(days, hours_remaining) ? (
			<UnitDisplay>
				<div className={'unit'}>{hours_remaining}</div>
				<div>hr{hours_remaining === 1 ? '' : 's'}</div>
			</UnitDisplay>
		) : null}
		{isShowMins(hours_remaining, mins_remaining) ? (
			<UnitDisplay>
				<div className={'unit'}>{mins_remaining}</div>
				<div>mins</div>
			</UnitDisplay>
		) : null}
		{isShowSeconds(hours_remaining, mins_remaining, seconds_remaining) ? (
			<UnitDisplay>
				<div className={'unit'}>{seconds_remaining}</div>
				<div>secs</div>
			</UnitDisplay>
		) : null}
	</Fragment>
);

import {mapValues} from 'lodash';
import {getRegionSelector} from 'modules/selectors/settings';
import {createSelector} from 'reselect';
import {IContestTimes, IGlobalStore} from 'modules/types';

export const getContestTimesStore = (store: IGlobalStore) => store.contest_times;
export const getContestTimes = createSelector(
	[getContestTimesStore, getRegionSelector],
	(times: IContestTimes, region) => {
		return mapValues(times, (value) => value[ region ]);
	}
);

import styled from 'styled-components';

interface IEventCardName {
	color?: string;
}

export const EventCardName = styled.p<IEventCardName>`
	color: ${ props => props.color || '#fff' };
	font-family: ${ ( { theme } ) => theme.fonts.bold };
	font-size: 20px;
	font-weight: bold;
	line-height: 18px;
	text-align: center;
	margin-bottom: 10px;
`;
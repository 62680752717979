import * as Sentry from '@sentry/browser';
import {get, isEmpty, toArray} from 'lodash';
import {postReAuthorizeUser} from 'modules/actions';
import {IGlobalStore, IModelUser} from 'modules/types';
import {IS_UAT, User} from 'modules/utils';
import React from 'react';

import TagManager from 'react-gtm-module';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import styled from 'styled-components';


const MessageBox = styled.div`
  color: #000;
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  font-size: 120%;
  text-align: center;
  padding: 20px;
`;

interface IOwn {
	user: any
}

const mapStateToProps = (state: IGlobalStore) => ({
	is_authorized: state.user.is_authorized,
	is_data_requested: state.user.is_data_requested,
	is_auth_flow_ended: state.user.is_auth_flow_ended,
	is_logged_success: state.user.is_logged_in,
	error: state.user.error || '',
	isGuestVerified: state.guestUser.isGuestVerified
});

const mapDispatchToProps = {
	postReAuthorizeUser,
};

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & RouteComponentProps;

class HOCBwwLoginComponent extends React.Component<Props, IOwn> {

	public state = {
		user: User.GET_USER_OBJECT() as any
	}

	// Todo: Refactor
	public componentDidMount() {
		const user = this.state.user;

		this.initializeGTM();

		if ( user.raw && Boolean(user.email) ) {
			this.authUser(user)
		} else {
			this.initializePostMessageListener();
		}
	}

	public initializePostMessageListener() {
		window.addEventListener('message', (data) => {
			const sessions = data.data;
			if (sessions.blazin_rewards && sessions.blazin_hash) {
				User.SET_ORIGINAL_COOKIES(sessions.blazin_rewards, sessions.blazin_hash);

				const new_user = User.GET_USER_OBJECT() as any;

				if ( new_user.raw && Boolean(new_user.email) ) {
					this.authUser(new_user)
				}

				this.setState({
					user: new_user
				})
			}
		})
	}

	public authUser(user: IModelUser) {
		this.props.postReAuthorizeUser({user})
	}


	public isProfileExist() {
		const {
			user,
		} = this.state;

		try {
			return Boolean(JSON.parse(user.raw).profileId);
		} catch ( e ) {
			return false
		}
	}

	public get isUserNotExist() {
		const profileId = this.isProfileExist();
		return !profileId;
	}

	public get domByAuthState() {
		const {
			children,
			is_auth_flow_ended
		} = this.props;

		return is_auth_flow_ended || this.isUserNotExist ? children : null;
	}

	private initializeGTM() {

		try {
			const logEvent = (name: string, params: string) => {
				if (!name) {
					return;
				}

				const message = {
					command: 'logEvent',
					name,
					parameters: params
				};
				window.postMessage(JSON.stringify(message), '*');

			};

			const setUserProperty = (name: string, value: string) => {
				if (!name || !value) {
					return;
				}

				const message = {
					command: 'setUserProperty',
					name,
					value
				};

				window.postMessage(JSON.stringify(message), '*');

			};

			Object.assign(window, {logEvent, setUserProperty});

			const gtmData = User.GET_GTM_DATA();
			const user = this.state.user;
			const GTMContainerId = gtmData.gtmId ?? user.GTMContainerId;
			const deviceType = gtmData.deviceType ?? user.device_type;

			const NOT_APP_ID = process.env.REACT_APP_GTM_NOT_APP_ID;

			if ( !GTMContainerId && !NOT_APP_ID ) {
				return
			}

			const GTM_ID_MAP = {
				'ios': GTMContainerId,
				'android': GTMContainerId,
				'other': NOT_APP_ID
			};

			const tagManagerArgs = {
				gtmId: get(GTM_ID_MAP, deviceType, NOT_APP_ID)
			};

			TagManager.initialize(tagManagerArgs)
		} catch (e: any) {
			console.log(e);
		}

	}

	public componentDidUpdate() {
		const {error} = this.props;
		if (!isEmpty(error)) {
			Sentry.captureException(error)
		}
	}

	/**
	 * TODO: Refactor
	 */
	public render() {

		const {
			error,
		} = this.props;

		/**
		 * Message with errors that are received during the request to API in user's saga
		 */
		if (!isEmpty(error) && IS_UAT) {
			return (
				<MessageBox>
					<code>
						{toArray(error)}
					</code>
				</MessageBox>
			);
		}

		return this.domByAuthState;
	}
}


export const BwwLogin = withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(HOCBwwLoginComponent));

import React, {Fragment} from 'react';

interface IProps {
	when: boolean
}

export const HOCisTrue: React.FC<IProps> = ({children, when}) => {
	return when ? (
		<Fragment>
			{children}
		</Fragment>
	) : null;
}
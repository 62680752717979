import logo from 'assets/img/logos/triviaLogo.svg';
import {EventCardLogo} from 'components/EventCard/EventCardLogo';
import {EventCardTriviaHeader} from 'components/EventCard/EventCardTriviaHeader';
import {EventCardWrapper} from 'components/EventCard/EventCardWrapper';
import {fetchContestTimes} from 'modules/actions/contest_times';
import {IRegionTime} from 'modules/reducers/contest_times';
import {getContestTimes} from 'modules/selectors/contest_times';
import {IconCalendar} from 'components/Icons/IconCalendar';
import {IconChevron} from 'components/Icons/IconChevron';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled, {css} from 'styled-components';


const TimeRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-self: center;
    width: 100%;
    padding: 10px 0;
    border-bottom:  1px solid #E3E1DB;
    
    &:last-child {
    	border-bottom: none;
    }

`;

const NameContest = styled.div`
	span {
		color: #2D5371;
		font-size: 15px;
		font-weight: bold;
		letter-spacing: 0;
	}
	
	p {
		padding-top: 10px;
		color: #2D5371;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 1px;
		line-height: 12px;
	}
`;

interface IOpenedContent {
	isOpened: boolean
}

const Content = styled.div<IOpenedContent>`
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    font-family: Barlow, sans-serif;
    max-height: 999px;
    opacity: 1;
    transition: all 0.3s ease;
    
    ${props => !props.isOpened && css`
    	max-height: 0;
    	opacity: 0;
    `}
`;

const Title = styled.div`
	color: #2D5371;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 18px;
	text-align: center;
	padding: 0 60px 20px;
`;

const Games = styled.div`
	height: 36px;
	width: 36px;
	border-radius: 13px;
	background-color: #1F3E56;
	display: flex;
	flex-direction: column;
	align-self: center;
	justify-content: center;
	color: #FFFFFF;
	letter-spacing: 0;
	
	p {
		text-align: center;
		&:first-child {
			font-size: 16px;
			font-weight: bold;
		}
		
		&:last-child {
			font-size: 6px;
		}
	}
	
`;

const IconSchedule = styled.div`
    position: absolute;
    height: 50px;
 	width: 50px;
 	background-color: #1F3E56;
 	bottom: 0;
 	border-radius: 50%;
 	display: flex;
 	align-items: center;
 	justify-content: center;
 	box-shadow: 0 2px 1px 0 #fff;
 	svg {
 		width: 16px;
 		height: 16px;
 	}
 
`;

const ScheduleToggle = styled.div<IOpenedContent>`
    position: absolute;
    right: 17px;
    top: 17px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
	color: #FFFFFF;
	font-family: Barlow, sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 14px;
	text-transform: uppercase;
	cursor: pointer;
	user-select: none;

	svg {
		margin-left: 5px;
		transition: all 0.3s ease;
		transform: scaleY(1);
		will-change: transform;
	}
	
	${props => props.isOpened && css`
		svg {
			transform: scaleY(-1);
		}
	`}
`;
export const TriviaScheduleCard : React.FC = () => {
	const dispatch = useDispatch();
	const contestTimes = useSelector(getContestTimes);
	const [isOpened, setOpenedState] = useState(true);

	useEffect(() => {
		dispatch(fetchContestTimes());
	}, [dispatch]);

	const toggleOpen = () => {
		setOpenedState(!isOpened);
	};

	const {
		lunchtime: LAUNCH_TIME,
		happy_hour: HAPPY_HOUR,
		late_night: LATE_NIGHT
	} = contestTimes;

	const formatHours = (regionTime: IRegionTime, withTimeA: boolean = false) => {
		const date = moment(regionTime.startDateTime);
		if ( withTimeA ) {
			return date.clone().add(1, 'hours').format('h:mma');
		}
		return date.format('h:mm');
	};

	const boxClassName = isOpened ? 'initial' : '';

	return (
		<EventCardWrapper className={boxClassName}>
			<EventCardTriviaHeader isSmall={true}>
				<EventCardLogo src={logo} alt={'Trivia'}/>
				<ScheduleToggle onClick={toggleOpen} isOpened={isOpened}>
					<span>schedule</span>
					<IconChevron width={16} height={16} color={'#fff'}/>
				</ScheduleToggle>
				<IconSchedule>
					<IconCalendar color={'#fff'}/>
				</IconSchedule>
			</EventCardTriviaHeader>

			<Title>
				Enjoy a double dose of trivia
				three times a day!
			</Title>

			<Content isOpened={isOpened}>
				<TimeRow>
					<NameContest>
						<span>
							Lunchtime Trivia
						</span>
						<p>    {`${formatHours(LAUNCH_TIME)} - ${formatHours(LAUNCH_TIME, true)}`}</p>
					</NameContest>
					<Games>
						<p>2</p>
						<p>games</p>
					</Games>
				</TimeRow>

				<TimeRow>
					<NameContest>
						<span>
							Happy Hour Trivia
						</span>
						<p>    {`${formatHours(HAPPY_HOUR)} - ${formatHours(HAPPY_HOUR, true)}`}</p>
					</NameContest>
					<Games>
						<p>2</p>
						<p>games</p>
					</Games>
				</TimeRow>
				<TimeRow>
					<NameContest>
						<span>
							Late Night Trivia
						</span>
						<p>    {`${formatHours(LATE_NIGHT)} - ${formatHours(LATE_NIGHT, true)}`}</p>
					</NameContest>
					<Games>
						<p>2</p>
						<p>games</p>
					</Games>
				</TimeRow>
			</Content>
		</EventCardWrapper>
	);
};

import React, {useState, useEffect} from 'react';
import moment from 'moment-timezone';
import {getDaysAndHours, getIsZero, getTimeTicked} from 'modules/utils';
import {EventTimeRemain} from './EventTimeRemain';
import {IconSpinner} from 'components';
import styled, {keyframes} from 'styled-components';
import {LiveIcon} from 'components/LiveIcon';

const spin = keyframes`
	100% {
		transform: rotate(360deg);
	}
`
const Wrapper = styled.div<{wrapperBackground?: string | null}>`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({wrapperBackground}) => wrapperBackground || '#362E2C'};
	width: 50px;
	height: 50px;
	border-radius: 50%;
	margin: 0 auto 10px;
	opacity: 1;
	z-index: 1;
	svg {
		position:absolute;
		transform-origin: center;
		animation: ${spin} 1s linear infinite;
	}
`;

interface IProps {
	readonly date: Date;
	uniq: number;
	wrapperBackground?: string | null;
	className?: string;
	onComplete?: () => void;
}

interface IState {
	hours_remaining: number;
	mins_remaining: number;
	seconds_remaining: number;
}

const TWO = 2;
const THOUSAND = 1000;

export const EventTimer: React.FC<IProps> = ( { date, uniq, wrapperBackground, className, onComplete }: IProps ) => {
	const now = moment();
	const end = moment( date );
	const ms_diff = end.diff( now );
	const diff = moment.duration( ms_diff );
	const format = `${Math.floor(diff.asHours())}${moment.utc( ms_diff ).format( ":m:s" )}`;
	const split = format.split( ':' );

	const [id] = useState( `${ uniq }` );

	const hours = Number( split[0] );
	const minutes = Number( split[1] );
	const seconds = Number( split[TWO] );

	const [state, setTime] = useState<IState>( {
		hours_remaining: hours,
		mins_remaining: minutes,
		seconds_remaining: seconds
	} );

	useEffect( () => {
		const seconds_interval = setInterval( () => {
			const nextState = getTimeTicked( state );
			setTime( nextState );
		}, THOUSAND );
		return () => clearInterval( seconds_interval );
	} );

	useEffect(() => {
		if (ms_diff < 0 && typeof onComplete === 'function') {
			onComplete();
		}
	}, [ms_diff, onComplete])

	// Account for lock out
	if ( ms_diff < 0 || getIsZero( state.hours_remaining, state.mins_remaining, state.seconds_remaining ) ) {
		return (
			<LiveIcon className="live-icon">LIVE</LiveIcon>
		);
	}

	const { days } = getDaysAndHours( state.hours_remaining );

	return (
		<Wrapper wrapperBackground={ wrapperBackground } className={ className }>
			<IconSpinner className={ id }/>
			<EventTimeRemain days={ days } { ...state } />
		</Wrapper>

	);
};

import {EnumEventGame, EnumEventStatus, EnumEventType, EnumFiltersPerGame, SegmentStatus} from 'modules/utils/enums';
import {createReducer} from 'redux-act';
import {fetchEventsSuccess, fetchIsBettingAllowedSuccess} from 'modules/actions';

export interface IAORound {
	category_id: number;
	category_logo: string;
	category_name: string;
	contest_id: number;
	id: number
	name: string;
	round_id: number
	start_date_time: string;
	status: SegmentStatus;
	tagline: string;
	duration: number;
}

export interface IPASSquad {
	id: number;
	name: string;
	short_name: string;
	squad_away_image: string;
	squad_home_image: string;
	squad_image: string;
	color: string;
	secondary_color: string;
}

export interface IEvent {
	game: EnumEventGame | EnumFiltersPerGame;
	id: number;
	max_picks: number;
	name: string;
	start: Date;
	status: EnumEventStatus;
	type: EnumEventType;
	game_name: string;
	user_made_picks: boolean;
	picks_count: number;
	number_rounds: number;
	position?: number | null;
	sport: string;
	is_playoff: boolean;
	is_nfl: boolean;

	away_squad?: IPASSquad;
	home_squad?: IPASSquad;
	rounds?: IAORound[];
	player1?:IUFCPlayer;
	player2?:IUFCPlayer;
}

interface IUFCPlayer {
	id: number;
	is_champion: 1 | 0;
	name: string;
	first_name: string;
	last_name: string;
	champion_image: string | null;
	option1_image:  string | null;
	option2_image: string | null;
	color: string;
	secondary_color: string;
}

export interface IEvents {
	completed: IEvent[],
	live: IEvent[],
	upcoming: IEvent[]
}

export interface IDashboard {
	events: IEvents;
	isBettingAllowed: boolean;
}

const initialState = {
	events: {
		completed: [],
		live: [],
		upcoming: []
	},
	isBettingAllowed: false
}

export const dashboard = createReducer<IDashboard>({}, initialState)
	.on(fetchEventsSuccess, (state, payload: IEvents) => {
		return {
			...state,
			events: {
				...payload
			}
		}
	}).on(fetchIsBettingAllowedSuccess,
		(state, payload: boolean) => ({
			...state,
			isBettingAllowed: payload
		}))
;

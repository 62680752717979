import {eq} from 'lodash';

const FIFTY_NINE = 59;
const HOURS_IN_DAY = 24;

interface IState {
	hours_remaining: number;
	mins_remaining: number;
	seconds_remaining: number;
}

export const getTimeTicked = ({ hours_remaining, mins_remaining, seconds_remaining }: IState) => {
	const are_seconds_zero = eq(seconds_remaining, 0);
	const are_minutes_zero = eq(mins_remaining, 0);
	const are_hours_zero = eq(hours_remaining, 0);

	const updateObj: Partial<IState> = {};

	// These functions were created to reduce the cyclomatic-complexity of the getTimeTicked

	const updateSeconds = () => {
		if (are_seconds_zero && (!are_hours_zero || (are_hours_zero && !are_minutes_zero))) {
			updateObj.seconds_remaining = FIFTY_NINE;
		}
		if (!are_seconds_zero) {
			updateObj.seconds_remaining = seconds_remaining - 1;
		}
	};

	const updateMinutes = () => {
		if (are_seconds_zero && are_minutes_zero && !are_hours_zero) {
			updateObj.mins_remaining = FIFTY_NINE;
		}
		if (are_seconds_zero && !are_minutes_zero) {
			updateObj.mins_remaining = mins_remaining - 1;
		}
	};

	const updateHours = () => {
		if (are_seconds_zero && are_minutes_zero && !are_hours_zero) {
			updateObj.hours_remaining = hours_remaining - 1;
		}
	};

	// Update the time as required
	updateSeconds();
	updateMinutes();
	updateHours();

	// Merge with old time
	return { hours_remaining, seconds_remaining, mins_remaining, ...updateObj };
};

export const getDaysAndHours = (total_hours: number): { days: number, hours: number } => {
	const abs_hours = Math.abs(total_hours);
	const total_days = abs_hours / HOURS_IN_DAY;
	const days = Math.floor(total_days);
	const hours = Math.floor((total_days - days) * HOURS_IN_DAY);
	return { days, hours };
};

export const getIsLessThanDay = (total_hours: number) => total_hours < HOURS_IN_DAY;

export const getIsZero = (hours: number, mins: number, seconds: number) => hours === 0 && mins === 0 && seconds === 0;

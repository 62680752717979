import React from 'react';
import styled from 'styled-components';
import icon from 'assets/img/icons/checkmark-black.svg';

interface IProps {
	onChange?: (e: React.SyntheticEvent<HTMLInputElement>) => void,
	children: React.ReactNode,
	name: string
}

const StyledCheckbox = styled.div`
	margin: 1em auto;
`;

const Checkbox = styled.input.attrs({
	type: 'checkbox',
})`
  visibility: hidden;
  left: -999px;
  position: relative;
`;

const Label = styled.label`
	display: inline-block;
	padding-left: 2.5em;
	position: relative;
	font-size: 14px;
	line-height: 22px;
	color: #FFF;
	font-family: 'Barlow-Bold', sans-serif;
	min-height: 2em;
	text-align: left;
	width: 100%;
	transition: all .4s ease;
	letter-spacing: normal;
	
	&:before {
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		content: '';
		width: 25px;
		height: 25px;
		box-sizing: border-box;
		background-color: #FFF;
		margin-top: 4px;
		border: solid 1px black;
		${Checkbox}:checked + & {
			background-image: url(${icon});
			background-size: 28px;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
 
`;

export const CheckboxStyled: React.FC<IProps> = ({onChange, children, name}) => (
	<StyledCheckbox>
		<Checkbox
			onChange={onChange}
			name={name}
			id={name}
		/>
		<Label htmlFor={name}>
			{children}
		</Label>
	</StyledCheckbox>
);

import {IChecksum} from 'modules/reducers/checksums';
import {ISubscribeToChecksums} from 'modules/sagas/live_score';
import {createAction} from 'redux-act';


export const fetchChecksumsJSON = createAction();
export const fetchChecksumsJSONSuccess = createAction<IChecksum>();
export const fetchChecksumsJSONFailed = createAction();

export const subscribeToLiveScores = createAction<ISubscribeToChecksums>();
export const unsubscribeFromLiveScores = createAction();

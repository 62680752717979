import React, {useState} from "react";
import styled from "styled-components";
import {IconChevron} from "components";
import {HOCisTrue} from 'components/HOCisTrue';

const Wrapper = styled.div`
	margin-bottom: 5px;
`;

const CollapseButton = styled.div<{isOpen: boolean}>`
	background: rgb(63, 52, 50);
	width: 100%;
	height: 50px;
	border-radius: 6px;
	display: flex;
	align-items: center;
	padding: 0 20px;
	color: #fff;
	font-size: 16px;
	font-family: "Barlow-Bold", sans-serif;
	img {
		width: 16px;
		margin-right: 20px;
	}
	svg {
		transform: rotate(${(props) => (props.isOpen ? "180deg" : 0)});
		margin-left: auto;
		transition: transform 0.3s linear;
	}
`;

const ExpandableContainer = styled.div<{isOpen: boolean}>`
	height: ${(props) => (props.isOpen ? "100%" : 0)};
	overflow: hidden;
`;

const ExpandableContainerContent = styled.div`
	padding: 20px;
	font-size: 12px;
	line-height: 16px;
`;

interface IProps {
	icon?: string;
	title: string;
	children?: React.ReactChild;
}

export const HOCHelpCollapsableSection = ({title, icon, children}: IProps) => {
	const [state, changeState] = useState(false);
	const changeStateHandler = () => {
		changeState(!state);
	};
	return (
		<Wrapper>
			<CollapseButton onClick={changeStateHandler} isOpen={state}>
				<HOCisTrue when={Boolean(icon)}>
					<img src={icon} alt="icon" />
				</HOCisTrue>
				{title}
				<IconChevron color={"#ffffff"} />
			</CollapseButton>
			<ExpandableContainer isOpen={state}>
				<ExpandableContainerContent>
					{children ? children : null}
				</ExpandableContainerContent>
			</ExpandableContainer>
		</Wrapper>
	);
};

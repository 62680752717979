import {createReducer} from 'redux-act';
import {changeMenuState} from 'modules/actions/global';

export interface IGlobalReducer {
	menuState: boolean;
}

const initialState: IGlobalReducer = {
	menuState: false,
};

export const global = createReducer( {}, initialState );

global.on( changeMenuState, ( _, payload: boolean ) => ({
	menuState: payload,
}) );
import Cookies from 'js-cookie';
import moment from 'moment';
import {AO_IMAGES_URL} from 'modules/utils/constants';

export function getCookie<T>(name: string, parse = false): T {
	if (parse) {
		try {
			return JSON.parse(String(Cookies.get(name))) as T;
		} catch(e) {
			return undefined as unknown as T;
		}
	}
	return Cookies.get(name) as unknown as T;
}

export function getCookieBoolean(name: string): boolean {
	return String(Cookies.get(name)).toLowerCase() === "true";
}

export function navigateToBWWLoginScreen(url: string) {
	// const data = {command: 'navigate', url: '/account/log-in/' };
	const data = {command: 'navigate', url};
	return window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(data));
}

// return time of start (11am) current or next day
export function getTimeToDayStart() {
	const currentHour = moment().hours()

	if (currentHour < 11) {
		return moment().set({hour: 11, minute: 0, second: 0, millisecond: 0});
	}

	return moment()
		.add({day: 1})
		.set({hour: 11, minute: 0, second: 0, millisecond: 0});
}

export function getImageFromBackend(image: string | null | undefined) {
	if (!image) {
		return "";
	}

	return `${AO_IMAGES_URL}${image}`;
}

import {HubStats} from 'components/HubStats';
import {fetchStats} from 'modules/actions/stats';
import {getStats} from 'modules/selectors/stats';
import iconStats from 'assets/img/icon-stats.svg';

import pinIcon from 'assets/img/icons/icon-pin-round.svg';

import {every} from 'lodash';
import {getUser} from 'modules/selectors/user';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {
	Location,
	Pin,
	Profile,
	ProfileSection,
	StatIcon,
	StatIconImg,
	StatItem,
	Stats,
	StatSubTitle,
	StatValue,
	TeamName,
} from './UserProfileParts';

interface IStatIconBtn {
	toggle?: () => void;
	color?: string;
}

export const StatIconBtn: React.FC<IStatIconBtn> = ({ toggle, color }) => {
	return (
		<StatIcon onClick={toggle} color={color}>
			<StatIconImg src={iconStats} alt="Stats" />
		</StatIcon>
	)
};

const getChecking = ( checked_in: number, store_name: string ) => (
	checked_in ? (
		<React.Fragment>
			You have checked in at <Pin role="presentation" src={ pinIcon }/> { store_name || '-' }
		</React.Fragment>
	) : (
		<React.Fragment>
			<Pin role="presentation" src={ pinIcon }/> Make sure you check in once per month
		</React.Fragment>
	)
);

const NoPointsText = styled.div`
	font-size: 12px;
	line-height: 12px;
	display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    color: #fff;
`;

const ProfileSectionStyled = styled(ProfileSection)<{ isStatsShow: boolean, statsHeight: number }>`
	height: ${props => props.isOpen
	? props.isStatsShow
		? `${props.statsHeight + 132}px`
		: '160px'
	: 0};
`;

interface IProps {
	isOpen: boolean;
}

export const UserProfile = ( { isOpen }: IProps ) => {
	const dispatch = useDispatch();
	const { store_name, first_name = '-', last_name = '', checked_in } = useSelector(getUser);
	const checkInLine = getChecking(checked_in, store_name);
	const { total_contests_played, highest_pp_rank, reward_items_won, highest_streak } = useSelector(getStats);
	const isNoPoints = every([ !total_contests_played, !highest_pp_rank, !reward_items_won, !highest_streak ]);
	const [ is_stats_show, setStatsShow ] = useState(false);
	const toggleShowStats = () => {
		setStatsShow(!is_stats_show);
		if ( is_stats_show ) {
			window.scrollTo({
				top     : 0,
				behavior: 'smooth'
			});
		}
	};
	const [stats_height, setStatsHeight] = useState(0);

	useEffect( () => {
		dispatch( fetchStats() );
	}, [dispatch] )

	useEffect(() => {
		if (!isOpen) {
			setStatsShow(false);
		}
	}, [isOpen, setStatsShow]);

	const getProfileValue = ( value: number | null ) => value || 'N/A';

	return (
		<ProfileSectionStyled isOpen={ isOpen } isStatsShow={is_stats_show} statsHeight={stats_height}>
			<Profile>
				<TeamName>
					Hey { first_name } { last_name[0] },
				</TeamName>
				<Location>
					{ checkInLine }
				</Location>
			</Profile>
			{ isNoPoints ? (
				<NoPointsText>Play games to start earning stats</NoPointsText>
			) : null }
			<Stats>
				<StatItem>
					<StatValue>{ getProfileValue( total_contests_played ) }</StatValue>
					<StatSubTitle>Contests<br/>Played</StatSubTitle>
				</StatItem>
				<StatItem>
					<StatValue>{getProfileValue(highest_streak)}</StatValue>
					<StatSubTitle>Highest<br/>Trivia Streak</StatSubTitle>
				</StatItem>
				<StatItem>
					<StatValue>{getProfileValue(reward_items_won)}</StatValue>
					<StatSubTitle>Reward<br/>Items Won</StatSubTitle>
				</StatItem>
			</Stats>

			<HubStats is_stats_show={is_stats_show}
			          toggleShowStats={toggleShowStats}
			          setStatsHeight={setStatsHeight}/>

		</ProfileSectionStyled>
	)
}

import {memoize, find, includes, get} from 'lodash';
import {getUserStoreID} from 'modules/selectors/user';
import {IGlobalStore, IStoreReducerState, IStore} from 'modules/types';
import {LEGAL_BETTING_STATES} from 'modules/utils/constants';
import {BWW_WEB_STORE_ID} from 'modules/utils/trivia';
import {createSelector} from 'reselect';


export const getStoresList = (state: IGlobalStore): IStoreReducerState['items'] => state.stores.items;

export const getStoreSelector = createSelector(
	[getStoresList],
	(stores) => {

		return memoize((store_id: number) => {

			return find(stores, {
				id: Number(store_id)
			})
		})
	}
);

export const getUserStoreObject = createSelector(
	[ getStoreSelector, getUserStoreID ],
	(storesSelector, store_id) => {
		if ( store_id ) {
			return storesSelector(+store_id);
		}
		return storesSelector(BWW_WEB_STORE_ID);
	}
);


export const isLegalBettingStore = createSelector(
	[ getUserStoreObject ],
	(store: IStore | undefined) => {
		const state_id = get(store, 'state_id', '');
		return includes(LEGAL_BETTING_STATES, state_id.toLowerCase());
	}
);

import {includes} from 'lodash';


export const BASE_URL = process.env.REACT_APP_BASE_URL || '';
export const IS_API_ON_THE_SAME_HOST = ( process.env.REACT_APP_API_URL || '' ).includes(window.location.host);
export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || '';
export const JSON_URL = process.env.REACT_APP_JSON_URL || '';
export const AO_LINK = process.env.REACT_APP_AO_LINK || "";
export const PAS_LINK = process.env.REACT_APP_PAS_LINK || "";

export const IS_UAT = APP_ENVIRONMENT === 'uat';
export const IS_LOC = includes(window.location.href, '.loc');
export const IS_UP_SHOW_ENABLED = process.env.REACT_APP_UP_SHOW_ENABLED === 'true';

const PROJECT_NAMES = ['BWW_TRIVIA_WEB', 'BWW_TRIVIA', 'BWW_PLAY_HUB', 'BWW_PP_WEB'];
if ( !includes(PROJECT_NAMES, process.env.REACT_APP_PROJECT_NAME) ) {
	console.error('Looks like there was added or renamed project/REACT_APP_PROJECT_NAME variable in dot env. Please check if these variables are still actual - IS_TRIVIA_APP | IS_PLAY_HUB_PROJECT')
}
export const IS_TRIVIA_APP = process.env.REACT_APP_PROJECT_NAME === 'BWW_TRIVIA';
export const IS_PLAY_HUB_PROJECT = process.env.REACT_APP_PROJECT_NAME === 'BWW_PLAY_HUB';

export const LEGAL_BETTING_STATES =["co", "il", "in", "ia", "ks", "nj", "ny", "oh", "tn", "dc", "wv", "wy"];
export const SHORT_MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const BWW_GTM_DATA_KEY = "bgdk";
export const SECRET_STORAGE_KEY = "in_new_visible";
export const NBA_SECRET_STORAGE_KEY = "is_pas_nba_visible";
export const PAS_SECRET_STORAGE_KEY = "ps_vsbl";
export const AO_IMAGES_URL = process.env.REACT_APP_AO_IMAGES_URL || "";
export const IS_AO_DISABLED_ENV = JSON.parse(process.env.REACT_APP_IS_AO_DISABLED || "false")
export const IS_PAS_NBA_SOON_PASSED = JSON.parse(localStorage.getItem(NBA_SECRET_STORAGE_KEY) || "false");
export const IS_PAS_SECRET_PASSED = true
export const IS_AO_DISABLED = IS_AO_DISABLED_ENV;
export const IS_PAS_COMING_SOON_VISIBLE = process.env.REACT_APP_IS_PAS_COMING_SOON_VISIBLE === 'true'

export const IS_MGM_VISIBLE = process.env.REACT_APP_IS_MGM_VISIBLE === 'true';
export const IS_TRIVIA_APP_DISABLED = process.env.REACT_APP_IS_DISABLED === "true";

import React from 'react';
import {IIconProps} from 'modules/types';

export const IconSpinner: React.FC<IIconProps> = ({title, width, height, color, className, onClick}) => {
	return (
		<svg width={`${width}px`} height={`${height}px`} onClick={onClick} viewBox="0 0 48 48">
			<title>{title}</title>
			<defs>
				<linearGradient x1="79.3999227%" y1="25.1734479%" x2="12.3242263%" y2="82.1967849%" id={className}>
					<stop stopColor={color} stopOpacity="0" offset="0%" />
					<stop stopColor={color} offset="100%" />
				</linearGradient>
			</defs>
			<g transform="translate(-1.000000, -1.000000)" stroke={`url(#${className})`} fill="transparent">
				<circle transform="translate(25.000000, 25.000000) scale(-1, -1) translate(-25.000000, -25.000000) " cx="25" cy="25" r="22.6481481" />
			</g>
		</svg>

	);
};

IconSpinner.defaultProps = {
	title: 'Spinner',
	width: 48,
	height: 48,
	color: '#fff'
};

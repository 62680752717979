import {fetchMgmCardsJSONFailed, fetchMgmCardsJSONSuccess} from 'modules/actions/mgmCards';
import {PlayHubApi} from 'modules/utils/Api';
import {globalError} from 'modules/actions';
import {ApiError} from 'modules/utils';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

export const mgmCardsSaga = function* (): SagaIterator {
	try {
		const mgmCards = yield call(PlayHubApi.JSON.mgm_cards);
		ApiError.CHECK(mgmCards, false);
		yield put(fetchMgmCardsJSONSuccess(mgmCards));
	} catch (e: any) {
		yield put(fetchMgmCardsJSONFailed());
		const error = e as Error;
		yield put(globalError(error))
	}
};

import axios from 'axios';
import {get} from 'lodash';
import {IFetchEventsPayload} from 'modules/actions';

import {
    ITriviaHelp,
    IApiNonTypedResponse,
    IApiResponse,
    IApiResponseRequired,
    IApiResponseRequiredRecord,
    IBackDoorInfo,
    IChecksum,
    IFooterReducerState,
    IHelpItem,
    IModelUser,
    IRequestCode,
    IServerTime,
    IStore,
    IUserResponse,
    IUserVerifyCodeResponse,
} from 'modules/types';
import {HTTPClient as HTTPClientCore, IRequestConfig} from 'modules/utils/HTTPClient';
import {IBackDoorUser} from 'modules/utils/User';
import qs from 'qs';
import {CANCEL} from 'redux-saga';


export class HTTPClient extends HTTPClientCore {
    /**
     * Overridden method adds CancelToken symbol, that allow redux-saga'
     * "takeLatest" function to cancel any requests automatically.
     */
    public makeRequest<T = any>(config: IRequestConfig): Promise<T> {
        const source = axios.CancelToken.source();

        const new_config = {
            ...config,
            params: {
                _: Date.now(),
                ...config.params
            },
            cancelToken: source.token
        };

        const request = super.makeRequest(new_config);

        // @ts-ignore
        request[CANCEL] = () => source.cancel();

        return request;
    }
}

const APIClient = new HTTPClient({
    baseURL: process.env.REACT_APP_API_URL || '',
    withCredentials: true,
    transformRequest: [data => qs.stringify(data)],
    onCatchNetworkError: response => get(response, 'response.data')
});

const JSONClient = new HTTPClient({
    baseURL: process.env.REACT_APP_JSON_URL || ''
});


const JSONClientTrivia = new HTTPClient({
    baseURL: process.env.REACT_APP_JSON_TRIVIA_URL || ''
});

const JSONClientPP = new HTTPClient({
    baseURL: process.env.REACT_APP_JSON_PP_URL || ''
});

const TERMS_FILE_NAME = 'terms.json';
export const Api = {
    JSON: {
        terms: () => JSONClient.get<IHelpItem[]>(TERMS_FILE_NAME),
        footer: () => JSONClient.get<IFooterReducerState>('footer.json'),
        stores: () => JSONClient.get<Record<string, IStore>>('stores.json'),
        checksum: () => JSONClient.get<IChecksum>('checksum.json'),
    },
    User: {
        create: (params: IModelUser) => APIClient.post<IApiResponseRequired<IUserResponse>>('user/create', params),
        update: (params: Record<string, unknown>) => APIClient.post<IApiResponseRequiredRecord<IModelUser>>('user/update', params),
        getBettingState: () => APIClient.get("/geo_ip/is_in_legal_betting_state")
    },
    Auth: {
        login: (params: IModelUser) => APIClient.post<IApiResponseRequired<IUserResponse>>('bww_login', params),
        logout: () => APIClient.post<IApiNonTypedResponse>('logout'),
        backdoor: (params: Record<string, unknown>) => APIClient.post<IApiResponseRequired<IBackDoorUser>>('bww_login/backdoor', params),
        request_code: (params: IRequestCode) => APIClient.post('login/request_code', params),
        verify_code: (params: Record<string, unknown>) => APIClient.post<IApiResponseRequired<IUserVerifyCodeResponse>>('login/verify_code', params),
        backdoor_info: () => APIClient.get<IApiResponse<IBackDoorInfo>>('bww_login/backdoor_info'),
    },

};

const JSONClientAwaysOnTrivia = new HTTPClient({
    baseURL: process.env.REACT_APP_JSON_TRIVIA_URL || ''
});


export const PlayHubApi = {
    Dashboard: {
        events: (params: IFetchEventsPayload) => APIClient.get('dashboard/events', params),
        stats: () => APIClient.get('dashboard/stats'),
        stats_details: () => APIClient.get('dashboard/stats_details'),
    },
    MGM: {
        cards: () => APIClient.get('cards')
    },
    Ladder: {
        snippet: ({type, ...params}: any) => APIClient.get(`leaderboard_snippet/${type}`, params),
    },
    JSON: {
        mgm_cards: () => JSONClient.get('mgm_cards.json'),
        ad_slot: () => JSONClient.get('ad_slot.json'),
        terms_trivia: () => JSONClientTrivia.get(TERMS_FILE_NAME),
        contests_timezones: () => JSONClientTrivia.get('contests_timezones.json'),
        terms_pp: () => JSONClientPP.get("multisport_pickem_terms_and_conditions.json"),
        trivia_guidelines: () => JSONClientAwaysOnTrivia.get<ITriviaHelp>("guideline.json"),
        trivia_terms: () => JSONClientAwaysOnTrivia.get<ITriviaHelp>(TERMS_FILE_NAME),
        trivia_faq: () => JSONClientAwaysOnTrivia.get<ITriviaHelp>("faq.json"),

    }
};

const TriviaAPIClient = new HTTPClient({
    baseURL: process.env.REACT_APP_TRIVIA_API_URL || '',
    withCredentials: true,
    transformRequest: [data => qs.stringify(data)],
    onCatchNetworkError: response => get(response, 'response.data')
});

export const TriviaApi = {
    Utils: {
        time: () => TriviaAPIClient.get<IApiResponseRequired<IServerTime>>(`time`)
    },
};


export * from './ApiError';

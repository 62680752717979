import {createReducer} from 'redux-act';
import * as actions from 'modules/actions';

export interface IFooterLogos {
	image: string;
	link: string;
}

export interface IFooterPrizes {
	image: string;
}

export interface IFooterReducerState {
	game_logos: IFooterLogos[],
	prize_images: IFooterPrizes[],
	isFetched: boolean,
	error?: string
}

const initialState: IFooterReducerState = {
	game_logos: [],
	prize_images: [],
	isFetched: false,
};

export const footer = createReducer<IFooterReducerState>( {}, initialState );

footer.on( actions.fetchFooterJSONSuccess, ( state: IFooterReducerState, payload ) => ({
	...state,
	...payload,
	isFetched: true
}) );
footer.on( actions.fetchFooterJSONFailed, () => (initialState) );

import React, {useEffect} from 'react';
import {AO_LINK} from 'modules/utils';
import styled from 'styled-components';
import cardHeaderBackground from 'assets/img/cards/AOCardBackground.jpg';
import {useDispatch, useSelector} from 'react-redux';
import {getAOEvents, getRegionSelector} from 'modules/selectors';
import {first} from 'lodash';
import moment from 'moment';
import {fetchEvents} from 'modules/actions';
import {SVGLoader} from 'components/SVGLoader';

const Card = styled.div`
  box-shadow: 0 20px 20px 0 rgb(28 28 28 / 20%);
  border-radius: 10px;
  background-color: #FFFFFF;
  margin: 0 auto 30px;
  overflow: hidden;
  position: relative;
  max-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  background: url("${cardHeaderBackground}");
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 70px;
  gap: 8px;
  
  svg {
	margin-top: 10px;
	max-width: 250px;
  }
`;

const ButtonPlay = styled.a`
  color: #362E2C;
  font-family: Barlow, sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 14px;
  text-align: center;
  padding: 23px;
  margin: 0 34px;
  border-top: 1px solid #E3E1DB;
  text-transform: uppercase;
`

const TagLine = styled.p`
  font-family: Barlow, sans-serif;
  color: #131313;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  padding: 20px 0;
`;

const LiveTimer = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  background: #fec600;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
	color: #3F3432;
	font-family: Barlow, sans-serif;
	font-size: 10px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 10px;
	text-transform: uppercase;
  }

  &:after {
	content: "";
	position: absolute;
	left: 1px;
	top: 1px;
	right: 1px;
	bottom: 1px;
	border: 1px solid #FFFFFF;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
  }
`

interface IProps {
	onDone: () => void;
}

export const EventCardAlwaysOnLive: React.FC<IProps> = ({onDone}) => {
	const dispatch = useDispatch();
	const region = useSelector(getRegionSelector);
	const {rounds} = useSelector(getAOEvents);
	const liveRound = first(rounds);

	useEffect(() => {
		if (liveRound) {
			const roundEnd = moment(liveRound.start_date_time).add(liveRound.duration, 's');
			if (roundEnd.isBefore(moment())) {
				onDone();
				return;
			}

			const diff = moment().diff(roundEnd, 'milliseconds');
			const delay = Math.abs(diff || 0);

			setTimeout(() => {
				dispatch(fetchEvents({region}));
			}, delay + 9000);
			setTimeout(() => {
				onDone();
			}, delay + 10000);
		}
	}, [liveRound, onDone, region, dispatch])

	return (
		<Card>
			<CardTitle>
				<LiveTimer>
					<span>Live</span>
				</LiveTimer>

				<SVGLoader logoPath={liveRound?.category_logo} color="#FFFFFF" height="70px"/>
			</CardTitle>
			<TagLine>{liveRound?.tagline || '-'}</TagLine>
			<ButtonPlay href={AO_LINK}>
				Play now
			</ButtonPlay>
		</Card>
	);
}

import headerBg from 'assets/img/header_bg.png';

export const theme = {
	header: {
		main_background: `#000 url(${ headerBg }) no-repeat`,
		main_background_size: 'cover',
		secondary_background: '#362E2C',
		avatar_border: '1px solid #ffffff',
		margin_bottom: '20px'
	},
	fonts: {
		normal: '"Barlow-Regular", sans-serif',
		bold: '"Barlow-Bold", sans-serif',
		semiBold: '"Barlow-SemiBold", sans-serif'
	}
};

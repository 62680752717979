import * as actions from 'modules/actions';
import {Api} from 'modules/utils';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';


export const fetchStoresSaga = function* (): SagaIterator {
	const stores = yield* call(Api.JSON.stores);

	if (stores.error) {
		yield* put(actions.fetchStoresJSONFailed({message: stores.error}));
		return;
	}

	yield* put(actions.fetchStoresJSONSuccess(stores));

};

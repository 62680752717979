import {
	fetchLeaderboardSnippetPPFailed,
	fetchLeaderboardSnippetPPSuccess,
	fetchLeaderboardSnippetTriviaFailed,
	fetchLeaderboardSnippetTriviaSuccess,
} from 'modules/actions/ladder';
import {createReducer} from 'redux-act';

export interface ITriviaLadderRound {
	"id": number,
	"points": number,
	"rank": number,
	"number_played_questions": number,
	"number_correct_answers": number,
	"number_questions": number,
	"is_fully_played": boolean,
	"is_perfect": boolean
}

export interface ITriviaSnippet {
	points: number;
	rank: number;
	rounds: ITriviaLadderRound[];
	user_id: number;
	user_name: string;
	is_own?: boolean;
}

export interface IPPSnippet {
	legs: number;
	name: string;
	points: number;
	rank: number;
	start: string;
	user_id: number;
	user_name: string;
	is_own?: boolean;
}

export interface ILeaderboardSnippetTrivia {
	snippet: ITriviaSnippet[];
}

export interface ILeaderboardSnippetPP {
	month: string;
	snippet: IPPSnippet[];
}

export interface ILeaderboardSnippetState {
	trivia: ILeaderboardSnippetTrivia;
	pp: ILeaderboardSnippetPP;
}

const defaultState: ILeaderboardSnippetState = {
	trivia: {
		snippet: [],
	},
	pp: {
		month: '',
		snippet: [],
	},
};

export const leaderboard_snippet = createReducer<ILeaderboardSnippetState>({}, defaultState)
	.on(fetchLeaderboardSnippetTriviaSuccess, (state, payload) => ( {
		...state,
		trivia: {
			...payload,
		},
	} ))
	.on(fetchLeaderboardSnippetPPSuccess, (state, payload) => ( {
		...state,
		pp: {
			...payload,
		},
	} ))
	.on(fetchLeaderboardSnippetTriviaFailed, (state) => ( {
		...state,
		trivia: {
			snippet: [],
		},
	} ))
	.on(fetchLeaderboardSnippetPPFailed, (state) => ( {
		...state,
		pp: {
			month: '',
			snippet: [],
		},
	} ))
;


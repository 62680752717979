import {fetchBackDoorInfoSuccess, fetchBackDoorInfoFailed} from 'modules/actions/backdoor';
import {globalError} from 'modules/actions/errors';
import {Api} from 'modules/utils/Api';
import {call, put} from 'typed-redux-saga';

export const fetchBackDoorInfoSaga = function* () {
	try {
		const result = yield* call(Api.Auth.backdoor_info);

		if (!result.result) {
			return;
		}
		yield put(fetchBackDoorInfoSuccess(result.result))
	} catch (e: any) {
		const error = e as Error;
yield put(globalError(error))
		yield put(fetchBackDoorInfoFailed());
		console.log(e);
	}
};

import React from 'react';
import styled from 'styled-components';
import {HOCisTrue as Exists} from 'components/HOCisTrue';
import {HOCHelpCollapsableSection} from 'components/HOCHelpCollapsableSection';
import {RulesTabContent} from 'pages/rules/RulesTabContent';
import {TermsTabContent} from 'pages/rules/TermsTabContent';
import {TriviaGuidelines} from 'pages/rules/TriviaGuidelines';
import {TriviaTerms} from 'pages/rules/TriviaTerms';
import {TriviaFaqs} from 'pages/rules/TriviaFaq';

const Wrapper = styled.div`
	padding: 20px 20px 100px 20px;
`;

const Title = styled.h1`
	font-size: 16px;
	line-height: 16px;
	font-family: ${({theme}) => theme.fonts.bold};
	color: ${({theme}) => theme.baseTextColor};
	padding: 20px 0;
`;

export const RulesPage: React.FC = () => {


	return (
		<Wrapper>
			<Title>Picks and Props</Title>

			<Exists when={true}>
				<HOCHelpCollapsableSection
					title={'Rules'}
					key={'key'}>
					<RulesTabContent/>
				</HOCHelpCollapsableSection>
				<HOCHelpCollapsableSection
					title={'Terms and Conditions'}
					key={'terms'}>
					<TermsTabContent/>
				</HOCHelpCollapsableSection>
			</Exists>

			<Title>Trivia</Title>

			<Exists when={true}>
				<HOCHelpCollapsableSection
					title={'Rules'}
					key={'rules'}>
					<TriviaGuidelines/>
				</HOCHelpCollapsableSection>
				<HOCHelpCollapsableSection
					title={'Frequently asked questions'}
					key={'questions'}>
					<TriviaFaqs/>
				</HOCHelpCollapsableSection>
				<HOCHelpCollapsableSection
					title={'Terms and Conditions'}
					key={'terms'}>
					<TriviaTerms/>
				</HOCHelpCollapsableSection>
			</Exists>
		</Wrapper>
	);
};

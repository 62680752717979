import {IHelpItem} from 'modules/types';
import {createAction} from 'redux-act';


export const fetchFaq = createAction();
export const fetchFaqSuccess = createAction<IHelpItem[]>();
export const fetchFaqFailed = createAction<Error>();

export const fetchGuideline = createAction();
export const fetchGuidelineSuccess = createAction<IHelpItem[]>();
export const fetchGuidelineFailed = createAction<Error>();

export const fetchTerms = createAction();
export const fetchTermsSuccess = createAction<IHelpItem[]>();
export const fetchTermsFailed = createAction<Error>();

export const fetchHelpPages = createAction();
export const fetchHelpPagesSuccess = createAction();
export const fetchHelpPagesFailed = createAction();

import {createSelector} from 'reselect';
import {IGlobalStore} from 'modules/types';


export const getHunStats = (store: IGlobalStore): IGlobalStore['hub_stats'] => store.hub_stats;

export const getHubFilter = createSelector(
	[ getHunStats ],
	(hub_stats) => {
		return hub_stats.filter;
	}
);

export const getHubStats = createSelector(
	[ getHunStats ],
	(hub_stats): IGlobalStore['hub_stats']["stats"] => {
		return hub_stats.stats;
	}
);

export const getHubStatsFetched = createSelector(
	[ getHunStats ],
	(hub_stats) => hub_stats.isFetched
);

import {createReducer} from 'redux-act';
import * as actions from 'modules/actions';


const initialState = {
	color: '#7BD53D',
	message: ''
};

export type TGameMessage = typeof initialState;

export const game_message = createReducer<typeof initialState>({}, initialState);

game_message.on(actions.showGameMessage, (state, payload) => ( {
	...state,
	...initialState,
	...payload
} ));

game_message.on(actions.hideGameMessage, () => ( initialState ));

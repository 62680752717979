import {fetchStoresJSON, fetchStoresJSONSuccess} from 'modules/actions/stores';
import {put, take} from 'redux-saga/effects';
import {fetchGameJSONSSuccess} from 'modules/actions';


export const fetchPPGameJSONSSaga = function* () {
	yield put(fetchStoresJSON());
	yield take(fetchStoresJSONSuccess);

	yield put(fetchGameJSONSSuccess());
};

import {ISettingsReducer} from 'modules/reducers/settings';
import {createReducer} from 'redux-act';
import * as actions from 'modules/actions';

export interface IStore {
	id: number// string value on BE side
	state_id: string
	name: string
	region: ISettingsReducer['region'],
	timezone: string
	caching_video_enabled?: boolean
}

export interface IStoreReducerState {
	items: Record<string, IStore>,
	is_fetched: boolean,
}

const initialState: IStoreReducerState = {
	items: {},
	is_fetched: false,
};

export const stores = createReducer<IStoreReducerState>( {}, initialState );

stores.on( actions.fetchStoresJSONSuccess, ( state, payload ) => ({
	...state,
	items: payload,
	is_fetched: true,
}) );

stores.on( actions.fetchStoresJSONFailed, () => (initialState) );

import Cookies from 'js-cookie';
import {defaultTo, get, isEmpty, isUndefined, partial, pick} from 'lodash';
import {IUser, Optional} from 'modules/types';
import {IModelUser} from 'modules/types/user';

import {BWW_GTM_DATA_KEY, cleanSearchParams, getCookie, getUserDataFromQueryString, Storage} from 'modules/utils';


interface IBlazinRewards {
	firstName: string,
	lastName: string,
	userZipcode: string,
	profileId: string,
	email: string
}

export interface IBackDoorUser {
	hashed: string,
	raw: Record<string, unknown>,
	user: { checked_in: boolean },
}

interface ICookieType {
	blazin_rewards: Optional<IBlazinRewards>,
	blazin_hash: Optional<string>,
	is_authenticated?: boolean
}

export abstract class User {

	public static SAVE(_session_id?: string): void {
		Cookies.set('is_authenticated', 'true');
	}


	public static SAVE_TMP_USER(user_obj: Record<string, unknown>) {
		User.SAVE();
		Cookies.set('user', JSON.stringify(user_obj));
	}

	public static SET_ORIGINAL_COOKIES(raw: string, hash: string) {
		Cookies.set('blazin_rewards', raw);
		Cookies.set('blazin_hash', hash);

		Storage.SET('blazin_rewards', raw);
		Storage.SET('blazin_hash', hash);
	}

	/**
	 * blazin_hash=999b9c83ec9c5cb149db3d7be0ce7e8a643ae7b56e083577401bda6c96720222; blazin_rewards={"profileId":"","email":"","firstName":"","lastName":"","userZipcode":"","eCommerceDate":"","eCommerceStoreId":"","latitude":"40.526261","longitude":"-74.854488","phoneNumber":"","appIdentifier":"686F270D-B498-46AC-B0A9-D164B3005AAD","GTMContainerId":"GTM-WZ7RLTH","deviceType":"ios"};
	 */
	public static checkDevEnv = () => {
		const {dev_env} = getUserDataFromQueryString();
		if (dev_env) {
			const raw = `{"profileId":"","email":"","firstName":"","lastName":"","userZipcode":"","eCommerceDate":"","eCommerceStoreId":"","latitude":"40.7903569138349","longitude":"-74.04470963454021","phoneNumber":"","appIdentifier":"14fbb4831208a8c5","GTMContainerId":"GTM-K24RKJC","deviceType":"android"}`;
			Cookies.set('blazin_rewards', raw);
			Cookies.set('blazin_hash', '56bed34fec2d7b64e76968fc153cf2b8f55b132e33b93e0d6f1f9377a49e2b44');
		}
	};

	public static CLEAR(): void {
		const ids = ['user', 'is_authenticated', 'sid', 'blazin_rewards', 'blazin_hash', 'is_authenticated'];
		ids.forEach(Storage.REMOVE);
		ids.forEach((key) => Cookies.remove(key));
	}

	public static GET(): IUser | Record<string, unknown> {
		return JSON.parse(Cookies.get('user') || '{}');
	}

	public static IS_AUTHENTICATED(): boolean {
		return JSON.parse(Cookies.get('is_authenticated') || 'false');
	}

	public static GET_LOCAL_STORAGE_DATA() {
		try {
			const blazin_rewards = Storage.GET('blazin_rewards');
			const blazin_hash = Storage.GET('blazin_hash') || '';

			return {
				blazin_rewards: blazin_rewards ? JSON.parse(blazin_rewards) : '',
				blazin_hash
			}
		} catch (e: any) {
			console.log(e);
			return {
				blazin_rewards: '',
				blazin_hash: ''
			}
		}
	}

	public static SAVE_GTM_DATA(gtmId: string, type: string) {
		if (!gtmId || !type) {
			return;
		}

		const data = {
			gtmId: gtmId || '',
			deviceType: type || ''
		}

		Cookies.set(BWW_GTM_DATA_KEY, JSON.stringify(data));
	}

	public static GET_GTM_DATA() {
		return JSON.parse(Cookies.get(BWW_GTM_DATA_KEY) || '{}');
	}

	/**
	 * Taking user from Cookie (blazin_rewards, hashed) and from URL search params
	 */
	public static GET_USER_OBJECT(): Partial<IModelUser> {

		User.checkDevEnv();

		const parsed = getUserDataFromQueryString();

		const cookies: ICookieType = User.getCookies()

		const local_storage_data = User.GET_LOCAL_STORAGE_DATA();

		const rewards = defaultTo(cookies.blazin_rewards, local_storage_data.blazin_rewards);
		const hashed = defaultTo(cookies.blazin_hash, local_storage_data.blazin_hash)

		const fromParsed = partial(get, parsed, partial.placeholder, '');
		const fromRewards = partial(get, rewards, partial.placeholder, '');

		const fromBoth = (key: string) =>
			get(rewards, key, get(parsed, key, ''));

		const raw: string = JSON.stringify(rewards);
		const device_type = fromBoth('deviceType');

		const GTMContainerId = fromBoth('GTMContainerId')
		User.SAVE_GTM_DATA(String(GTMContainerId ?? ''), String(device_type ?? ''));

		const user_object = {
			raw,
			hashed,
			bww_id: fromBoth('profileId'),
			checked_in: fromParsed('checkedIn'),
			store_id: fromParsed('storeId'),
			store_zip: fromParsed('storeZip'),
			store_state: fromParsed('storeState'),
			email: fromBoth('email'),
			first_name: fromRewards('firstName'),
			last_name: fromRewards('lastName'),
			longitude: fromRewards('longitude'),
			latitude: fromRewards('latitude'),
			postcode: fromRewards('userZipcode'),
			last_ecommerce_date: fromRewards('eCommerceDate'),
			ecommerce_store: fromRewards('eCommerceStoreId'),
			mobile: fromRewards('phoneNumber'),
			app_uid: fromRewards('appIdentifier'),
			device_type: defaultTo(device_type, 'web'),
			GTMContainerId,
		};

		const cookieUser = getCookiesUser();

		if (cookieUser.isGuest) {
			return cookieUser;
		}

		const is_no_checked_in_query_string = isUndefined(parsed.checkedIn);

		if (is_no_checked_in_query_string) {
			return cookieUser;
		}

		if (!isEmpty(rewards) && hashed.length) {

			User.SAVE_TMP_USER(user_object);

			return user_object;
		}

		return {
			device_type: defaultTo(device_type, 'web'),
		};
	}

	/**
	 * Was used for old projects
	 * Candidate to remove
	 * @deprecated
	 */
	public static SET_USER_GOD_MODE(user: IModelUser) {
		return {...user, is_god_mode: User.IS_GOD_MODE(user)}
	}

	/**
	 * Was used for old projects
	 * Candidate to remove
	 * @deprecated
	 */
	public static IS_GOD_MODE(user: IModelUser) {
		return get(user, 'email', '')
			.includes('fanhubmedia.com');
	}

	public static SET_BACKDOOR_USER_OBJECT({hashed, raw, user}: IBackDoorUser) {
		const
			user_fields = [
				'bww_id',
				'email',
				'store_id',
				'store_zip',
				'store_state',
				'first_name',
				'last_name',
				'postcode',
			];

		const user_obj = {
			hashed,
			raw: JSON.stringify(raw),
			checked_in: user.checked_in ? 'true' : 'false',
			mobile: '000000000',
			device_type: 'web',
			...pick(user, user_fields)
		};

		User.SAVE_TMP_USER(user_obj);

		Cookies.set('user', JSON.stringify(user_obj));

		cleanSearchParams();
	}

	private static getCookies(): ICookieType {
		return {
			blazin_rewards: getCookie<Optional<IBlazinRewards>>('blazin_rewards', true),
			blazin_hash: getCookie<string>('blazin_hash') || ''
		}
	}
}

interface ICookiesUser extends IModelUser {
	isGuest?: boolean
}

const getCookiesUser = (): ICookiesUser => {
	try {
		return JSON.parse(Cookies.get('user') || '{}')
	} catch (e: unknown) {
		Cookies.remove('user');
		return getCookiesUser();
	}
};

import React, {Fragment} from 'react';
import styled from 'styled-components';
import {Optional} from 'modules/types';
import {getCookie, getCookieBoolean} from 'modules/utils';

const MessageBox = styled.div`
	color: #000;
	display: flex;
	justify-content: center;
	height: 100vh;
	align-items: center;
	font-size: 120%;
	text-align: center;
	padding: 20px;
`;

interface IBlazinRewards {
	firstName: string;
	lastName: string;
	userZipcode: string;
	profileId: string;
	email: string;
	latitude: string;
}

interface ICookie {
	blazin_rewards: Optional<IBlazinRewards>,
	is_authenticated: boolean
}

export const HOCLocationCheck: React.FC = ({children}) => {
	const cookies: ICookie = {
		blazin_rewards: getCookie<Optional<IBlazinRewards>>("blazin_rewards"),
		is_authenticated: getCookieBoolean("is_authenticated")
	}

	if ( cookies.blazin_rewards?.profileId && !cookies.blazin_rewards?.latitude && !cookies.is_authenticated ) {
		return (
			<MessageBox>
				<code>
					Please update your Buffalo Wild Wings app to the latest version to access Buffalo Wild Wings
					Play
				</code>
			</MessageBox>
		)
	}

	return (
		<Fragment>
			{children}
		</Fragment>
	)
}

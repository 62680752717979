import * as React from 'react';
import {IIconProps} from 'modules/types';

export const IconRevert: React.FC<IIconProps> = ( { title, width, height, color, onClick } ) => (
	<svg width={ `${ width }px` } height={ `${ height }px` } onClick={ onClick } viewBox="0 0 25 30">
		<title>{ title }</title>
		<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Icons-/-Reset" transform="translate(-3.000000, 0.000000)" fill={ color }>
				<path
					d="M25.4874372,9.71661397 C24.9535176,9.01718687 22.4723618,10.9247153 22.9748744,11.6241424 C24.1997487,13.2773338 24.8592965,15.2484465 24.8592965,17.3467278 C24.8592965,22.560639 20.6507538,26.8207859 15.5,26.8207859 C10.3492462,26.8207859 6.14070352,22.560639 6.14070352,17.3467278 C6.14070352,12.1328167 10.3492462,7.84087765 15.5,7.84087765 L16.3479899,7.84087765 L14.2123116,10.0027432 C13.5841709,10.6385861 15.1908108,12.895234 16.410804,12.2599853 L21.2160804,7.39578767 C21.8442211,6.75994485 21.8442211,5.77438848 21.2160804,5.13854565 L16.3479899,0.111736713 C15.7198492,-0.524106109 13.4899497,1.73313591 14.1180905,2.36897873 L16.3479899,4.69345568 L15.5,4.69345568 C8.59045226,4.69345568 3,10.3842489 3,17.3467278 C3,24.3092067 8.59045226,30 15.5,30 C22.4095477,30 28,24.3092067 28,17.3467278 C28,14.5490194 27.1520101,11.9102717 25.4874372,9.71661397 Z"
					id="Path123"/>
			</g>
		</g>
	</svg>
);

IconRevert.defaultProps = {
	title: 'Revert',
	width: 12,
	height: 12,
	color: '#fff'
};

import {differenceBy, first, flatten, flow, get, identity, isEmpty, last, partial, toPairs} from 'lodash';
import {
	fetchChecksumsJSONFailed,
	fetchChecksumsJSONSuccess,
	unsubscribeFromLiveScores,
} from 'modules/actions/checksums';
import {checksumsSelector} from 'modules/selectors';
import {ISagaAction} from 'modules/types';
import {Api, ApiError} from 'modules/utils/Api';
import {Saga, SagaIterator} from 'redux-saga';
import {all, call, delay, put, race, select, take} from 'typed-redux-saga';


const ONE_MIN = 15;
const THOUSAND = 1000;

export type LiveScoreSection = 'trivia' | 'ladder' | 'pp';

export interface ISubscribeToChecksums {
	section: LiveScoreSection
}

type TMap = {
	[key in LiveScoreSection]: Saga[]
}

/**
 * Keys for listener
 */
type CheckSumsKeys = 'contests';

type TMapChecksums = {
	[key in CheckSumsKeys]: TMap
}

/**
 * If we need add some additional actions.
 * ladder - just a sections of APP
 *
 */
const mapChecksumsToSaga: TMapChecksums = {
	'contests': {
		trivia: [],
		ladder: [],
		pp: []
	}
};

export const fetchChecksumsJSONSaga = function* () {
	try {
		const response = yield* call(Api.JSON.checksum);

		ApiError.CHECK(response);
		yield* put(fetchChecksumsJSONSuccess(response));
	} catch (e: any) {
		yield* put(fetchChecksumsJSONFailed());
	}
};


export const ChecksumsToSaga = function* (
	action: ISagaAction<ISubscribeToChecksums>
): SagaIterator {

	const {section} = action.payload;
	const checksums = yield* select(checksumsSelector);

	if (isEmpty(checksums)) {
		const {stopped_fetch} = yield* race<any>({
			task: call(fetchChecksumsJSONSaga),
			stopped_fetch: take(unsubscribeFromLiveScores)
		});

		if (stopped_fetch) {
			return
		}
	}
	/**
	 * I think here we should add a check on lockout
	 * if current is state is locked  then we request checksums per minute otherwise per hour
	 */

	const TIME = THOUSAND * ONE_MIN;

	const {stopped} = yield* race<any>({
		wait: delay(TIME),
		stopped: take(unsubscribeFromLiveScores)
	});

	if (!stopped) {
		const [old_checksums] = yield* all<any>([
			select(checksumsSelector),
			call(fetchChecksumsJSONSaga)
		]);

		const new_checksums = yield* select(checksumsSelector);

		const requestsForChanges = flatten(differenceBy(
			toPairs(String(old_checksums)),
			toPairs(new_checksums),
			(arr: string[]) => {
				return last<string>(arr ? arr : ['']);
			}
		).map(
			flow([
				first,
				partial(get, mapChecksumsToSaga),
				partial(get, partial.placeholder, section)
			])
		).filter(identity));

		if (!isEmpty(requestsForChanges)) {
			yield* all<any>(requestsForChanges.map((request) => {
				return call(request);
			}));
		}

		yield* call(ChecksumsToSaga, action);
	}
};




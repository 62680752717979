import React, {useState} from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	margin-bottom: 20px;
`;

const QuestionContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;
	margin-bottom: 10px;
`;
const Question = styled.div`
	font-family: "Barlow-Bold", sans-serif;
`;
const Button = styled.button`
	text-decoration: underline;
	box-shadow: none;
	border: none;
	background: transparent;
	cursor: pointer;
`;

const ExpandableContainer = styled.div<{isOpen: boolean}>`
	height: ${(props) => (props.isOpen ? "100%" : 0)};
	overflow: hidden;
`;

const ExpandableContainerContent = styled.div`
	font-size: 12px;
	line-height: 16px;
`;

interface IProps {
	question: string;
	text: string;
}

export const QuestionExpandable: React.FC<IProps> = ({question, text}: IProps) => {
	const [state, changeState] = useState(false);
	const changeStateHandler = () => {
		changeState(!state);
	};
	return (
		<Wrapper>
			<QuestionContainer>
				<Question>{question}</Question>
				<Button onClick={changeStateHandler}>{state ? "Hide" : "View"}</Button>
			</QuestionContainer>
			<ExpandableContainer isOpen={state}>
				{/* eslint-disable-next-line react/no-danger */}
				<ExpandableContainerContent dangerouslySetInnerHTML={{__html: text}} />
			</ExpandableContainer>
		</Wrapper>
	);
};

import {HOCPopupWrapper} from 'components';
import {eq, get, map, noop} from 'lodash';
import {fetchBackDoorInfo} from 'modules/actions/backdoor';
import {userLoginBackDoor} from 'modules/actions/user';
import {IBackDoorInfo, IGlobalStore} from 'modules/types';
import {getUserDataFromQueryString} from 'modules/utils/url';
import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {HOCisTrue} from 'components/HOCisTrue';


const Rules = styled.div`
  text-align: center;
  color: #000;

  p {
	color: #434343;
	font-family: 'Eund', sans-serif;
	font-size: 12px;
	font-weight: 500;
	line-height: 13px;
	max-width: 26.667em;

	margin: 0 auto;
  }

  form {
	width: 100%;

	select, input {
	  width: 100%;
	  height: 40px;
	  margin-bottom: 20px;
	  box-sizing: border-box;
	}

	span {
	  padding: 10px 0;
	  color: #fff;
	  display: block;
	}
  }

`;

const RegularButtonCustom = styled.button`
  display: block;
  background: #FFC600;
  color: #121212;
  width: 100%;
  padding: 12px 24px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none;

  &:disabled {
	opacity: 0.5;
  }
`;

interface IState {
	email: string,
	email_select: string,
	store_id_select: number,
	store_id: number,
	visible: boolean
}

interface IProps extends RouteComponentProps {
	fetchBackDoorInfo: typeof fetchBackDoorInfo,
	userLoginBackDoor: typeof userLoginBackDoor
	backdoor_info: IBackDoorInfo
}

class HOCBackDoorComponent extends React.Component<IProps, IState> {

	public state = {
		email: '',
		email_select: '',
		store_id_select: 0,
		store_id: 0,
		visible: true,
	};

	get isCanContinue() {
		const {email, email_select} = this.state;

		return Boolean(email) || Boolean(email_select);
	}

	public componentDidMount(): void {
		if (this.isBackDoorPath()) {
			const {fetchBackDoorInfo: fetch} = this.props;
			fetch();
		}
	}

	public handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
		const {name, value} = e.currentTarget;

		if (!name) {
			return;
		}

		// @ts-ignore
		this.setState({
			[name]: String(value),
			email_select: '',
		});
	};

	public handleEmailViaSelect = (e: React.SyntheticEvent<any>) => {
		this.setState({
			email_select: e.currentTarget.value,
			email: ''
		});
	};

	public handleStoreViaSelect = (e: React.SyntheticEvent<any>) => {
		this.setState({
			store_id_select: Number(e.currentTarget.value),
			store_id: 0
		});
	};

	public handleStore = (e: React.SyntheticEvent<any>) => {
		this.setState({
			store_id: Number(e.currentTarget.value),
			store_id_select: 0
		});
	};

	public handleBackDoorLogin = ({email = '', store_id = 0}: Partial<IState>) => {
		const {userLoginBackDoor: userLogin} = this.props;
		userLogin({email, store_id});
	};

	public handleContinue = (e: any) => {
		e.preventDefault();
		const {email, email_select, store_id, store_id_select} = this.state;

		const payload = {
			email: email_select || email,
			store_id: store_id_select || store_id,
		};

		this.handleBackDoorLogin(payload);

		this.setState({
			visible: false,
		});
	};

	public render() {
		const {visible, email_select, store_id_select} = this.state;
		const {backdoor_info, children, location: {pathname},} = this.props;

		const is_home = eq(pathname, '/');

		if (!this.isBackDoorPath() || !is_home || !visible) {
			return children
		}

		return (
			<HOCPopupWrapper visible={visible} bg_inner="#fff" with_title={true}>
				<Rules>
					<form autoComplete="on" onSubmit={noop}>

						<span>Select an email</span>
						<select onBlur={this.handleEmailViaSelect} onChange={this.handleEmailViaSelect}>
							<option value="" aria-selected={'false'}>Emails</option>
							{map(backdoor_info.emails, (email: string, index: number) => {
								return (
									<option key={index} value={email} aria-selected={'false'}>{email}</option>
								)
							})}
						</select>


						<HOCisTrue when={!email_select}>
							<span>OR</span>
							<input
								placeholder="Email"
								type="email"
								name="email"
								onChange={this.handleChange}
								autoComplete="on"/>
						</HOCisTrue>

						<HOCisTrue when={!store_id_select}>
							<input
								placeholder="Store ID"
								type="text"
								name="store_id_select"
								onChange={this.handleStore}
								autoComplete="on"/>
							<span>OR</span>
						</HOCisTrue>

						<select onBlur={this.handleStoreViaSelect} onChange={this.handleStoreViaSelect}>
							<option value="" aria-selected="false">Not Checked IN</option>
							{map(backdoor_info.common_stores, (store: any, index: number) => {
								return (
									<option
										key={index}
										value={store.store_id}
										aria-selected="false">
										{store.name} ({store.store_id})
									</option>
								)
							})}
						</select>

					</form>
				</Rules>
				<RegularButtonCustom
					disabled={!this.isCanContinue}
					onClick={this.handleContinue}
				>
					CONTINUE
				</RegularButtonCustom>
			</HOCPopupWrapper>
		);
	}

	private isBackDoorPath = () => {
		const parsed = getUserDataFromQueryString();

		return eq(get(parsed, 'backdoor', ''), 'true');
	}
}

const mapDispatchToProps = {
	fetchBackDoorInfo,
	userLoginBackDoor
};

const mapStateToProps = (state: IGlobalStore) => ({
	backdoor_info: state.backdoor_info
});

export const HOCBackDoor = withRouter(connect(mapStateToProps, mapDispatchToProps)(HOCBackDoorComponent));

import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import styled, {css} from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {getMenuState} from 'modules/selectors';
import {IconChevron, IconCross} from 'components';
import {changeMenuState} from 'modules/actions';
import {NavLink, useLocation} from 'react-router-dom';
import {get, partial} from 'lodash';
import stairsIcon from 'assets/img/icons/icon-stairs.svg';
import presentIcon from 'assets/img/icons/icon-present.svg';
import infoIcon from 'assets/img/icons/icon-info-letter.svg';
import roundCheckIcon from 'assets/img/icons/icon-round-check.svg';
import iconTrivia from 'assets/img/icons/icon-trivia.svg';
import {NAV_LINKS} from 'modules/constants';
import {HOCisTrue} from 'components/HOCisTrue';
import {IS_AO_DISABLED, IS_PAS_SECRET_PASSED} from 'modules/utils';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.7);
  z-index: 4;
  color: #fff;
`;
const MenuWrapper = styled.div<{ isOpen: boolean }>`
  max-width: 80%;
  width: 100%;
  height: 100%;
  background: ${({theme}) => theme.menu?.background || '#362E2C'};
  position: absolute;
  left: ${props => props.isOpen ? '0' : '-100%'};
  transition: left .2s ease;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled.div`
  svg {
	padding: 20px;
	margin-left: -20px;
	margin-top: -20px;
  }
`;
const MainMenu = styled.div`
  margin-top: 27px;
`;
const BottomMenu = styled.div`
  margin-top: auto;
`;

const MenuStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  height: 40px;
  border-bottom: ${({theme}) => theme.menu?.border || '1px solid #312A28'};
  font-size: 12px;

  &.active {
	color: #fff;
	font-family: 'Barlow-Bold', sans-serif;
  }
`
const ExpandItem = styled.div`
  ${MenuStyles};
`;
const MenuItem = styled(NavLink)`
  ${MenuStyles};
`;
const MenuItemExternal = styled.a`
  ${MenuStyles};
`;

const ChevronWrapper = styled.div<{ isOpen: boolean }>`
  height: 100%;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
	transform: rotate(${props => props.isOpen ? '180deg' : 0});
	transition: transform .2s linear;
  }
`;

const SubMenuWrapper = styled.div`
  padding: 0 8px;
  border-bottom: ${({theme}) => theme.menu?.border || '1px solid #312A28'};
`;

const SubMenuItem = styled(MenuItem)`
  border-bottom: 0;
  justify-content: flex-start;
`;

const SubMenuItemExternal = styled.a`
  ${MenuStyles};
  border-bottom: 0;
  justify-content: flex-start;
`;

const MenuItemIcon = styled.img`
  max-width: 12px;
  margin-right: 10px;
  max-height: 12px;
`;

export type MenuType = 'picks' | 'trivia' | "pas";

// const isUAT = process.env.REACT_APP_ENVIRONMENT === 'uat';
/* tslint:disable:max-func-body-length */
export const Menu = () => {
	const dispatch = useDispatch();
	const menuState = useSelector(getMenuState);
	const [isOpen, changeOpenState] = useState(false);
	const [subMenuState, changeSubMenuState] = useState({
		pas: false,
		picks: false,
		trivia: true,
	});
	const {pathname} = useLocation();
	const lastLocation = useRef(pathname);

	const closeMenu = useCallback(() => {
		changeOpenState(false);
		setTimeout(() => {
			dispatch(changeMenuState(false))
		}, 200);
	}, [dispatch]);

	const setLastLocation = useCallback(() => {
		lastLocation.current = pathname;
	}, [pathname]);

	useEffect(() => {
		setTimeout(() => {
			changeOpenState(menuState);
		}, 50);
	}, [menuState]);

	useEffect(() => {
		if (pathname !== lastLocation.current && pathname !== '/') {
			closeMenu();
			setLastLocation();
		}
	}, [pathname, closeMenu, setLastLocation]);


	const toggleSubMenuState = (menu: MenuType, e: React.SyntheticEvent) => {
		e.preventDefault();
		e.stopPropagation();
		changeSubMenuState({
			...subMenuState,
			[menu]: !subMenuState[menu]
		})
	}
	const projName = get(process.env, 'REACT_APP_PROJECT_NAME', '');
	const nav = NAV_LINKS[projName] || {};

	const getNavItem = (itemName: string) => get(nav, `${itemName}`, {});

	const getEcoLink = () => {
		const {path, isExternal} = getNavItem('eco');
		return isExternal ? <MenuItemExternal href={path}>Buffalo Wild Wings Play</MenuItemExternal> :
			<MenuItem to={path}>Buffalo Wild Wings Play</MenuItem>;
	}
	const getExpandItemActiveClass = (pathName: string) => pathName === process.env.REACT_APP_GAME ? 'active' : ''
	const getMenuItem = (pathName: MenuType, name: string) => {
		const toggle = <ChevronWrapper isOpen={subMenuState[pathName]}
									   onClick={partial(toggleSubMenuState, pathName)}>
			<IconChevron color={'#fff'}/>
		</ChevronWrapper>
		return <ExpandItem className={getExpandItemActiveClass(pathName)}
						   onClick={partial(toggleSubMenuState, pathName)}>
			{name}
			{toggle}
		</ExpandItem>;
	}

	const getSabMenuItem = (pathName: string, name: string, icon: string) => {
		const {path, isExternal} = getNavItem(pathName);
		const iconElement = icon ? <MenuItemIcon src={icon}/> : '';
		return isExternal ? (
				<SubMenuItemExternal href={path}>
					{iconElement}
					{name}
				</SubMenuItemExternal>
			) :
			(
				<SubMenuItem to={path}>
					{iconElement}
					{name}
				</SubMenuItem>
			);
	};

	return menuState ? (
		<Wrapper>
			<MenuWrapper isOpen={isOpen}>
				<CloseButton>
					<IconCross onClick={closeMenu}/>
				</CloseButton>
				<MainMenu>
					{getEcoLink()}
					<HOCisTrue when={IS_PAS_SECRET_PASSED}>
						{getMenuItem('pas', 'Pick A Side')}
						<HOCisTrue when={subMenuState.pas}>
							<SubMenuWrapper>
								{getSabMenuItem('pas', 'Pick A Side Contests', roundCheckIcon)}
								{getSabMenuItem('pasPrizes', 'Prizes', presentIcon)}
								{getSabMenuItem('pasInfo', 'Information', infoIcon)}
							</SubMenuWrapper>
						</HOCisTrue>
					</HOCisTrue>
					<Fragment>
						<HOCisTrue when={!IS_AO_DISABLED}>
							{getMenuItem('trivia', 'Blazin’ Trivia')}
							<HOCisTrue when={subMenuState.trivia}>
								<SubMenuWrapper>
									{getSabMenuItem('trivia', 'Blazin’ Trivia', iconTrivia)}
									{getSabMenuItem('triviaLeaderboard', 'Leaderboards', stairsIcon)}
									{getSabMenuItem('triviaPrizes', 'Prizes', presentIcon)}
									{getSabMenuItem('triviaInfo', 'Information', infoIcon)}
								</SubMenuWrapper>
							</HOCisTrue>
						</HOCisTrue>
					</Fragment>
				</MainMenu>
				<BottomMenu>
					{getSabMenuItem('privacy', 'Privacy Policy', '')}
				</BottomMenu>
			</MenuWrapper>
		</Wrapper>
	) : null
}

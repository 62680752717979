import {getFilters} from 'modules/selectors/filters';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
// import { IconCalendar } from 'components/Icons/IconCalendar';
import {SortByFilter} from 'components/Filters/SortByFilter';
import {partial} from 'lodash';
import {TimesAndGamesFilter} from 'components/Filters/TimesAndGamesFilter';
import {ContestCalendar} from 'components/ContestCalendar';

const PageFilters = styled.div`
	color: #362E2C;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 20px;
`;
const Filter = styled.div`
	font-size: 12px;
	text-decoration: underline;
	margin-left: 20px;
	cursor: pointer;
	display: flex;
	align-content: center;
`;
const AddFilter = styled.div`
	cursor: pointer;
	margin-right: 15px;
	margin-left: 10px;
`;

export const Filters = () => {
	const [sortModalState, changeSortModalState] = useState( false );
	const [filterModalState, changeFilterModalState] = useState( false );
	const { games } = useSelector( getFilters );

	return (
		<React.Fragment>
			<PageFilters>
				<Filter onClick={ partial( changeSortModalState, !sortModalState ) }>Sort</Filter>
				<Filter
					onClick={ partial( changeFilterModalState, !filterModalState ) }>Filter</Filter><AddFilter>&bull; 1</AddFilter>
				<ContestCalendar/>
			</PageFilters>
			<SortByFilter modalState={ sortModalState }
						  toggleModalState={ partial( changeSortModalState, !sortModalState ) }
			/>
			<TimesAndGamesFilter modalState={ filterModalState }
			                     key={games}
								 toggleModalState={ partial( changeFilterModalState, !filterModalState ) }/>
		</React.Fragment>

	)
}

import * as React from 'react';
import styled from 'styled-components';
import icon_close from 'assets/img/icons/icon-cancel.svg';
import blasin_logo from 'assets/img/logo.svg';

export const LogoWrapper = styled.div`
	width: 100%;
	max-width: 160px;
	display: block;
	padding: 1em 0;
	box-sizing: border-box;
	text-align: center;
	margin: 0 auto;
	img{
		width: auto;
		max-height: 65px;
	}
`;

interface IMainPopupWrapper {
	bg?: string,
	visible: boolean;
	position?: string
}

const MainPopupWrapper = styled.div<IMainPopupWrapper>`
	display:${({visible}) => ( visible ? 'flex' : 'none' )};
	position: fixed;
	justify-content: center;
	align-items: ${({position}) => position};
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	z-index: 999;
	overflow-y: scroll;
	padding: 1.5em 0;
	background: rgba(0, 0, 0, 0.5);
`;

interface ITitleProps {
	close_icon?: boolean
}

const Title = styled.div<ITitleProps>`
  width: 100%;
  height: 35px;
  border-radius: 4px 4px 0 0;
  position: relative;
  &:before{
    position: absolute;
    right: 10px;
    top: 0;
    content: '';
    width: 35px;
    height: 35px;
    color: black;
    display: ${({close_icon}) => ( close_icon ? 'block' : 'none' )};
    background-image: url(${icon_close});
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
`;

interface IContainer {
	position: string,
	bg_inner: string
}

const Container = styled.div<IContainer>`
	display:flex;
	flex-direction: column;
	width: 90%;
	border-radius: 5px;
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
	overflow-y: auto;
	height: 100%;
	position: relative;
	max-width: 360px;
	margin: 0 auto;
	padding: 0 20px;
	background-color: #362E2C;
	max-height: 800px;
`;

interface IProps {
	children: React.ReactNode,
	visible: boolean,
	with_title?: boolean,
	with_close_icon?: boolean,
	with_logo?: boolean,
	bg_inner: string,
	onClose?: (e: React.SyntheticEvent) => void,
	position?: string,
	scrollRef?: any,
}

// const prevent = (e: React.SyntheticEvent<HTMLDivElement>) => {
// 	e.stopPropagation();
// 	e.preventDefault();
// };

export const HOCPopupWrapper = ({
	                                children,
	                                visible,
	                                bg_inner,
	                                onClose = (e: React.SyntheticEvent) => e.preventDefault(),
	                                with_title = false,
	                                with_logo = false,
	                                with_close_icon = false,
	                                scrollRef,
	                                position = 'center'
                                }: IProps) => {
	const onContainerClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();
	};

	return (
		<MainPopupWrapper visible={visible} onClick={onClose}>
			<Container
				ref={scrollRef}
				bg_inner={bg_inner}
				onClick={onContainerClick}
				position={position}
			>
				{with_title ? (
					<div>
						{
							with_close_icon ? (
								// @ts-ignore
								<Title close_icon={true} onClick={onClose}/>
							) : (
								<Title/>
							)}
					</div>
				) : null}
				{with_logo ? (
					<LogoWrapper>
						<img src={blasin_logo} alt="logo"/>
					</LogoWrapper>
				) : null}
				{children}
			</Container>
		</MainPopupWrapper>
	);
};

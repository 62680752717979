import * as Sentry from '@sentry/browser';
import {IStore} from 'modules/reducers/stores';
import {IS_LOC, IS_UP_SHOW_ENABLED} from 'modules/utils/constants';
import {IframeBridge} from 'wisper-rpc';

export abstract class UpShow {
	private static UP_SHOW_BRIDGE = !IS_LOC && IS_UP_SHOW_ENABLED ? new IframeBridge(window.parent) : null;

	public static READY() {
		if ( UpShow.UP_SHOW_BRIDGE ) {
			return UpShow.UP_SHOW_BRIDGE.invoke('onReady');
		} else {
			console.log('No UP_SHOW_BRIDGE')
		}

		return new Promise(( (_resolve, reject) => reject() ))
	}

	public static PLAY(onPlay: () => void) {
		if ( UpShow.UP_SHOW_BRIDGE ) {
			UpShow.UP_SHOW_BRIDGE.exposeFunction('play', onPlay);
		}
	}

	public static DONE() {
		if ( UpShow.UP_SHOW_BRIDGE ) {
			UpShow.UP_SHOW_BRIDGE.invoke('onDone');
		}
		console.log('Fanhub - DONE')
	}

	public static TESLA_CACHE_X(src: string, store: IStore | undefined, callback: (src: string) => void) {
		if ( !UpShow.UP_SHOW_BRIDGE ) {
			callback(src);
			return
		}
		if ( store?.caching_video_enabled ) {
			UpShow.UP_SHOW_BRIDGE.invoke('teslaCacheX', [src])
				.then((cached_src: string) => {
					callback(cached_src)
				})
				.catch((e: Error) => {
					callback(src);
					Sentry.captureException(e);
				});
		} else {
			callback(src)
		}
	}

	public static ERROR(error: Error) {
		Sentry.captureException(error);
		console.log('Fanhub error')
		if ( UpShow.UP_SHOW_BRIDGE ) {
			UpShow.UP_SHOW_BRIDGE.invoke('onError', [error]);
		}
	}

	public static KILL() {
		console.log('Contest has been killed')
		if ( UpShow.UP_SHOW_BRIDGE ) {
			UpShow.UP_SHOW_BRIDGE.invoke('onError', [new Error("Kill switch")]);
		}
	}
}
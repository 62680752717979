// import { User } from "modules/utils/User";
import {fetchAdSlotsJSON} from 'modules/actions/adSlots';
import {getAdSlots} from 'modules/selectors/ad_slot';
import {uniqueId} from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    margin: 0 auto;
    flex: 0 0 auto;
`;

// const deepLink = (adSlot: any) => {
// 	const user = User.GET_USER_OBJECT();
//
// 	const is_app = user && user.device_type && ( user.device_type === 'ios' || user.device_type === 'android' );
//
// 	const data = { command: "navigate", url: adSlot.link };
// 	if ( is_app ) {
// 		return window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(data));
// 	} else {
// 		return null
// 	}
// }

// const Link = styled.a`
//     position: relative;
//     img {
//     	max-width: 100%;
//     	margin-bottom: -20px;
//     }
// `;

const AdDiv = styled.a`
    position: relative;
    img {
    	max-width: 340px;
    	margin-bottom: 20px;
    }
`;

export const AdSlots : React.FC = () => {
	const dispatch = useDispatch();
	const { adSlot, isFetched } = useSelector( getAdSlots );
	useEffect( () => {
		dispatch( fetchAdSlotsJSON() );
	}, [dispatch] );

	return isFetched ? (
		<Wrapper>
			<AdDiv href={adSlot.externalLink} target={'_blank'}>
				<img src={`${process.env.REACT_APP_AD_IMAGES_URL}${adSlot.image}`} alt={adSlot.link}
				     id={uniqueId('promo_link_')}/>
			</AdDiv>
		</Wrapper>
	) : null;
};

import {keyframes} from 'styled-components';


export const rotation = keyframes`
    from {
         transform: rotate(0deg);
    }
    to {
        opacity: 1;
        transform: rotate(360deg);
    }
`;

import {call, put, select, take} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';
import {globalError} from 'modules/actions/errors';
import {PlayHubApi, Api, ApiError} from 'modules/utils/Api';
import {
	fetchEventsFailed,
	fetchEventsSuccess,
	fetchIsBettingAllowedSuccess,
	IFetchEventsPayload,
	postUserLoginSuccess,
} from 'modules/actions';
import {ISagaAction} from 'modules/types';
import {isLoggedIn} from 'modules/selectors/user';

export const fetchEventsSaga = function* (action: ISagaAction<IFetchEventsPayload>): SagaIterator {
	const isUserLoggedIn = yield select(isLoggedIn);
	if (!isUserLoggedIn && !action.payload.skipLoginCheck) {
		yield take(postUserLoginSuccess);
	}
	try {
		delete action.payload.skipLoginCheck;
		const response = yield call(PlayHubApi.Dashboard.events, action.payload);
		ApiError.CHECK(response);
		yield put(fetchEventsSuccess(response.result.dashboard));
	} catch (e: any) {
		yield put(globalError(e as Error));
		yield put(fetchEventsFailed());
	}
};

export const fetchIsBettingAllowedSaga = function* (action: ISagaAction<boolean>): SagaIterator {
	try {
		if (action.payload) {
			const response = yield call(Api.User.getBettingState);
			yield put(fetchIsBettingAllowedSuccess(response.result.success));
		} else {
			yield put(fetchIsBettingAllowedSuccess(false));
		}

	} catch (e: unknown) {
		yield put(fetchIsBettingAllowedSuccess(false));
	}
}

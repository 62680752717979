import * as React from 'react';
import {IIconProps} from 'modules/types';

export const IconCross:React.FC<IIconProps> = ({ title, width, height, color, onClick }) => (
	<svg  width={`${ width }px`} height={`${ height }px`} onClick={ onClick } viewBox="0 0 12 12">
		<title>{ title }</title>
		<g transform="translate(-14.000000, -14.000000)" fill={color}>
			<g transform="translate(14.000000, 14.000000)">
				<path d="M1.80637681,0.12269166 L5.99984065,4.31588063 L10.1936232,0.12269166 C10.3572121,-0.0408972199 10.6224421,-0.0408972199 10.7860309,0.12269166 L11.8773083,1.21396907 C12.0408972,1.37755795 12.0408972,1.64278793 11.8773083,1.80637681 L7.68338178,5.99984065 L11.8773083,10.1936232 C12.0408972,10.3572121 12.0408972,10.6224421 11.8773083,10.7860309 L10.7860309,11.8773083 C10.6224421,12.0408972 10.3572121,12.0408972 10.1936232,11.8773083 L5.99984065,7.68338178 L1.80637681,11.8773083 C1.64278793,12.0408972 1.37755795,12.0408972 1.21396907,11.8773083 L0.12269166,10.7860309 C-0.0408972199,10.6224421 -0.0408972199,10.3572121 0.12269166,10.1936232 L4.31588063,5.99984065 L0.12269166,1.80637681 C-0.0408972199,1.64278793 -0.0408972199,1.37755795 0.12269166,1.21396907 L1.21396907,0.12269166 C1.37755795,-0.0408972199 1.64278793,-0.0408972199 1.80637681,0.12269166 Z" />
			</g>
		</g>
	</svg>

);

IconCross.defaultProps = {
	title: 'Cross',
	width: 12,
	height: 12,
	color: '#fff'
};


import {fetchAdSlotsJSONFailed, fetchAdSlotsJSONSuccess} from 'modules/actions/adSlots';
import {PlayHubApi} from 'modules/utils/Api';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

export const adSlotsSaga = function* (): SagaIterator {

	const adSlots = yield call(PlayHubApi.JSON.ad_slot);

	if ( adSlots.error ) {
		yield put(fetchAdSlotsJSONFailed());
		return;
	}

	yield put(fetchAdSlotsJSONSuccess(adSlots.ad_slot));

};

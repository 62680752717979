import {EnumFiltersPerGame, EnumEventsPayloadSort, EnumTimesOfGame} from 'modules/utils/enums';
import {createReducer} from 'redux-act';
import {setFilter} from 'modules/actions/filters';

export interface IFilters {
	sortBy: EnumEventsPayloadSort;
	times: EnumTimesOfGame;
	games: EnumFiltersPerGame;
	date: string;
}

const initialState: IFilters = {
	sortBy: EnumEventsPayloadSort.ASC,
	times: EnumTimesOfGame.All,
	games: EnumFiltersPerGame.All,
	date: ''
}

export const filters = createReducer<IFilters>( {}, initialState )
	.on( setFilter, ( state, payload: Partial<IFilters> ) => ({
		...state,
		...payload
	}) )
;

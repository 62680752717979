import * as Sentry from '@sentry/browser';
import {get, isString} from 'lodash';
import {showGlobalError} from 'modules/actions';
import {IResponseError, ISagaAction} from 'modules/types';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';


type TPayload = IResponseError | string;

const onError = {
	// * [ ApiError.AUTH_REQUIRED ](error: TPayload): SagaIterator {
	// 	if ( yield select(isLoggedIn) ) {
	// 		User.CLEAR();
	// 		window.location.reload();
	// 	}
	//
	// 	yield call(defaultErrorHandler, error);
	// }
};

const defaultErrorHandler = (payload: TPayload) => {
	// const error_code = get(payload, 'code');

	// if ( !onError[ error_code ] ) {
	// 	Sentry.captureException(payload);
	// }

	Sentry.captureException(payload);

	if ( isString(payload) ) {
		throw new Error(payload);
	}


	if ( isString(payload.message) ) {
		throw new Error(payload.message);
	}
};

export const errorsManagerSaga = function* ({ payload }: ISagaAction<TPayload>): SagaIterator {
	const error_code = get(payload, 'code');

	try {
		const onErrorHandler = get(onError, error_code, defaultErrorHandler);
		yield call(onErrorHandler, payload);
	} catch ( err: any ) {
		yield put(showGlobalError({
			text: err.message,
			code: error_code || 0
		}));
	}
};

import {createAction} from 'redux-act';
import {IError, IModelUser} from 'modules/types';
import {IActionBackdoor, IUserLoginResponse, IUserObject} from 'modules/reducers/user';


export const postUserLogout = createAction();
export const postReAuthorizeUser = createAction<IUserObject>();

export const postUserLogin = createAction<IUserObject>();
export const postUserLoginSuccess = createAction<IUserLoginResponse>();
export const postUserLoginFailed = createAction<IError>();

export const userLoginBackDoor = createAction<IActionBackdoor>();

export const postUserUpdateSuccess = createAction<IUserObject>();
export const postUserUpdateFailed = createAction<IError>();
export const postUserUpdate = createAction<IUserObject>();
export const termsModalAccept = createAction<Partial<IModelUser>>();

export const staffEntrySuccess = createAction();
export const staffEntryError = createAction<IError>();
export const staffEntryStart = createAction();
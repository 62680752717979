import {LiveScoreSection} from 'modules/sagas/live_score';
import React from 'react';
import {connect} from 'react-redux';
import * as actions from 'modules/actions';

interface ILiveScore {
	subscribed_to_live_scores: boolean
}

interface IOwnProps {
	section: LiveScoreSection,
	children: React.ReactNode
}

type Props = IDispatchProps & IOwnProps;
type State = ILiveScore;

/**
 * Live score subscriber
 */
class HOCLiveScoreComponent extends React.Component<Props, State> {

	public state = {
		subscribed_to_live_scores: false
	};

	public componentDidMount(): void {
		this.subscribeToLiveScore();
		window.addEventListener('online', () => this.subscribeToLiveScore());
		window.addEventListener('offline', () => this.unsubscribeFromLiveScoring());
	}

	/**
	 * @ignore
	 */
	public componentWillUnmount() {
		this.unsubscribeFromLiveScoring();
	}

	/**
	 * @ignore
	 */
	public render(): React.ReactNode {
		return this.props.children;
	}

	private subscribeToLiveScore() {

		const { subscribeToLiveScores: subscribe } = this.props;
		const { subscribed_to_live_scores } = this.state;

		if (!subscribed_to_live_scores) {
			this.setState({ subscribed_to_live_scores: true }, () => subscribe({
				section: this.props.section
			}));
		}

		return true;
	}

	private unsubscribeFromLiveScoring() {

		const { unsubscribeFromLiveScores: unsubscirbe } = this.props;
		unsubscirbe();
		this.setState({
			subscribed_to_live_scores: false
		});

		return this;
	}
}

interface IDispatchProps {
	readonly unsubscribeFromLiveScores: () => void
	readonly subscribeToLiveScores: (prop: any) => void
}


const mapDispatchToProps: IDispatchProps = {
	unsubscribeFromLiveScores: actions.unsubscribeFromLiveScores,
	subscribeToLiveScores: actions.subscribeToLiveScores,
};

export const LiveScore = connect(null, mapDispatchToProps)(HOCLiveScoreComponent);


import {useRef, useEffect, useState} from 'react';


export const usePrevious = <T = undefined>(value: T): T | undefined => {
	const ref = useRef<T>();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

export const useMediaQuery = (query: string) => {
	const mediaMatch = window.matchMedia(query);
	const [ matches, setMatches ] = useState(mediaMatch.matches);

	useEffect(() => {
		const handler = (e: MediaQueryListEvent) => setMatches(e.matches);
		if ( ( "addListener" in mediaMatch ) ) {
			mediaMatch.addListener(handler);
		} else {
			// @ts-ignore
			mediaMatch.addEventListener('change', handler);
		}

		return () => {
			if ( "addListener" in mediaMatch ) {
				mediaMatch.removeListener(handler);
			} else {
				// @ts-ignore
				mediaMatch.removeEventListener('change', handler);
			}
		};
	});

	return matches;
};

export const useLocalStorage = <T>(key: string, defaultValue: T) => {
	const [value, setValue] = useState(() => {
		try {
			const item = window.localStorage.getItem(key);
			return item ? JSON.parse(item) : defaultValue;
		} catch (e: any) {
			return defaultValue;
		}
	});

	useEffect(() => {
		window.localStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);

	return [value, setValue];
};


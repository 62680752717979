import {createReducer} from 'redux-act';
import * as actions from 'modules/actions';

export interface IStatsReducerState {
	total_contests_played: number;
	highest_pp_rank: number;
	reward_points: number;
	highest_trivia_rank: number;
	reward_items_won: number;
	highest_streak: number;
	pp: IPPTriviaStats;
	trivia: IPPTriviaStats;
}

interface IPPTriviaStats {
	reward_points: number;
	daily_numbers_played_contests: Record<string, unknown> | [];
}

const initialState: IStatsReducerState = {
	total_contests_played: 0,
	highest_pp_rank: 0,
	reward_points: 0,
	highest_streak: 0,
	reward_items_won: 0,
	highest_trivia_rank: 0,
	pp: {
		reward_points: 0,
		daily_numbers_played_contests: [],
	},
	trivia: {
		reward_points: 0,
		daily_numbers_played_contests: [],
	},
};

export const stats = createReducer<IStatsReducerState>( {}, initialState );

stats.on( actions.fetchStatsSuccess, ( state: IStatsReducerState, payload: IStatsReducerState ) => ({
	...state,
	...payload
}) );
stats.on( actions.fetchStatsFailed, () => (initialState) );

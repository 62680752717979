import {getFilters} from 'modules/selectors/filters';


import {EnumEventStatus, EnumFiltersPerGame, SegmentStatus} from 'modules/utils/enums';
import {chain, filter, first, last} from 'lodash';
import {IEvent, IEvents, IFilters} from 'modules/reducers';
import {createSelector} from 'reselect';
import {EnumEventGame} from 'modules/utils';
import {IGlobalStore} from 'modules/types';


export const getAllEvents = (state: IGlobalStore) => {
	return state.dashboard?.events;
}

export const getIsBettingAllowed = (state: IGlobalStore): boolean => state.dashboard?.isBettingAllowed;

const filterEvent = ({sortBy, games}: IFilters, events: IEvent[]): IEvent[] => chain(events)
	.filter(event => games === EnumFiltersPerGame.All || event.game === games)
	.orderBy([
		'game', // for sorting trivia above picksandprops
		(event: any) => {
			return new Date(event.start).getTime();
		},
	], ['desc', sortBy])
	.value();

const filterByPositionTriviaEvents = (events: IEvent[], position: number) => {
	return filter(events, (event: IEvent) => {
		if (event.game === EnumFiltersPerGame.Trivia && event.position) {
			return event.position === position;
		}
		return true;
	});
};

const mergeTriviaEvents = (events: IEvent[]) => {
	let countTrivias = 0;
	return filter(events, (event: IEvent) => {
		if (event.game === EnumFiltersPerGame.Trivia && event.position) {
			if (!countTrivias) {
				countTrivias++;
				return true;
			}
			return false;
		}
		return true;
	})
}

export const getFilteredEvents = createSelector([getAllEvents, getFilters], (events, filters): IEvents => {
	return {
		completed: filterEvent(filters, filterByPositionTriviaEvents(events.completed, 2)) || [],
		live: filterEvent(filters, mergeTriviaEvents(events.live)) || [],
		upcoming: filterEvent(filters, filterByPositionTriviaEvents(events.upcoming, 1)) || []
	}
});

export const getFilteredEventsPerGameSelector = createSelector(
	[getAllEvents],
	(events) => {
		return (game: IFilters['games']) => filter(events.live, {
			game
		})
	}
);

const filterTriviaEvents = (incomingArray: IEvent[]) => {
	if (!incomingArray) {
		return [];
	}
	return incomingArray.filter(event => event.game === EnumEventGame.Trivia);
}

export const getTriviaEvents = createSelector(
	getAllEvents,
	(events) => {
		const liveEvent = first(filterTriviaEvents(events?.live));
		if (liveEvent) {
			return liveEvent;
		}
		const upcomingEvent = first(filterTriviaEvents(events?.upcoming));
		if (upcomingEvent) {
			return upcomingEvent;
		}
		const completedEvents = filterTriviaEvents(events?.completed);
		return last(completedEvents);
	}
)

const filterAOEvent = (event: IEvent) => event.game === EnumFiltersPerGame.AO;

const filterPASEvent = (event: IEvent) => event.game === EnumFiltersPerGame.PAS;

export const getAOEvents = createSelector(getAllEvents, (events) => {
	const live = events.live.filter(filterAOEvent);
	const upcoming = events.upcoming.filter(filterAOEvent);
	const rounds = [...live, ...upcoming]
		.flatMap((event) => event.rounds)
		.filter((round) => {
			if (!round) {
				return false;
			}
			return [SegmentStatus.Live, SegmentStatus.Scheduled].includes(round.status);
		})
		.sort((round1, round2) =>{
			const r1Start = new Date(round1!.start_date_time).getTime();
			const r2Start = new Date(round2!.start_date_time).getTime();
			return r1Start - r2Start;
		});

	const nextUpcoming = [...live, ...upcoming].find((event) => event.status === EnumEventStatus.Scheduled);

	return {rounds, nextUpcoming, upcoming};
})

export const getNearestPASEvent = createSelector(getAllEvents, (events) => {
	const PASLive = events.live.find(filterPASEvent);
	const PASSchedule = events.upcoming.find(filterPASEvent);

	return PASLive || PASSchedule;
})

import {NavigationHubStats} from 'components/HubStats/NavigationHubStats';
import {fetchHubStats} from 'modules/actions/hub_stats';
import {getHubFilter, getHubStats, getHubStatsFetched} from 'modules/selectors/hub_stats';
import React, {useEffect, useRef,} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';


const HubStatsWrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 76px;
  position: relative;
`;

interface IHubStats {
    is_stats_show: boolean;
    toggleShowStats: () => void;
    setStatsHeight: (height: number) => void;
}

export const HubStats: React.FC<IHubStats> = ({is_stats_show, toggleShowStats, setStatsHeight}) => {
    const dispatch = useDispatch();

    const hubStats = useSelector(getHubStats);
    const currentFilter = useSelector(getHubFilter);
    const is_hub_stats_fetched = useSelector(getHubStatsFetched);
    const hubStatsWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setStatsHeight(hubStatsWrapperRef?.current?.offsetHeight || 0);
    }, [hubStats, setStatsHeight, currentFilter]);

    useEffect(() => {
        if (is_stats_show && !is_hub_stats_fetched) {
            dispatch(fetchHubStats());
        }
    }, [dispatch, is_stats_show, is_hub_stats_fetched]);

    return (
        <HubStatsWrapper ref={hubStatsWrapperRef}>
            <NavigationHubStats/>
        </HubStatsWrapper>
    );
};

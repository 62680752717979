import {IRequestCode, ISagaAction} from 'modules/types';
import {
	requestVerifyCodeFailed,
	requestVerifyCodeSuccess,
	globalError,
	verifyCodeSuccess,
	verifyCodeFailed,
	postUserLoginSuccess,
} from 'modules/actions';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {Api, ApiError} from 'modules/utils/Api';
import {User} from 'modules/utils/User';

export const requestVerifyCodeSaga = function* ( { payload }: ISagaAction<IRequestCode> ): SagaIterator {
	try {
		const response = yield* call( Api.Auth.request_code, {
			...payload
		} )
		ApiError.CHECK( response );
		yield* put( requestVerifyCodeSuccess() );
	} catch (e: any) {
		const error = e.text || e.message || e;
		yield* put(globalError(e.text || e.message || e))

		yield* put(requestVerifyCodeFailed(error));
	}
};

export const verifyCodeSaga = function* ( { payload }: ISagaAction<string> ): SagaIterator {
	try {
		const response = yield* call( Api.Auth.verify_code, {
			code: payload
		} );

		const { user, session_id, is_first_time_checked_in, raw, hashed } = response.result;
		ApiError.CHECK( response );

		User.SET_ORIGINAL_COOKIES(raw, hashed);
		User.SAVE_TMP_USER( {
			...user,
			raw,
			hashed,
			isGuest: 1
		} );

		User.SAVE( session_id );
		yield* put( postUserLoginSuccess( {
			user,
			session_id,
			is_first_time_checked_in
		} ) );

		yield* put( verifyCodeSuccess() );

	} catch (e: any) {
		const error = e.text || e.message || e;
		yield* put(globalError(e.text || e.message || e))

		yield* put(verifyCodeFailed(error));
	}
};



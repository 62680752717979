import * as actions from 'modules/actions';
import {fetchAdSlotsJSON} from 'modules/actions/adSlots';
import {fetchLeaderboardSnippet} from 'modules/actions/ladder';
import {adSlotsSaga} from 'modules/sagas/adSlots';
import {fetchContestTimesSaga} from 'modules/sagas/contest_times';
import {fetchHubStatsSaga} from 'modules/sagas/hub_stats';
import {fetchPPGameJSONSSaga} from 'modules/sagas/jsons';
import {fetchLeaderboardSnippetSaga} from 'modules/sagas/ladder/inedex';
import {mgmCardsSaga} from 'modules/sagas/mgmCards';
import {fetchStatsSaga} from 'modules/sagas/stats';

import {
	ChecksumsToSaga,
	errorsManagerSaga,
	fetchBackDoorInfoSaga,
	fetchBackEndTimeSaga,
	fetchFooterSaga,
	fetchStoresSaga,
	postUserLoginBackDoor,
	postUserLoginSaga,
	postUserLogoutSaga,
	postUserUpdateSaga,
	reAuthorizeUserSaga,
	termsModalAcceptSaga,
	requestVerifyCodeSaga,
	verifyCodeSaga,
	fetchEventsSaga,
	fetchIsBettingAllowedSaga,
} from 'modules/sagas';

/**
 * Sagas from rood directory
 */
import {SagaIterator} from 'redux-saga';
import {all, takeLatest} from 'redux-saga/effects';

const sagas = [
	/**
	 * Auth
	 */
	takeLatest(actions.postUserLogin, postUserLoginSaga),
	takeLatest(actions.postUserLogout, postUserLogoutSaga),
	takeLatest(actions.userLoginBackDoor, postUserLoginBackDoor),
	takeLatest(actions.postReAuthorizeUser, reAuthorizeUserSaga),
	takeLatest(actions.postUserUpdate, postUserUpdateSaga),
	takeLatest(actions.termsModalAccept, termsModalAcceptSaga),
	takeLatest(actions.requestVerifyCode, requestVerifyCodeSaga),
	takeLatest(actions.verifyCode, verifyCodeSaga),
	takeLatest(actions.subscribeToLiveScores, ChecksumsToSaga),
	takeLatest(actions.fetchBackDoorInfo, fetchBackDoorInfoSaga),

	/**
	 * Static
	 */
	takeLatest(actions.fetchFooterJSON, fetchFooterSaga),
	takeLatest(actions.globalError, errorsManagerSaga),

	/**
	 * Utils
	 */
	takeLatest(actions.fetchBETime, fetchBackEndTimeSaga),


	takeLatest(actions.fetchIsBettingAllowed, fetchIsBettingAllowedSaga),
	takeLatest(actions.fetchHubStats, fetchHubStatsSaga),
	takeLatest(actions.fetchStats, fetchStatsSaga),
	takeLatest(fetchAdSlotsJSON, adSlotsSaga),
	takeLatest(actions.fetchMgmCardsJSON, mgmCardsSaga),
	takeLatest(fetchLeaderboardSnippet, fetchLeaderboardSnippetSaga),

	takeLatest(actions.fetchPPGameJSONS, fetchPPGameJSONSSaga),
	takeLatest(actions.fetchStoresJSON, fetchStoresSaga),

	takeLatest(actions.fetchContestTimes, fetchContestTimesSaga),
	takeLatest(actions.fetchEvents, fetchEventsSaga),
	takeLatest(actions.fetchIsBettingAllowed, fetchIsBettingAllowedSaga),
];


/**
 * Root saga that connect sagas with actions.
 */
export const rootSaga = function* (): SagaIterator {
	yield all(sagas);
};

export * from './live_score'
export * from './errors'
export * from './backdoor_info'
export * from './settings'
export * from './footer'
export * from './stores'
export * from './user'
export * from './guestUser'
export * from './dashboard'
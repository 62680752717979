import copy from 'copy-to-clipboard';
import {EnumRegion} from 'modules/utils/enums';
import moment from 'moment-timezone';
import qs from 'qs';
import {useEffect} from 'react';
import {noop} from 'lodash';


export const scrollSmooth = (ref: any, interval: any, time: number = 5000) => {
	return new Promise((res: any) => {
		clearInterval(interval);
		const target = ref.current;
		const TIMEOUT = 15;
		const step = TIMEOUT * 100 / time;

		if (target) {
			interval = setInterval(() => {
				target.scrollTop = Number(target.scrollTop) + step;

				if (target.scrollHeight === target.scrollTop) {
					clearInterval(interval);
					res();
				}

			}, TIMEOUT);
		}
	});

};

export const detectMob = () => {
	const toMatch = [
		/Android/i,
		/webOS/i,
		/iPhone/i,
		/iPad/i,
		/iPod/i,
		/BlackBerry/i,
		/Windows Phone/i
	];

	return toMatch.some((toMatchItem) => {
		return toMatchItem.exec(navigator.userAgent);
	});
}


export const useTimeOut = (callback: any, delay: number | null = null, call_at_start: boolean = false) => {

	if (call_at_start) {
		callback()
	}

	useEffect(() => {
		if (delay !== null) {

			const id = setTimeout(() => {
				callback();
			}, delay);

			return () => clearTimeout(id);
		}

		return noop;
	}, [delay, callback]);
};

export const useInterval = (callback: any, delay: number | null = null, call_at_start: boolean = false) => {

	if (call_at_start) {
		callback()
	}

	useEffect(() => {
		if (delay !== null) {

			const id = setInterval(() => {
				callback();
			}, delay);

			return () => clearInterval(id);
		}

		return noop;
	}, [delay, callback]);
};

export const preFetchVideo = () => {
	const video = document.createElement('video');
	video.src = 'https://uat-bww-play.fanhubmedia.com/trivia/media/BWW_BeerBattered_Tenders_Dark.mp4';
};

export const getAlphaBet = (index: number) => String.fromCharCode(65 + index);

export const toggleFixedBody = (state: boolean) => {
	if (!state) {
		document.body.classList.remove('fixed-scroll');
	} else {
		document.body.classList.add('fixed-scroll');
	}
};

/**
 * Parse the query string
 */
export const getParsedQS = (): any => {
	const {search} = window.location;
	return qs.parse(search, {
		ignoreQueryPrefix: true,
		decoder: e => decodeURIComponent(e)
	});
};

/**
 * Used to split the game on two parts for EAST US and WEST US timezones
 */
export const getRegionByLocalTime = () => {
	const tzid = moment.tz.guess();

	const timezone_offset = new Date().getTimezoneOffset();

	if (timezone_offset === 360) {
		return tzid === 'America/Denver' ? EnumRegion.West : EnumRegion.East;
	}
	return timezone_offset > 300 ? EnumRegion.West : EnumRegion.East;
};


const current_date = moment().format('YYYY-MM-DD');
const time_zone_east = moment().isDST() ? '04:00' : '05:00';
const time_zone_west = moment().isDST() ? '07:00' : '08:00';
const east_launch = moment(current_date + `T13:00:00-${time_zone_east}`);
const east_happy = moment(current_date + `T18:30:00-${time_zone_east}`);
const east_night = moment(current_date + `T20:30:00-${time_zone_east}`);

const west_launch = moment(current_date + `T12:00:00-${time_zone_west}`);
const west_happy = moment(current_date + `T17:30:00-${time_zone_west}`);
const west_night = moment(current_date + `T19:30:00-${time_zone_west}`);

export const TRIVIA_DEFAULT_STARTS = {
	east: {
		LAUNCH_TIME: east_launch,
		HAPPY_HOUR: east_happy,
		LATE_NIGHT: east_night
	},
	west: {
		LAUNCH_TIME: west_launch,
		HAPPY_HOUR: west_happy,
		LATE_NIGHT: west_night
	}
};

export const getDataForStaticContestsTimes = (regionTime: string) => {
	const date = moment(regionTime);
	return {
		hours: date.format('hh'),
		minutes: date.format('mm'),
		a: date.format('a'),
	}
};

// Disabled due we can't have two T in generics, but it says to have T, T
// eslint-disable-next-line @typescript-eslint/naming-convention
export const resizeToFit = <T extends HTMLDivElement, S extends HTMLSpanElement>(container: T, text: S) => {
	const font_size_float = window.getComputedStyle(text, null).getPropertyValue('font-size');
	const font_size = parseFloat(font_size_float);

	if (container.scrollHeight > container.clientHeight) {
		text.style.fontSize = `${font_size - 1}px`;
		resizeToFit(container, text);
	}
};

export const withSign = (value: number): string => {
	if (value > 0) {
		return `+${value}`;
	} else {
		return `${value}`;
	}
};


export const copyToClipboard = (text: string): boolean => {
	try {
		return copy(text);
	} catch (e: any) {
		return false
	}
};

export const scrollIntoView = (key: string) => {
	const element = document.getElementById(key);
	if (element) {
		try {
			element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
		} catch (e: any) {
			try {
				element.scrollIntoView();
			} catch (e: any) {
				console.log(e);
			}
		}
	}
}

export const convertColorHEXtoRGB = (color: string) => {
	if (color.substring(0, 1) === '#') {
		color = color.substring(1);
	}

	return [
		parseInt(color.substring(0, 2), 16),
		parseInt(color.substring(2, 4), 16),
		parseInt(color.substring(4), 16)
	];
}
import {IGlobalStore, IModalState} from 'modules/types';
import {createSelector} from 'reselect';

export const getModalsState = (state: IGlobalStore) => state.modals;

export const isTutorialModalOpen = createSelector(
	getModalsState,
	( { isEcoTutorialOpen }: IModalState ) => isEcoTutorialOpen
);


import { IconAllNavPlusSymbol } from 'components/Icons/IconAllNavPlusSymbol';
import { IconBetMGM } from 'components/Icons/IconBetMGM';
import { setFilter } from 'modules/actions/filters';
import { IFilters } from 'modules/reducers/filters';
import { getFilteredEventsPerGameSelector } from 'modules/selectors/dashboard';
import { getFilters } from 'modules/selectors/filters';
import { EnumFiltersPerGame } from 'modules/utils/enums';
import { partial } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { HOCisTrue } from 'components/HOCisTrue';
import {IS_AO_DISABLED, IS_PAS_SECRET_PASSED} from 'modules/utils';
import { IconAOLogo } from 'components/Icons';
import {ReactComponent as IconPASLogo} from 'assets/img/logos/PaSLogo.svg'


const BadgeStyled = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  background: #C3470E;
  color: #FFFFFF;
  font-family: Barlow, sans-serif;
  font-size: 8px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 8px;
  text-align: center;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

interface IPropsBadge {
	size: number
}

const Badge: React.FC<IPropsBadge> = ({size}) => {
	if (!size) {
		return null
	}
	return <BadgeStyled>{size}</BadgeStyled>
}


const Wrapper = styled.div`
  margin: 0 auto 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  max-width: 320px;
`;

const NavItem = styled.div`
  height: 100%;
  width: 100%;
  margin-right: 5px;
  border: 1px solid currentColor;
  border-radius: 15px;
  padding: 6px 14px;
  color: #362E2C;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }

  svg {
    height: 100%;
    width: auto;
  }

  &.active {
    ${BadgeStyled} {
      display: none;
    }
  }
`;

const NavItemTrivia = styled(NavItem)`
	&:not(.active) {
		.fill-dark-element {
			fill: #362E2C;
		}
	}

  &.active {
	background: #362E2C;
	border-color: #362E2C;

	.fill-element {
	  fill: #FFFFFF;
	}
  }

  img {
	width: 60px;
	height: auto;
  }
`;

const NavItemMGM = styled(NavItem)`
  &.active {
	background: #000000;
	color: #fff;
	border-color: #000000;
  }
`;

const NavItemAll = styled(NavItem)`
  padding: 7px;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  &.active {
	background: #362E2C;
	color: #FEC601;
	border-color: #362E2C;
  }
`;

export const NavigationPerGame = () => {
	const dispatch = useDispatch();
	const {games: filteredGames} = useSelector(getFilters);
	const eventsLiveSelector = useSelector(getFilteredEventsPerGameSelector);

	const mgmCards = eventsLiveSelector(EnumFiltersPerGame.MGM);

	const setFilterFn = (games: IFilters['games']) => {
		dispatch(setFilter({
			games
		}));
	};

	const activeClass = (game: string) => {
		return game === filteredGames ? 'active' : '';
	};

	return (
		<Wrapper>
			<NavItemAll
				className={activeClass(EnumFiltersPerGame.All)}
				onClick={partial(setFilterFn, EnumFiltersPerGame.All)}
			>
				<IconAllNavPlusSymbol/>
			</NavItemAll>

			<HOCisTrue when={!IS_AO_DISABLED}>
				<NavItemTrivia
					className={activeClass(EnumFiltersPerGame.AO)}
					onClick={partial(setFilterFn, EnumFiltersPerGame.AO)}>
					<IconAOLogo/>
				</NavItemTrivia>
			</HOCisTrue>

			<HOCisTrue when={IS_PAS_SECRET_PASSED}>
				<NavItemTrivia
					style={{paddingTop: "5px", paddingBottom: "5px"}}
					className={activeClass(EnumFiltersPerGame.PAS)}
					onClick={partial(setFilterFn, EnumFiltersPerGame.PAS)}>
					<IconPASLogo/>
				</NavItemTrivia>
			</HOCisTrue>


			<NavItemMGM
				className={activeClass(EnumFiltersPerGame.MGM)}
				onClick={partial(setFilterFn, EnumFiltersPerGame.MGM)}
			>
				<IconBetMGM/>
				<Badge size={mgmCards.length}/>
			</NavItemMGM>

		</Wrapper>
	)
}

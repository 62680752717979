import React, {useState} from 'react';
import styled from 'styled-components';
import {theme} from 'assets/css/theme';
import {IconTick, IconRevert} from 'components';
import {HOCBaseModal} from 'components/Modals';
import {useDispatch, useSelector} from 'react-redux';
import {getFilters} from 'modules/selectors/filters';
import {setFilter} from 'modules/actions/filters';
import {partial} from 'lodash';
import {TimesFilter} from 'components/Filters/TimesFilter';
import {GamesFilter} from 'components/Filters/GamesFilter';

const Title = styled.div`
	font-family: ${ theme.fonts.bold };
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 33px;
	color: #ffffff;
`;

const Buttons = styled.div`
	margin-top: auto;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Button = styled.button`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #E3E1DB;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 5px;
`;
const RevertButton = styled( Button )`
	background: #57504e;
	svg {
		transform: rotate(180deg);
	}
`;

const ChangeFilterButtons = styled.div`
	display: flex;
	margin-bottom: 30px;
`;

interface IFilterButton {
	isSelected: boolean;
}

const FilterButton = styled.div<IFilterButton>`
	width: 80px;
	height: 30px;
	color: ${ props => props.isSelected ? '#362E2C' : '#FEC600' };
	border: 1px solid #FEC600;
	margin-right: 10px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	background: ${ props => props.isSelected ? '#FEC600' : 'transparent' };
	justify-content: center;
	font-size: 12px;
`;

interface IProps {
	modalState: boolean;
	toggleModalState: () => void;
}

export const TimesAndGamesFilter : React.FC<IProps> = ( { modalState, toggleModalState }: IProps ) => {
	const dispatch = useDispatch();
	const { times, games } = useSelector( getFilters );
	const [filterType, changeFilterType] = useState( 'times' );

	const [timesOption, changeTimesOption] = useState( times );
	const [gamesOption, changeGamesOption] = useState( games );

	const closeFilter = () => {
		toggleModalState();
	}
	const approveFilter = () => {
		dispatch( setFilter( {
			times: timesOption,
			games: gamesOption,
		} ) );
		closeFilter();
	}

	const resetBack = () => {
		changeTimesOption( times );
		changeGamesOption( games );
		changeFilterType( 'times' );
		closeFilter();
	}

	return (
		<HOCBaseModal isShow={ modalState } show_close_icon={ true } onCloseClick={ closeFilter }>
			<Title>Filters</Title>
			<ChangeFilterButtons>
				<FilterButton isSelected={ filterType === 'times' }
							  onClick={ partial( changeFilterType, 'times' ) }>Times</FilterButton>
				<FilterButton isSelected={ filterType === 'games' }
							  onClick={ partial( changeFilterType, 'games' ) }>Games</FilterButton>
			</ChangeFilterButtons>
			{ filterType === 'times' ?
				<TimesFilter selectedOption={ timesOption } changeCallBack={ changeTimesOption }/> : null }
			{ filterType === 'games' ?
				<GamesFilter selectedOption={ gamesOption } changeCallBack={ changeGamesOption }/> : null }
			<Buttons>
				<RevertButton onClick={ resetBack }>
					<IconRevert width={ 16 } height={ 16 }/>
				</RevertButton>
				<Button onClick={ approveFilter }>
					<IconTick width={ 16 } height={ 16 } color={ '#1C1C1C' }/>
				</Button>
			</Buttons>
		</HOCBaseModal>
	)
}

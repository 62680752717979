import {SagaIterator} from 'redux-saga';
import {call, put, select, take} from 'redux-saga/effects';
import {fetchHubStatsFailed, fetchHubStatsSuccess, globalError, postUserLoginSuccess} from 'modules/actions';
import {PlayHubApi} from 'modules/utils';
import {ApiError} from 'modules/utils/Api';
import {isLoggedIn} from 'modules/selectors/user';

export const fetchHubStatsSaga = function* (): SagaIterator {
	const isUserLoggedIn = yield select(isLoggedIn);
	if (!isUserLoggedIn) {
		yield take(postUserLoginSuccess);
	}
	try {
		const response = yield call(PlayHubApi.Dashboard.stats_details);

		ApiError.CHECK(response);

		yield put(fetchHubStatsSuccess(response.result));

	} catch (e: any) {
		const error = e as Error;
		yield put(globalError(error))
		yield put(fetchHubStatsFailed(e));
	}
};

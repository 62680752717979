import infoIcon from 'assets/img/icons/icon-info.svg';
import menu_icon from 'assets/img/icons/icon-menu.svg';
import pinIcon from 'assets/img/icons/icon-pin-round.svg';
// import statsButtonIcon from 'assets/img/icons/icon-stats-button.svg';
import userIcon from 'assets/img/icons/icon-user.svg';
import {HOCisTrue} from 'components/HOCisTrue';
import {changeMenuState, setModalState} from 'modules/actions';
import {getUser} from 'modules/selectors/user';
import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// import { NavLink } from 'react-router-dom';
import styled, {css} from 'styled-components';

const HeaderNavWrapper = styled.div`
	background: ${({theme}) => theme.header?.main_background || '#362E2C center top no-repeat'};
	background-size: ${({theme}) => theme.header?.main_background_size || 'auto'};
	padding: 0 15px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: ${ ( { theme } ) => theme.header?.margin_bottom || '35px' };
	flex: 0 0 auto;
	z-index: 3;
`;

const TopHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	width: 100%;
	position: relative;
	z-index: 3;
`;

const LogoLeftImg = styled.img`
	width: 100px;
	cursor: pointer;
`;

const Left = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
`;
const Right = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

const bigAvatar = css`
	width: 60px;
	height: 60px;
	img {
		width: 40px;
		height: 40px;
	}
`;

const UserAvatar = styled.div<{ isBig?: boolean }>`
	height: 40px;
	width: 40px;
	border-radius: 50%;
	background: ${ ( { theme } ) => theme.header?.secondary_background || '#362E2C' };
	border: ${ ( { theme } ) => theme.header?.avatar_border || 'none' };
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 auto;
	${ props => props.isBig && bigAvatar };
	position: relative;
`;

const PinIcon = styled.img`
	position: absolute;
	right: 0;
	top: 0;
`

const Points = styled.div`
    p {
		color: #FFFFFF;
		font-size: 16px;
		font-weight: 900;
		letter-spacing: 0;
		line-height: 16px;
		text-align: center;
		
		&:last-child {
			font-size: 8px;
			line-height: 10px;
			font-weight: normal;
		}
    }
`

const UserIcon = styled.img`
	width: 20px;
	height: 20px;
`;

const InfoIconWrapper = styled.div`
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
`;
const InfoIcon = styled.img``;
const Info = styled.div`
	font-size: 10px;
	white-space: pre;
	display: none;
	color: #ffffff;
`;
const infoOpenStyles = css`
		padding-left: 5px;
		${ Info } {
			display: block;
		}
	`;
const InfoContainer = styled.div<{ isOpen: boolean }>`
	background: rgba(255,255,255, .1);
	margin-right: 5px;
	display: flex;
	align-items: center;
	border-radius: 30px;
	width: ${ props => props.isOpen ? 'auto' : '40px' };
	overflow: hidden;
	${ props => props.isOpen && infoOpenStyles }
`;

// const CTAWrapper = styled.div<{ isOpen: boolean }>`
// 	max-height: 50px;
// 	height: ${ props => props.isOpen ? '100%' : 0 };
// 	opacity:  ${ props => props.isOpen ? 1 : 0 };
// 	transition: all 1s linear;
// 	position: absolute;
//     bottom: -25px;
// 	overflow: hidden;
// 	width: 100%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// `
// const StatsLink = styled( NavLink )``;
// const ImgButton = styled.img``;

const HamburgerMenu = styled.img`
	top: 0;
	left: 0;
	padding: 20px;
	display: flex;
	cursor: pointer;
	margin-left: -20px;
`;

interface IProps {
	userProfile?: (data: {isOpen: boolean, toggleOpenState?: () => void}) => React.ReactComponentElement<any>;
	points?: number;
	defaultOpenUserStats?: boolean;
	logoImage?: string;
	tutorialType: string;
}

export const Header: React.FC<IProps> = (props) => {
	const {userProfile: UserProfile, points, defaultOpenUserStats, logoImage, tutorialType} = props;
	const dispatch = useDispatch();
	const {checked_in, bww_id} = useSelector(getUser);
	const [isOpen, changeIsOpen] = useState(!!defaultOpenUserStats);
	const [infoState, changeInfoState] = useState(false);
	const toggleInfoState = () => changeInfoState(!infoState);

	const toggleOpenState = () => {
		changeIsOpen(!isOpen)
	};

	const showTutorial = useCallback(() => {
		changeInfoState(false);
		dispatch(setModalState({
			key: tutorialType === 'eco' ? 'isEcoTutorialOpen' : 'isTriviaTutorialOpen',
			state: true
		}));
	}, [dispatch, changeInfoState, tutorialType]);


	const updateMenuStatus = () => {
		dispatch( changeMenuState( true ) );
	};

	return (
		<React.Fragment>
			<HeaderNavWrapper>
				<TopHeader>
					<Left>
						<HOCisTrue when={!!bww_id}>
							<HamburgerMenu alt={'Menu'} src={menu_icon} onClick={updateMenuStatus}/>
						</HOCisTrue>
						{logoImage ? <LogoLeftImg src={logoImage}/> : null}
					</Left>
					<HOCisTrue when={!!bww_id}>
						<Right>
							<InfoContainer isOpen={infoState} onClick={toggleInfoState}>
								<Info onClick={showTutorial}>Play the tutorial again</Info>
								<InfoIconWrapper>
									<InfoIcon role="presentation" src={infoIcon}/>
								</InfoIconWrapper>
							</InfoContainer>

							<UserAvatar onClick={toggleOpenState}>
								{points ? (
									<Points>
										<p>{points}</p>
										<p>pts</p>
									</Points>
								) : (
									<React.Fragment>
										<UserIcon role="presentation" src={userIcon}/>
										{checked_in ? <PinIcon src={pinIcon}/> : null}
									</React.Fragment>
								)}
							</UserAvatar>
						</Right>
					</HOCisTrue>
				</TopHeader>
				{UserProfile ? (
					<UserProfile isOpen={isOpen} toggleOpenState={toggleOpenState}/>
				) : null}
			</HeaderNavWrapper>
		</React.Fragment>
	)
};


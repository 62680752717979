import {IGlobalStore, IUserState} from 'modules/types';
import {createSelector} from 'reselect';


export const getUserState = ({ user }: IGlobalStore) => user;

export const getUser = createSelector(
	[getUserState],
	(state: IUserState) => state.user_obj
);

export const isLoggedIn = createSelector(
	[getUserState],
	state => state.is_logged_in
);

export const getUserID = createSelector(
	[getUser],
	(user: IUserState["user_obj"]) => user.id
);

export const getUserStoreID = createSelector(
	[getUser],
	(user: IUserState["user_obj"]) => user.store_id
);
import {fetchContestTimesSuccess} from 'modules/actions/contest_times';
import {createReducer} from 'redux-act';

export interface IContestTimes {
	lunchtime: IContestTime;
	happy_hour: IContestTime;
	late_night: IContestTime;
	team_night: IContestTime;
}

export interface IContestTime {
	east: IRegionTime;
	west: IRegionTime;
}

export interface IRegionTime {
	startDateTime: string;
}

const initialState: IContestTimes = {
	"lunchtime": {
		"east": {
			"startDateTime": "2020-09-16T20:33:00-04:00"
		},
		"west": {
			"startDateTime": "2020-09-17T20:55:31-07:00"
		}
	},
	"happy_hour": {
		"east": {
			"startDateTime": "2020-09-17T10:35:00-04:00"
		},
		"west": {
			"startDateTime": "2020-09-21T04:46:45-07:00"
		}
	},
	"late_night": {
		"east": {
			"startDateTime": "2020-10-28T20:30:00-04:00"
		},
		"west": {
			"startDateTime": "2020-11-12T19:30:00-08:00"
		}
	},
	"team_night": {
		"east": {
			"startDateTime": "2021-04-25T03:39:08-04:00"
		},
		"west": {
			"startDateTime": "2021-04-28T12:08:47-07:00"
		}
	}
}

export const contest_times = createReducer<IContestTimes>({}, initialState)
	.on(fetchContestTimesSuccess, (state, payload) => ( {
		...state,
		...payload
	} ))

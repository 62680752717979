import {IFilters} from 'modules/reducers/filters';
import {EnumFiltersPerGame} from 'modules/utils/enums';
import React from 'react';
import styled, {css} from 'styled-components';
import {partial} from 'lodash';
import {IconTick} from 'components/Icons/IconTick';

const borderStyled = ( withBorder: boolean = false ) => withBorder && css`
	border-top: 1px solid #312A28;
	border-bottom: 1px solid #312A28;
`;
const Checked = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const NotChecked = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #fff;
	opacity: .2;
`;

interface IOption {
	withBorder?: boolean;
	isChecked?: boolean;
}

const Option = styled.div<IOption>`
	font-size: 12px;
	line-height: 16px;
	padding: 10px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #ffffff;
	${ ( { withBorder = false } ) => borderStyled( withBorder ) };
`;

interface IProps {
	selectedOption: string;
	changeCallBack: ( option: IFilters['games'] ) => void;
}

export const GamesFilter = ( { selectedOption, changeCallBack }: IProps ) => {
	const getChecked = ( option: IFilters['games']  ) => selectedOption === option ? (
		<Checked>
			<IconTick width={ 10 } height={ 10 } color={ '#1C1C1C' }/>
		</Checked>
	) : <NotChecked/>;

	return (
		<React.Fragment>
			<Option onClick={ partial( changeCallBack, EnumFiltersPerGame.All ) } withBorder={ true }>
				All Games
				{ getChecked( EnumFiltersPerGame.All ) }
			</Option>
		</React.Fragment>
	);
}

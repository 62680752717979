import React, {useEffect} from 'react';
import styled from 'styled-components';
import {LiveIcon} from 'components/LiveIcon';
import {EventTimer} from 'components/EventCard';
import {useSelector} from 'react-redux';
import {getAOEvents} from 'modules/selectors';
import {SegmentStatus} from 'modules/utils';
import moment from 'moment';

import AssetGoldBg from 'assets/img/cards/AOCardTimerGold.svg';
import mascot from 'assets/img/landing/cards/triviaMascot.svg';

const EmptyIcon = styled(LiveIcon)`
  pointer-events: none;
  background: url(${mascot});
  background-size: cover;
  height: 60px;
  margin-bottom: 0;
  
  &:before {
	content: unset !important;
  }
`

const Wrapper = styled.div`
  width: 60px;
  height: 60px;
  background: url(${AssetGoldBg});
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-size: cover;

  * {
	color: #131313;
  }

  .timer-60 {
	width: 60px;
	height: 60px;
  }

  svg {
	width: 58px;
	height: 58px;
  }
`

interface IProps {
	onComplete: () => void
}

export const TimerToContest: React.FC<IProps> = ({onComplete}) => {
	const {rounds} = useSelector(getAOEvents);
	const nextSchedule = rounds.find(round => round?.status === SegmentStatus.Scheduled);
	const live = rounds.find(round => round?.status === SegmentStatus.Live);

	useEffect(() => {
		if (live) {
			onComplete();
		}
	}, [live, onComplete])

	if (!nextSchedule) {
		return <EmptyIcon/>
	}

	const date = moment(nextSchedule.start_date_time).toDate();

	return (
		<Wrapper>
			<EventTimer
				date={date}
				uniq={nextSchedule?.id}
				className="timer-60"
				wrapperBackground="transparent"
				onComplete={onComplete}/>
		</Wrapper>
	)
}

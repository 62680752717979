import {EnumStatsType} from 'components/HubStats/utils';
import {
	fetchLeaderboardSnippetPPFailed,
	fetchLeaderboardSnippetPPSuccess,
	fetchLeaderboardSnippetTriviaFailed,
	fetchLeaderboardSnippetTriviaSuccess,
} from 'modules/actions/ladder';
import {PlayHubApi, ApiError} from 'modules/utils/Api';
import {globalError} from 'modules/actions/errors';

import {IQueryParams, ISagaAction} from 'modules/types';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

const defaultWebOptions: IQueryParams = {
	limit: 25,
	offset: 0,
};

export const fetchLeaderboardSnippetSaga = function* (action: ISagaAction<EnumStatsType>): SagaIterator {
	const type = action.payload;
	try {
		const result = yield call(PlayHubApi.Ladder.snippet, {
			...defaultWebOptions,
			direction: 'ASC',
			type,
		});
		ApiError.CHECK(result);

		const fetchLeaderboardSnippetSuccess = {
			[ EnumStatsType.trivia ]: fetchLeaderboardSnippetTriviaSuccess,
			[ EnumStatsType.pp ]: fetchLeaderboardSnippetPPSuccess,
		}[ type ];

		yield put(fetchLeaderboardSnippetSuccess(result.result));
	} catch (e: any) {
		const fetchLeaderboardSnippetFailed = {
			[ EnumStatsType.trivia ]: fetchLeaderboardSnippetTriviaFailed,
			[ EnumStatsType.pp ]: fetchLeaderboardSnippetPPFailed,
		}[ type ];

		yield put(fetchLeaderboardSnippetFailed());
		const error = e as Error;
		yield put(globalError(error))
	}
};

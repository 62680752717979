import {setHubStatsFilter} from 'modules/actions/hub_stats';
import {getHubFilter} from 'modules/selectors/hub_stats';
import {EnumFiltersHubStats} from 'modules/utils/enums';
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';
import {partial} from 'lodash';


const Wrapper = styled.div`
    width: 100%;
    max-width: 320px;
	padding: 0 10px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    height: 30px;
    font-family: Barlow, sans-serif;   
`;

const NavItem = styled.div`
	border: 1px solid #FEC600;
	border-radius: 20px;
	height: 100%;
	color: #FEC600;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 12px;
	text-align: center;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
	transition: all 0.3s ease-in-out;
	cursor: pointer;

	&:last-child {
		margin-right: auto;
	}
	
	&.active {
		background-color: #FEC600;
		color: #362E2C;
		cursor: default;
	}
`;

const navItems = [
	{
		title : 'Overview',
		filter: EnumFiltersHubStats.All
	},
];

export const NavigationHubStats: React.FC = () => {
	const dispatch = useDispatch();

	const currentFilter = useSelector(getHubFilter);

	const dispatchFilter = (value: EnumFiltersHubStats) => {
		if ( value !== currentFilter ) {
			dispatch(setHubStatsFilter(value));
		}

	};

	return (
		<Wrapper>
			{navItems.map((nav, index) => {
				const className = nav.filter === currentFilter ? 'active' : '';
				return (
					<NavItem
						onClick={partial(dispatchFilter, nav.filter)}
						key={index}
						className={className}
					>
						{nav.title}
					</NavItem>
				);
			})}
		</Wrapper>
	);
};

import React from 'react';
import styled from 'styled-components';
import mascot from 'assets/img/landing/cards/triviaMascot.svg';
import bg from 'assets/img/landing/cards/prizeBG.jpg';

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 80px;
  height: 80px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background: url(${bg});
  background-size: cover;
  color: #FFFFFF;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  img {
	width: 32px;
	height: 40px;
  }
  
`;

export const AOPrizesCounter: React.FC = () => {
	return (
		<Wrapper>
			<img src={mascot} alt="BWW logo" />
		</Wrapper>
	);
}

import {fetchContestTimesFailed, fetchContestTimesSuccess} from 'modules/actions/contest_times';
import {PlayHubApi} from 'modules/utils/Api';
import {globalError} from 'modules/actions/errors';
import {call, put} from 'typed-redux-saga';

export const fetchContestTimesSaga = function* () {
	try {
		const result = yield* call(PlayHubApi.JSON.contests_timezones);
		yield put(fetchContestTimesSuccess(result));
	} catch (e) {
		const error = e as Error;
		yield put(globalError(error))
		yield put(fetchContestTimesFailed());
	}
}

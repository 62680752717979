import {IHUBStatsReducer} from 'modules/reducers/hub_stats';
import {EnumFiltersHubStats} from 'modules/utils/enums';
import {createAction} from 'redux-act';


export const fetchHubStats = createAction();
export const fetchHubStatsSuccess = createAction<IHUBStatsReducer["stats"]>();
export const fetchHubStatsFailed = createAction<Error>();

export const setHubStatsFilter = createAction<EnumFiltersHubStats>();

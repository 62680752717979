import arrow from 'assets/img/icons/arrow-right-gold.svg';
import {CheckboxStyled, HOCPopupWrapper} from 'components';
import {termsModalAccept} from 'modules/actions/user';
import {getUser} from 'modules/selectors/user';
import React, {Fragment, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

const Rules = styled.div`
	text-align: left;
	color: #ffffff;
	padding-bottom: 30px;
	h4 {
		font-size: 18px;
		font-family: 'Barlow-Bold', sans-serif;
		margin: 1em 0;
		text-align: center;
	}
	
	p {
		font-family: 'Barlow-Bold', sans-serif;
		font-size: 16px;
		line-height: 23px;
		letter-spacing: -0.5px;
		padding-bottom: 2em;
		max-width: 290px;
		text-align: center;
	}
	
	a {
		color: #DBBD74;
	}
`;

const ButtonWrapper = styled.div`
	height: 50px;
	margin-top: auto;
	margin-bottom: 1.5em;
	width: 100%;
`;

const RegularButtonCustom = styled.button`
	display: block;
	height: 50px;
	width: 100%;
	max-width: 340px;
	margin: 0 auto;
	box-shadow: 0 15px 15px 0 rgba(0,0,0,0.2);
	border: 2px solid #DBBD74;
	background: transparent url("${arrow}") 1em center no-repeat ;
	letter-spacing: 2.33px;
	font-size: 14px;
	font-weight: bold;
	font-family: 'Barlow-Bold', sans-serif;
	color: #ffffff;
	transition: all 0.3s ease;
	
	&:disabled {
		border: 1px solid #fff;
		cursor: not-allowed;
		opacity: 0.2;
	}
	
	&:active:not(disabled) {
		transform: scale(1.1) translateY(-50%);
	}
`;

export const HOCTermsChecking: React.FC = ({children}) => {
	const dispatch = useDispatch();
	const user = useSelector(getUser);
	const [visible, setVisible] = useState(!user?.terms);
	const scrollRef = useRef<HTMLDivElement>(null);
	const [form, setForm] = useState<Record<string, number>>({});
	const is_unlocked = form.terms;
	const location = useLocation();

	if ( location.pathname.includes('help') || !user.bww_id ) {
		return (
			<Fragment>
				{children}
			</Fragment>
		)
	}

	const scrollToButton = () => {
		const elem = scrollRef.current;
		if ( elem && elem.scrollTo ) {

			elem.scrollTo({
				top: elem.scrollHeight,
				left: 0,
				behavior: "smooth"
			})
		}
	}
	const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
		const value = e.currentTarget.checked;
		const name = e.currentTarget.name;

		setForm({
			...form,
			[ name ]: Number(value)
		});

		if ( is_unlocked ) {
			scrollToButton();
		}
	}

	const onSubmit = () => {
		dispatch(termsModalAccept({
			...form
		}));

		setVisible(false)
	}

	return (
		<Fragment>
			<HOCPopupWrapper
				visible={visible}
				bg_inner="#fff"
				with_title={true}
				with_logo={true}
				scrollRef={scrollRef}
			>
				<Rules>
					<h4>Terms & Conditions</h4>

					<CheckboxStyled
						name="terms"
						onChange={handleChange}
					>
						I understand that my information will be used as described in the Buffalo Wild Wings
						<a href="https://www.buffalowildwings.com/en/privacy-policy-terms/">
							{' Privacy Policy'}
						</a>
						. I understand that I am entering into a contest and agree to the
						<a href="/trivia-app/help/game-info">
							{' Official Rules'}
						</a>.
					</CheckboxStyled>
					<CheckboxStyled
						name="news"
						onChange={handleChange}
					>
						By providing my information, I’m opting-in to receive marketing communications from Buffalo Wild
						Wing’s email program in addition to communications about game operations and for prize
						verification purposes from Buffalo Wild Wings, Inc. and Genius Sports Group.
					</CheckboxStyled>

				</Rules>
				<ButtonWrapper>
					<RegularButtonCustom
						disabled={!is_unlocked}
						onClick={onSubmit}
					>
						CONTINUE
					</RegularButtonCustom>
				</ButtonWrapper>
			</HOCPopupWrapper>
			{children}
		</Fragment>
	)
}

import {createReducer} from 'redux-act';
import {
	requestVerifyCode,
	requestVerifyCodeFailed,
	requestVerifyCodeSuccess,
	verifyCode,
	verifyCodeFailed,
	verifyCodeSuccess,
} from 'modules/actions/guestUser';

export interface IGuestUserReducer {
	showLoader: boolean;
	showCodeVerify: boolean;
	error: string;
	isGuestVerified: boolean;
}

const initialState: IGuestUserReducer = {
	showLoader: false,
	showCodeVerify: false,
	error: '',
	isGuestVerified: false
};

export const guestUser = createReducer( {}, initialState );

guestUser.on( requestVerifyCode, ( state ) => ({
	...state,
	showLoader: true,
}) );
guestUser.on( requestVerifyCodeSuccess, ( state ) => ({
	...state,
	showLoader: false,
	showCodeVerify: true,
}) );
guestUser.on( requestVerifyCodeFailed, ( state, payload: string ) => ({
	...state,
	showLoader: false,
	error: payload
}) );
guestUser.on( verifyCode, ( state ) => ({
	...state,
	showLoader: true,
}) );
guestUser.on( verifyCodeSuccess, ( state ) => ({
	...state,
	showLoader: false,
	isGuestVerified: true,
}) );
// eslint-disable-next-line sonarjs/no-identical-functions
guestUser.on( verifyCodeFailed, ( state, payload: string ) => ({
	...state,
	showLoader: false,
	error: payload
}) );

import {IModelUser} from 'modules/types';

export const DEFAULT_USER: IModelUser = {
	id: 0,
	email: '',
	first_name: '',
	last_name: '',
	postcode: '',
	state: '',
	checked_in: 0,
	bww_id: '',
	store_id: '',
	store_zip: '',
	store_state: '',
	active: 0,
	recovered: 0,
	playing_games: [],
	disable_notifications: 0,
	terms: 0,
	check_in_rounds: [],
	staff: false,
	region_leaderboard: false,
	state_leaderboard: false,
	betting_state: '',
	is_legal_betting_state: false,
	daily_tutorial: 1,
	anchor_tutorial: 1,
	eco_tutorial: 0,
	store_name: '',
	is_guest: false,
	legal_betting_state_abbreviation: '',
	app_uid: '',
	latitude: 0,
	longitude: 0,
	mobile: '',
	news: 0,
	device_type: 'web',
	played: []
};

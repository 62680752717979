export const NAV_LINKS: { [key: string]: any } = {
	BWW_PLAY_HUB: {
		eco: {
			path: '/',
			isExternal: false
		},
		picks: {
			path: `${ String(process.env.REACT_APP_HUB_URL) }/picksandprops/`,
			isExternal: true
		},
		picksLeaderboard: {
			path: `${ String(process.env.REACT_APP_HUB_URL) }/picksandprops/leaderboard`,
			isExternal: true
		},
		picksPrizes: {
			path: `${ String(process.env.REACT_APP_HUB_URL) }/picksandprops/help/prizes`,
			isExternal: true
		},
		picksInfo: {
			path: `${ String(process.env.REACT_APP_HUB_URL) }/picksandprops/help/game-info`,
			isExternal: true
		},
		pas: {
			path: `${ String(process.env.REACT_APP_HUB_URL) }/pick-a-side`,
			isExternal: true
		},
		pasPrizes: {
			path: `${ String(process.env.REACT_APP_HUB_URL) }/pick-a-side/help/prizes`,
			isExternal: true
		},
		pasInfo: {
			path: `${ String(process.env.REACT_APP_HUB_URL) }/pick-a-side/help`,
			isExternal: true
		},
		trivia: {
			path: `${ String(process.env.REACT_APP_HUB_URL) }/always-on-trivia/`,
			isExternal: true
		},
		triviaLeaderboard: {
			path: `${String(process.env.REACT_APP_HUB_URL)}/always-on-trivia/leaderboard`,
			isExternal: true
		},
		triviaPrizes: {
			path: `${ String(process.env.REACT_APP_HUB_URL) }/always-on-trivia/help/prizes`,
			isExternal: true
		},
		triviaInfo: {
			path: `${ String(process.env.REACT_APP_HUB_URL) }/always-on-trivia/help/game-info`,
			isExternal: true
		},
		privacy: {
			path: '/help/privacy-policy',
			isExternal: false
		},
		account: {
			path: '/help/email-settings',
			isExternal: false
		}
	},
	BWW_TRIVIA: {
		eco: {
			path: process.env.REACT_APP_HUB_URL,
			isExternal: true
		},
		picks: {
			path: `${ String(process.env.REACT_APP_HUB_URL) }/picksandprops/`,
			isExternal: true
		},
		picksLeaderboard: {
			path: `${ String(process.env.REACT_APP_HUB_URL) }/picksandprops/leaderboard`,
			isExternal: true
		},
		picksPrizes: {
			path: `${ String(process.env.REACT_APP_HUB_URL) }/picksandprops/help/prizes`,
			isExternal: true
		},
		picksInfo: {
			path: `${ String(process.env.REACT_APP_HUB_URL) }/picksandprops/help/game-info`,
			isExternal: true
		},
		trivia: {
			path: `/`,
			isExternal: false
		},
		triviaLeaderboard: {
			path: `/leaderboard/daily`,
			isExternal: false
		},
		triviaPrizes: {
			path: `/help/prizes`,
			isExternal: false
		},
		triviaInfo: {
			path: `/help/game-info`,
			isExternal: false
		},
		privacy: {
			path: '/help/privacy-policy',
			isExternal: false
		},
		account: {
			path: '/help/email-settings',
			isExternal: false
		}
	},
	BWW_TRIVIA_WEB: {
		eco: {
			path: process.env.REACT_APP_HUB_URL,
			isExternal: true
		},
	}
}

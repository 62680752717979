import React, {useCallback, useState} from 'react';
import {HOCBaseModal} from 'components';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import bg from 'assets/img/modals/tutorialBackground.png';
import Swipe from 'react-easy-swipe';
import {partial, get} from 'lodash';
import {postUserUpdate, setModalState} from 'modules/actions';
import {isTutorialModalOpen} from 'modules/selectors';
import {getUser} from 'modules/selectors/user';
import tutorial_play from 'assets/img/tutorial/play.svg';
import tutorial_leaderboard from 'assets/img/tutorial/leaderboard.svg';
import tutorial_missions from 'assets/img/tutorial/missions.svg';


const Background = styled.div`
	background: url(${ bg }) center top no-repeat #362E2C;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;
const Container = styled( Swipe )`
	position: relative;
	z-index: 2;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;
const TutorialContent = styled.div`
	width: 90%;
	margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
`;
const TutorialImg = styled.img`
	margin: 0 auto 20px;
	display: block;
`;
const Title = styled.div`
	font-family: ${ ( { theme } ) => theme.fonts.bold };
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	margin-bottom: 20px;
`;
const Text = styled.div`
	text-align: center;
	font-size: 12px;
	line-height: 18px;
	padding: 0 15px;
	
	p {
		margin-bottom: 20px;
	}
`;
const Dots = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;
	margin-top: 12px;
`;
const Dot = styled.div<{ isSelected: boolean }>`
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: ${ props => props.isSelected ? '#FEC600' : '#fff' };
	margin: 0 7px;
`;
const ContinueSection = styled.div`
	border-top: 1px solid #fff;
	font-family: ${ ( { theme } ) => theme.fonts.semiBold };
	font-size: 14px;
	padding-top: 20px;
	color: #fff;
	text-align: center;
	margin-bottom: 30px;
`;

interface IStepContent {
	title: string;
	text: string[];
	img: string;
}

const stepsContent: Record<number, IStepContent> = {
	1: {
		title: 'Ready to Play?',
		text: [
			`You've come to the right place to earn points and win prizes. Test your skills with daily trivia games and free-to-play sports betting right here in the Buffalo Wild Wings app`,
		],
		img: tutorial_play,
	},
	2: {
		title: 'Climb the leaderboards',
		text: [
			`Tap the PLAY icon at the bottom of the app each day to enter upcoming contests and give yourself a chance to win big.`,
			`Each day offers a new opportunity to win prizes from fountain beverages to free wings for a month! The more you play, the better your chances.`,
		],
		img: tutorial_leaderboard,
	},
	3: {
		title: 'Stats and Milestones',
		text:  [
			`After you make your picks, track your stats and performance across all Buffalo Wild Wings contests right here in the app.`,
			`Check stats on recent games played and see what you won. Pretty soon, you'll be racking up free wings and other great prizes. It's time to PLAY.`
		],
		img: tutorial_missions,
	},
};

export const TutorialModal : React.FC = () => {
	const dispatch = useDispatch();
	const isOpen = useSelector( isTutorialModalOpen );
	const user = useSelector( getUser );
	const [step, changeStep] = useState( 1 );

	const isStepSelected = ( stepNum: number ) => stepNum === step;
	const changeStepHandler = ( stepNum: number ) => {

		if ( stepNum > 3 ) {
			completeTutorial();
			return;
		}
		if ( stepNum && (stepNum >= 1) ) {
			changeStep( stepNum )
		}
	}

	const completeTutorial = useCallback( () => {
		changeStep( 1 );
		if ( user.eco_tutorial === 0 ) {
			dispatch( postUserUpdate( {
				user: {
					...user,
					eco_tutorial: 1
				}
			} ) );
		}
		dispatch( setModalState( {
			key: 'isEcoTutorialOpen',
			state: false
		} ) )
	}, [dispatch, user] );

	return (
		<HOCBaseModal isShow={ Boolean( isOpen ) } show_close_icon={ true } height={ '100%' }
					  onCloseClick={ completeTutorial }>
			<Background/>
			<Container
				onSwipeLeft={ partial( changeStepHandler, step + 1 ) }
				onSwipeRight={ partial( changeStepHandler, step - 1 ) }
			>
				<TutorialContent>
					<TutorialImg src={get( stepsContent, `${ step }.img`, '' )} />
					<Title>
						{ get( stepsContent, `${ step }.title`, '' ) }
					</Title>
					<Text>
						{ get( stepsContent, `${ step }.text`, [] ).map((item: IStepContent, index: number) => (
							<p key={index}>{ item }</p>
						))}
					</Text>
				</TutorialContent>
				<Dots>
					<Dot isSelected={ isStepSelected( 1 ) }/>
					<Dot isSelected={ isStepSelected( 2 ) }/>
					<Dot isSelected={ isStepSelected( 3 ) }/>
				</Dots>
				<ContinueSection onClick={ partial( changeStepHandler, step + 1 ) }>
					CONTINUE
				</ContinueSection>
			</Container>
		</HOCBaseModal>
	);
}

import * as actions from 'modules/actions';
import {createReducer} from 'redux-act';


export interface IHelpItem {
	title: string;
	text: string;
}

export interface IHelp {
	faq: IHelpItem[];
	terms: IHelpItem[];
	guideline: IHelpItem[],
}

const defaultState = {
	faq: [],
	terms: [],
	guideline: [],
};

export const helpes = createReducer<IHelp>({}, defaultState)
	.on(actions.fetchFaqSuccess, (state, result) => ({
		...state,
		faq: result
	}))
	.on(actions.fetchTermsSuccess, (state, result) => ({
		...state,
		terms: result,
	}))
	.on(actions.fetchGuidelineSuccess, (state, result) => ({
		...state,
		guideline: result,
	}));

import styled from 'styled-components';
import bg from 'assets/img/cards/trivia.png';
import bgSmall from 'assets/img/cards/triviaSmall.png';

interface IEventCardPPHeader {
	isSmall?: boolean;
}

export const EventCardTriviaHeader = styled.div<IEventCardPPHeader>`
	min-height: ${ props => props.isSmall ? '103px' : '215px' };
	background: url(${ props => props.isSmall ? bgSmall : bg }) center bottom no-repeat transparent;
	background-size: cover;
	padding: 45px 10px 20px;
	box-sizing: border-box;
	position:relative;
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
`;
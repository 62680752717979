import {IconCross} from 'components';
import {toggleFixedBody} from 'modules/utils/dom';
import React, {useEffect} from 'react';
import styled, {css} from 'styled-components';


interface IModalContent {
	readonly isShow?: boolean;
	readonly height?: string;
	readonly padding?: string;
	readonly styl?: any;
}

const ModalWrapper = styled.div<IModalContent>`
	position: fixed;
	display: flex;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 0;
	height: 0;
	z-index: 10;
	background: rgba(0,0,0,0.5);
	justify-content: center;
	align-items: flex-end;
	overflow: hidden;
	visibility: hidden;
	
	${props => props.isShow && css`
		height: 100%;
		opacity: 1;
		visibility: visible;
	`} 
`;


const ModalContent = styled.div<IModalContent>`
	width: 100%;	
	height: ${props => props.isShow ? props.height : 0};
	transition: height .3s ease;
	background-color: #362E2C;
	position: relative;
	padding: ${props => props.isShow ? props.padding : 0};
	border-radius: ${props => props.height === '100%' ? 0 : '10px 10px 0 0'};
	display: flex;
	flex-direction: column;
	${props => props.styl ? props.styl : ''}
`;

const Close = styled.button<{styl: any}>`
	position: absolute;
	top: 10px;
	right: 10px;
	border-radius: 50%;
	background: #362E2C;
	border: none;
	width: 40px;
	height: 40px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	${props => props.styl ? props.styl : ''}
`;

interface IProps {
	onCloseClick?: () => void;
	children?: React.ReactNode;
	show_close_icon?: boolean;
	isShow: boolean;
	height?: string;
	padding?: string;
	styl?: any
	closeIconStyl?: any
}

export const HOCBaseModal: React.FC<IProps> = (props) => {
	const {
		styl,
		closeIconStyl,
		onCloseClick,
		children,
		show_close_icon = true,
		isShow,
		height = '80%',
		padding = '40px 30px 20px'
	} = props;

	useEffect(() => {
		toggleFixedBody(isShow);
	}, [isShow]);

	return (
		<ModalWrapper isShow={isShow}>
			<ModalContent styl={styl} isShow={isShow} height={height} padding={padding}>
				{show_close_icon && (
					<Close onClick={onCloseClick} styl={closeIconStyl}>
						<IconCross/>
					</Close>
				)}
				{children}
			</ModalContent>
		</ModalWrapper>
	)
};

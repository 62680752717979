import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {PlayHubApi} from 'modules/utils';

const Title = styled.div`
	font-family: ${({theme}) => theme.fonts.bold};
	font-size: 16px;
	margin-bottom: 10px;
`;
const ContentWrapper = styled.div``;

export const TermsTabContent = () => {
	const [terms, setTerms] = useState([{
		title: "",
		content: ""
	}]);

	const fetchTerms = async () => {
		const response = await PlayHubApi.JSON.terms_pp();
		if (response) {
			setTerms(response);
		}
	}

	useEffect(() => {
		void fetchTerms();
	}, [])

	return (
		<React.Fragment>
			{terms?.map((item) => (
				<React.Fragment key={item.title}>
					<Title>{item.title}</Title>
					{/* eslint-disable-next-line react/no-danger */}
					<ContentWrapper dangerouslySetInnerHTML={{__html: item.content}} />
				</React.Fragment>
			))}
		</React.Fragment>
	);
}

import {setRegion} from 'modules/actions/time';
import {isRegionConfigured} from 'modules/selectors/settings';
import {getUserStoreObject} from 'modules/selectors/stores';
import {getRegionByLocalTime} from 'modules/utils/dom';
import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';


interface IProps {
	children: any
}

export const HOCRegion: React.FC<IProps> = ({ children }) => {
	const dispatch = useDispatch();
	const user_store_obj = useSelector(getUserStoreObject);
	const is_configured = useSelector(isRegionConfigured);

	const region = user_store_obj?.region || getRegionByLocalTime();

	useEffect(() => {
		if ( region ) {
			dispatch(setRegion(region))
		}
	}, [region, dispatch, user_store_obj]);

	return is_configured ? children : null
};

import React from 'react';
import styled from 'styled-components';

const SubTitle = styled.div`
	font-family: ${({theme}) => theme.fonts.bold};
	font-size: 16px;
	margin-bottom: 10px;
`;

const List = styled.ul`
	list-style-type: disc;
	margin-left: 32px;
	margin-bottom: 10px;
`;
/**
 * max-func-body-length, no-big-function are disabled because form items can't be easily split into small chunks,
 * and this will make code more harder to support
 *
 * react-a11y-anchors - disabled because this is clients wish to use such hrefs
 */
// tslint:disable-next-line:max-func-body-length no-big-function react-a11y-anchors
export const RulesTabContent = () => {
	return (
		<React.Fragment>
			<SubTitle>1. Registering</SubTitle>
			<List>
				<li>In order to play the Picks and Props game, you must be registered to the Blazin’ Rewards
					app.
				</li>
				<li>Terms and conditions must be accepted before participation is allowed</li>
			</List>
			<SubTitle>2. Predicting and Scoring</SubTitle>
			<List>
				<li>Every day there will be a contest available for users to participate in with a chance of
					winning amazing prizes
				</li>
				<li>
					There are 2 different types of contests:
					<List>
						<li>Daily - 3 picks</li>
						<li>Picks & Props XL - Flexible amount of picks able to be made to build your custom
							contest and parlay (minimum of 2)
						</li>
					</List>
				</li>
				<li>
					Every contest will consist of picks against the spread and a number of game specific prop
					questions across multiple sports and competitions.
				</li>
				<li>
					Daily contest types will have a full lockout meaning that once the first game of the contest
					starts, no more changes can be made to your picks.
				</li>
				<li>
					Picks & Props XL contests utilize rolling lockout meaning that you can enter your picks at
					any point up until the beginning of each game. If a game has already started or is complete
					in a contest, you can still enter picks for any games that are still scheduled
				</li>
				<li>
					Potential points for a contest are calculated by the potential parlay returns of combining
					the individual odds of the users picks based off a $10 stake.
				</li>
				<li>
					You must score a perfect contest (all picks correct) in order to receive points. No points
					will be awarded for contests with any incorrect picks, even if it’s just one wrong pick.
				</li>
				<li>
					If you forget to make a pick, no points will be awarded
				</li>
			</List>
			<SubTitle>3. Results</SubTitle>
			<List>
				<li>Results are released at the completion of each game</li>
				<li>Overall rankings and leaders are recalculated after each game.</li>
				<li>Please allow for flexibility around these times if technical issues to arise, at which point
					all users will be notified
				</li>
			</List>
			<SubTitle>4. Rankings</SubTitle>
			<List>
				<li>The leaderboard will run on a month by month basis</li>
				<li>In order to qualify for the monthly leaderboard you must score a perfect contest and the
					contest must have a minimum of 2 picks
				</li>
				<li>You can only have one entry on the monthly leaderboard. If you manage to score multiple
					perfect contests in a month then the contest with the highest returned points in that month
					will qualify
				</li>
				<li>
					All users who score perfect contests will be displayed on the leaderboard but only users who
					have either checked in to a Buffalo Wild Wings store or made an eComm purchase will be
					eligible for prizing
				</li>
				<li>
					In the event of a tie, the user who is deemed the higher rank will be determined by the
					number of perfect contests they’ve achieved in the month
				</li>
				<li>
					Second tiebreaker will be date/time of entry with the earliest entry being ranked higher
				</li>
			</List>
			<SubTitle>5. Check In</SubTitle>
			<List>
				<li>
					To be eligible to win monthly prizes, you must check in to a Buffalo Wild Wings store or
					make an eComm purchase from a Buffalo Wild Wings store at least once during the month
				</li>
				<li>
					You can check in at a store through the homepage of the Blazin’ Rewards app.
				</li>
			</List>
			<SubTitle>6. Prizes</SubTitle>
			<List>
				<li>
					<SubTitle>Daily Prizes:</SubTitle>
					<p>
						100 Blazin’ Rewards Points - Players that answer each daily contest 100 percent correctly will
						receive 100 Blazin’ Rewards Points for use towards in-store purchases at participating BWW
						restaurant locations. Blazin Rewards Points are governed by Blazin Rewards terms found here –
						<a href="https://www.blazinrewards.com/Common/Home/AboutTheProgram">https://www.blazinrewards.com/Common/Home/AboutTheProgram</a> and
						will be applied directly to the
						winner’s account, upon verification by Sponsor within 48 hours.
					</p>
					<br/>
				</li>
				<li>
					<SubTitle>Monthly Prizes:</SubTitle>
					<p>Overall Winner (1 winner) - [Check-in by end of Promotion Period for eligibility] is the Player
						at the top of the overall leaderboard at the end of the Promotion as determined in accordance
						with the game play rules.
						Overall Winner prize is a Picks and Props Trophy, A championship ring, and a $100 Buffalo Wild
						Wings electronic gift card (ARV: $599).
						Top Additional Players on Monthly Leaderboard: Blazin’ Rewards Points.
						Players that achieve the following contest status through game play at the end of the Promotion
						Month (and are not eligible for another monthly contest prize) will receive the stated number of
						Blazin’ Rewards Points for use towards in-store purchases at participating BWW restaurant
						locations. The points will be automatically added to the winning Players’ accounts within 48
						hours of the end of the Promotion Month. Blazin’ Rewards Points are governed by Blazin’ Rewards
						terms found here - <a
							href="https://www.blazinrewards.com/Common/Home/AboutTheProgram">https://www.blazinrewards.com/Common/Home/AboutTheProgram</a> and
						will be applied
						directly to the winner’s account, upon verification by Sponsor.</p>

					<br/>
					<p>Top 2-25 Players</p>
					<p> 1,000 Blazin’ Rewards Points</p>
					<br/>
					<p>Top 26-100 Players</p>
					<p>500 Blazin’ Rewards Points</p>
				</li>
			</List>
			<SubTitle>7. Manage Account Settings</SubTitle>
			<List>
				<li>To update your account settings, please visit the Account section of the Blazin’ Rewards
					App
				</li>
			</List>
		</React.Fragment>
	);
}

import {postUserLogout} from 'modules/actions/user';
import {User} from 'modules/utils/User';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

export const Logout: React.FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(postUserLogout())
		User.CLEAR();
	}, [dispatch])

	return (
		<div>
			Logout
		</div>
	)
}
import {fetchPPGameJSONS} from 'modules/actions/game';
import {isPPJsonsFetched} from 'modules/selectors/jsons';
import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';


interface IProps {
	fallback: React.ReactNode
}

export const HOCPPFetchJSONS: React.FC<IProps> = ({children, fallback}) => {

	const dispatch = useDispatch();
	const is_fetched = useSelector(isPPJsonsFetched);

	useEffect(() => {
		dispatch(fetchPPGameJSONS());
	}, [dispatch]);

	return (
		<Fragment>
			{is_fetched ? children : fallback || null}
		</Fragment>
	);
};

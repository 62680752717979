import logo from 'assets/img/logos/triviaLogo.svg';
import {EventCardLogo} from 'components/EventCard/EventCardLogo';
import {EventCardName} from 'components/EventCard/EventCardName';
import {EventCardTriviaHeader} from 'components/EventCard/EventCardTriviaHeader';
import {EventCardWrapper} from 'components/EventCard/EventCardWrapper';
import {EventDate} from 'components/EventCard/EventDate';
import {EventTimer} from 'components/EventCard/EventTimer';
import {IEvent} from 'modules/reducers/dashboard';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';


const EventCardWrapperStyled = styled( EventCardWrapper )`
	${ EventCardName } {
		color: #1F3E56;
	}
	${ EventDate } {
		color: #1F3E56;
		margin-bottom: 40px;
	}
	.event-timer {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin-bottom: 0;
	}
`;

const EventCardHeaderWrapper = styled.div`
	position:relative;
`;

interface IProps {
	event: IEvent
}

export const EventCardUpcomingTrivia: React.FC<IProps> = ( { event } ) => {
	const start = moment( event.start )
		.format( 'hh:mma, ddd, MMM DD YYYY' );
	return (
		<EventCardWrapperStyled>
			<EventCardHeaderWrapper>
				<EventCardTriviaHeader isSmall={ true }>
					<EventCardLogo src={ logo } alt={ 'Trivia' }/>
				</EventCardTriviaHeader>
				<EventTimer className={ 'event-timer' } date={ event.start } uniq={ event.id } wrapperBackground={ '#1F3E56' }/>
			</EventCardHeaderWrapper>

			<EventCardName>
				{ event.name }
			</EventCardName>
			<EventDate>{ start }</EventDate>

		</EventCardWrapperStyled>
	)
};

import {createReducer} from 'redux-act';
import {fetchBackDoorInfoSuccess} from 'modules/actions';

interface IStore {
	store_id: number,
	name: string
}
export interface IBackDoorInfo {
	emails: string[]
	common_stores: IStore[]
}

const defaultState: IBackDoorInfo = {
	emails: [],
	common_stores: []
};

export const backdoor_info = createReducer<typeof defaultState>({}, defaultState)
	.on(fetchBackDoorInfoSuccess, (_state, payload) => payload)
;

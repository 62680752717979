import styled from 'styled-components';
import {theme} from 'assets/css/theme';

export const ProfileSection = styled.div<{ isOpen: boolean }>`
	height: ${props => props.isOpen ? '160px' : 0};
	transition: height .5s linear;
	overflow: hidden;
`;

export const Profile = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #ffffff;
`;
export const TeamName = styled.div`
	font-size: 16px;
	line-height: 16px;
	margin: 5px 0;
`;
export const Location = styled.div`
	font-size: 12px;
	line-height: 12px;
	display: flex;
    align-items: center;
    justify-content: center;
`;
export const Pin = styled.img`
	width: 20px;
	margin: 0 5px;
`;
export const Stats = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 16px;
`;
export const StatItem = styled.div`
	width: 70px;
	height: 70px;
	background: #362e2c;
	color: #E3E1DB;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 27px;
	margin: 0 2px;
	text-align: center;
`;
export const StatTitle = styled.div`
	font-size: 8px;
	line-height: 10px;
	letter-spacing: 1px;
	min-height: 10px;
	margin-bottom: 3px;
`;
export const StatValue = styled.div`
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 5px;
	font-family: ${ theme.fonts.bold };
	min-height: 14px;
`;

export const StatSubTitle = styled.div`
	font-size: 10px;
	line-height: 10px;
	opacity: .5;
`;

export const StatIcon = styled.button<{ color?: string }>`
	margin: 0;
	padding: 0;
	border: 0;
	position: absolute;
	left: 50%;
	bottom: -20px;
	transform: translateX(-50%);
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: ${(props) => props.color || '#362E2C'};
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	cursor: pointer;
`;

export const StatIconImg = styled.img`
	width: 12px;
`;

import {fetchMgmCardsJSONFailed, fetchMgmCardsJSONSuccess} from 'modules/actions/mgmCards';
import {createReducer} from 'redux-act';

export interface IMgmCard {
	american_odds: number;
	market_copy: string;
	market_name: string;
	match_info: string;
	odds: string;
	option_id: number;
}

export interface IMgmCardReducerState {
	cards: IMgmCard[],
	isFetched: boolean
}

const initialState: IMgmCardReducerState = {
	cards: [],
	isFetched: false,
};

export const mgmCards = createReducer<IMgmCardReducerState>( {}, initialState );

mgmCards.on(fetchMgmCardsJSONSuccess, (state, payload) => ( {
	...state,
	cards: payload,
	isFetched: true
} ));
mgmCards.on(fetchMgmCardsJSONFailed, () => ( initialState ));

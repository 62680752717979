export enum EnumTriviaContestType {
	lunchtime = 'lunchtime',
	happy_hour = 'happy_hour',
	late_night = 'late_night',
	team_night = 'team_night',
}

export enum EnumRegion {
	East = 'east',
	West = 'west',
}

export enum EnumEventGame {
	PicksAndProps = 'picksandprops',
	Trivia = 'trivia',
	AO = 'trivia_always_on'
}

export enum EnumScreenState {
	Active = 'active',
	Prev = 'prev',
	Next = 'next'
}

export enum EnumEventsPayloadSort {
	ASC = 'asc',
	DESC = 'desc'
}

export enum EnumEventsPayloadTimes {
	All = 'all',
	Active = 'active',
	Scheduled = 'scheduled',
}

export enum EnumEventsPayloadGames {
	PP = 'pp',
	Trivia = 'trivia'
}

export enum EnumEventStatus {
	Scheduled = 'scheduled',
	Active = 'active',
	Complete = 'complete',
	Live = 'live',
	Open = 'open'
}

export enum EnumEventType {
	Daily = 'daily',
	Anchor = 'anchor',
	TriviaHerd = 'herd',
}

export enum EnumFiltersPerGame {
	PicksAndProps = 'picksandprops',
	Trivia = 'trivia',
	All = 'all',
	MGM = 'Mgm',
	AO = 'trivia_always_on',
	PAS = 'pick_a_side'
}

export enum EnumFiltersHubStats {
	PicksAndProps = 'picksandprops',
	Trivia = 'trivia',
	All = 'all',
}

export enum EnumTimesOfGame {
	All = 'all',
	Live = 'live',
	Upcoming = 'upcoming'
}

export enum SegmentStatus {
	Scheduled = "scheduled",
	Live = "live",
	Complete = "complete",
	Voided = "voided",
}

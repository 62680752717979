import {globalError, setBETimeDiff} from 'modules/actions';
import {ApiError, TriviaApi} from 'modules/utils';
import {call, put} from 'typed-redux-saga';

export const fetchBackEndTimeSaga = function* () {
	try {
		const response = yield* call(TriviaApi.Utils.time);
		ApiError.CHECK(response);
		const BE_TIME = new Date(response.result.server_time).getTime();

		yield put(setBETimeDiff(BE_TIME - Date.now()));
	} catch (e: any) {
		const error = e as Error;
		yield put(globalError(error))
	}
};

import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import * as actions from 'modules/actions';
import {Api} from 'modules/utils';

export const fetchFooterSaga = function* (): SagaIterator {
	const footer = yield* call(Api.JSON.footer);

	if (footer.error) {
		yield* put(actions.fetchFooterJSONFailed({message: footer.error}));
		return;
	}
	yield* put(actions.fetchFooterJSONSuccess(footer));
};

import {HOCPPFetchJSONS} from 'components/HOCFetchJSONS';
import {BwwLogin} from 'components/BwwLogin';
import {HOCBackDoor} from 'components/HOCBackDoor';
import {HOCLocationCheck} from 'components/HOCLocationCheck';
import {Preloader} from 'components/Preloader';
import React from 'react';


export const HOCRunStart: React.FC = ({children}) => {
	return (
		<HOCBackDoor>
			<HOCLocationCheck>
				<BwwLogin>
					<HOCPPFetchJSONS fallback={<Preloader/>}>
						{children}
					</HOCPPFetchJSONS>
				</BwwLogin>
			</HOCLocationCheck>
		</HOCBackDoor>

	)
}


import {EnumEventsPayloadSort} from 'modules/utils/enums';
import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {theme} from 'assets/css/theme';
import {IconTick, IconRevert} from 'components';
import {partial} from 'lodash';
import {HOCBaseModal} from 'components/Modals';
import {useDispatch, useSelector} from 'react-redux';
import {getFilters} from 'modules/selectors/filters';
import {setFilter} from 'modules/actions/filters';


const Title = styled.div`
	font-family: ${ theme.fonts.bold };
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 33px;
	color: #ffffff;
`;
const borderStyled = ( withBorder: boolean = false ) => withBorder && css`
	border-top: 1px solid #312A28;
	border-bottom: 1px solid #312A28;
`;
const Checked = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const NotChecked = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #fff;
	opacity: .2;
`;

interface IOption {
	withBorder?: boolean;
	isChecked?: boolean;
}

const Option = styled.div<IOption>`
	font-size: 12px;
	line-height: 16px;
	padding: 10px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #ffffff;
	${ ( { withBorder = false } ) => borderStyled( withBorder ) };
`;
const Buttons = styled.div`
	margin-top: auto;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Button = styled.button`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #E3E1DB;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 5px;
`;
const RevertButton = styled( Button )`
	background: #57504e;
	svg {
		transform: rotate(180deg);
	}
`;

interface IProps {
	modalState: boolean;
	toggleModalState: () => void;
}

export const SortByFilter : React.FC<IProps> = ( { modalState, toggleModalState }: IProps ) => {
	const dispatch = useDispatch();
	const { sortBy } = useSelector( getFilters );
	const [selectedOption, changeOption] = useState( sortBy );

	const getChecked = ( option: 'asc' | 'desc' ) => selectedOption === option ? (
		<Checked>
			<IconTick width={ 10 } height={ 10 } color={ '#1C1C1C' }/>
		</Checked>
	) : <NotChecked/>;


	const closeFilter = () => {
		toggleModalState();
	}
	const approveFilter = () => {
		dispatch( setFilter( {
			sortBy: selectedOption
		} ) );
		closeFilter();
	}

	const resetBack = () => {
		changeOption( sortBy );
		closeFilter();
	}

	return (
		<HOCBaseModal isShow={ modalState } show_close_icon={ true } onCloseClick={ closeFilter }>
			<Title>Sort by</Title>
			<Option onClick={ partial( changeOption, EnumEventsPayloadSort.ASC ) }>
				Starting Soonest
				{ getChecked( 'asc' ) }
			</Option>
			<Option onClick={ partial( changeOption, EnumEventsPayloadSort.DESC ) }>
				Starting Latest
				{ getChecked( 'desc' ) }
			</Option>
			<Buttons>
				<RevertButton onClick={ resetBack }>
					<IconRevert width={ 16 } height={ 16 }/>
				</RevertButton>
				<Button onClick={ approveFilter }>
					<IconTick width={ 16 } height={ 16 } color={ '#1C1C1C' }/>
				</Button>
			</Buttons>
		</HOCBaseModal>
	)
}

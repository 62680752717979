import {createReducer} from 'redux-act';
import {fetchChecksumsJSONSuccess} from 'modules/actions';

export type IChecksum = Record<string, string>

const defaultState: IChecksum = {};

export const checksums = createReducer<typeof defaultState>({}, defaultState)
	.on(fetchChecksumsJSONSuccess, (_state, payload) => payload)
;

import * as global_reducers from 'modules/reducers';
import {applyMiddleware, combineReducers, createStore} from 'redux';
import {default as createSagaMiddleware} from 'redux-saga';
import * as reducers from './reducers';
import {rootSaga} from './sagas';

const sagaMiddleware = createSagaMiddleware();

export const rootReducer = combineReducers({ ...global_reducers, ...reducers });

export const store = createStore(
	rootReducer,
	applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(rootSaga);

import * as Cookies from 'js-cookie';
import * as actions from 'modules/actions';
import {DEFAULT_USER} from 'modules/constants';

import {IModelUser} from 'modules/types';
import {createReducer} from 'redux-act';

const is_authorized = !!Cookies.get('is_authenticated');

export interface IUserResponse {
	user: IModelUser;
	session_id: string;
	is_first_time_checked_in: boolean;
}

export interface IUserVerifyCodeResponse extends IUserLoginResponse {
	raw: string
	hashed: string;
}

export interface IUserObject {
	user: IModelUser
}

export interface IActionBackdoor {
	email: string
	store_id?: number
	name?: string;
}

export interface IActionSignUpStaff {
	code: string;
}

export interface IUserState {
	is_data_requested: boolean,
	is_logged_in: boolean,
	is_show_terms: boolean,
	is_updated: boolean,
	is_authorized: boolean,
	is_auth_flow_ended: boolean,
	error?: string
	update_error: string,
	user_obj: IModelUser,
	is_first_time_checked_in: boolean;
	staff_registration_error?: string;
}

export interface IUserLoginResponse extends IUserObject {
	session_id: string;
	is_first_time_checked_in: boolean;
}

const initialState: IUserState = {
	is_data_requested: false,
	is_logged_in: false,
	is_show_terms: false,
	is_updated: false,
	is_auth_flow_ended: false,
	is_authorized,
	error: '',
	update_error: '',
	is_first_time_checked_in: false,
	staff_registration_error: undefined,
	user_obj: DEFAULT_USER,
};


export const user = createReducer<IUserState>( {}, initialState )
	.on( actions.postUserLogin, state => ({
		...state,
		is_data_requested: true,
	}) )

	.on( actions.postUserLoginSuccess, ( state, payload ) => ( {
		...state,
		is_data_requested: false,
		is_authorized: true,
		is_show_terms: false,
		is_auth_flow_ended: true,
		is_logged_in: true,
		user_obj: payload.user,
		is_first_time_checked_in: payload.is_first_time_checked_in
	}) )

	.on( actions.postUserLoginFailed, ( state, payload ) => ( {
		...state,
		error: payload.text,
		is_data_requested: false,
		is_authorized: false,
		is_logged_in: false,
		is_auth_flow_ended: true,
		is_show_terms: false,
	}) )
	.on( actions.postUserLogout, ( state ) => ({
		...state,
		is_authorized: false,
	}) )
	// .on(actions.firstEnterUser, state => ({
	// 	...state,
	// 	is_show_terms: true,
	// }))
	.on( actions.postUserUpdateSuccess, ( state, { user: user_obj } ) => ({
		...state,
		is_data_requested: false,
		is_updated: true,
		user_obj,
	}) )
	.on( actions.postUserUpdateFailed, ( state, { text } ) => ({
		...state,
		update_error: text || '',
		is_updated: false,
		is_data_requested: false,
	}) )
	.on( actions.postUserUpdate, ( state ) => ({
		...state,
		update_error: '',
	}) )
	.on( actions.staffEntrySuccess, ( state ) => ({
		...state,
		staff_registration_error: undefined,
		user_obj: {
			...state.user_obj,
			staff: true
		}
	}) )
	.on( actions.staffEntryError, ( state, { text } ) => ({
		...state,
		staff_registration_error: text
	}) )
	.on( actions.staffEntryStart, ( state ) => ({
		...state,
		staff_registration_error: undefined
	}) )
;

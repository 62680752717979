import React, {useCallback, useEffect, useState} from 'react';
import {isUndefined, chain, first, last, partial} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import Calendar, {CalendarTileProperties} from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';
import {theme} from 'assets/css/theme';
import {IconChevron, IconTick, IconCross} from 'components/Icons';
import calendarIcon from 'assets/img/icons/icon-calendar.svg';
import {getAllEvents} from 'modules/selectors/dashboard';
import {EnumEventStatus} from 'modules/utils/enums';
import {setFilter} from 'modules/actions/filters';
import {toggleFixedBody} from 'modules/utils/dom';
import {IEvent} from 'modules/reducers/dashboard';
import moment, {Moment} from 'moment-timezone';

const CalendarContainer = styled.div`
	position:fixed;
	width: 100%;
	height: 100vh;
	z-index: 15;
	background-color: rgba(28,28,28, .7);
	top: 0;
	left: 0;
`;

const CalendarInner = styled.div`
	width: 100%;
	bottom: 0;
	border-radius: 10px 10px 0 0;
	background-color: #362E2C;
	position:absolute;
	z-index: 6;
	padding: 0 30px 20px;
	
	.react-calendar {
		background: rgba(0,0,0,0);
		border: none;
		width: 100%;
		box-sizing: border-box;
		.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus,
		.react-calendar__navigation__label {
			color: #fff;
			background: none;
			font-family: ${ theme.fonts.bold };
			font-size: 12px;
			font-weight: bold;
			line-height: 16px;
			text-align: center;
		}
		.react-calendar__month-view__weekdays__weekday {
			color: #FFFFFF;
			font-family: ${ theme.fonts.normal };
			font-size: 12px;
			line-height: 16px;
			text-align: center;
			height: 40px;
			abbr {
				text-decoration: none;
			}
		}
		.react-calendar__tile {
			padding: 0;
			width: 45px;
			height: 45px;
			background: none;
			position: relative;
			abbr {
				display: flex;
				color: #FEC600;
				font-size: 12px;
				font-family: ${ theme.fonts.normal };
				border-radius: 50%;
				width: 40px;
				height: 40px;
				background-color: #312A28;
				flex: 0 0 auto;
				padding: 2px;
				justify-content: center;
				align-items: center;
			}
			
		
			&.today {
				abbr {
					color: #312A28;
					background: #FEC600;
				}
				
			}
			&.future {
				abbr {
					border: 1px solid #FEC600;
					background: rgba(0,0,0,0);
				}
				
			}
			&.today:disabled,
			&.future:disabled,
			&:disabled {
				abbr {
					border: none;
					background: rgba(0,0,0,0);
					color: #976035;
				}
				
			}
		}
		.react-calendar__navigation {
			button {
				&:disabled {
					background: none;
					svg {
						opacity: .5;
					}
				}
			}
		}
		.react-calendar__year-view__months__month {
			padding: 0;
			border-radius: 20px;
			height: 30px;
		}
	}
`;

const Header = styled.header`
	color: #fff;
	margin-bottom: 20px;
	padding: 20px 10px 10px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: ${ theme.fonts.bold };
`;

const CheckButton = styled.button`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: none;
	background: #fff;
	margin: 20px auto 0;
	display: block;
`;

const CalendarBtn = styled.button`
	width: 20px;
	height: 20px;
	border: none;
	outline: none;
	cursor:pointer;
	background: none;
	padding: 0;
	text-align: center;
	img {
		width: 12px;
		margin-left: 0;
	}
`;

const EventsCount = styled.div`
	position: absolute;
    top: 3px;
    right: 6px;
    background: #f00;
    color: #ffffff;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const tileContent = ( events: IEvent[], { date }: any ) => {
	const calDay = date.getDate();
	const calMonth = date.getMonth();
	const calYear = date.getFullYear();
	const calDate = `${ calYear }-${ calMonth }-${ calDay }`;
	let eventCount = 0;
	events.forEach( ( { start, status } ) => {
		const eventDate = new Date( start );
		const day = eventDate.getDate();
		const month = eventDate.getMonth();
		const year = eventDate.getFullYear();
		const evDateString = `${ year }-${ month }-${ day }`;
		if ( calDate === evDateString && status !== EnumEventStatus.Complete ) {
			eventCount++;
		}
	} )

	return eventCount ? (
		<EventsCount>{ eventCount }</EventsCount>
	) : null;
}

const getFormatted = ( format: string, date: Moment ) => date.format( format );

const formatShortWeekday = ( _: string, date: Date ) => moment( date )
	.format( 'dd' )[0];

const getItems = ( events: IEvent[] ) => chain( events )
	.orderBy( [
		event => {
			return new Date( event.start ).getTime();
		}
	], ['asc'] )
	.value();

const longFormat = 'YYYY-MM-DD';
const shirtFormat = 'YYYY-MM';

const isTileDisabled = ( contests: any[], { date, view }: CalendarTileProperties ) => {
	const momentDate = moment( date );

	if ( view === 'month' ) {
		const formattedDate = getFormatted( longFormat, momentDate );
		return isUndefined( contests.find( ( { date: contestDate } ) => contestDate === formattedDate ) );
	}

	if ( view === 'year' ) {
		const formattedDate = getFormatted( shirtFormat, momentDate );
		return isUndefined( contests.find( ( { monthYear } ) => monthYear === formattedDate ) );
	}

	return false;
}

const getFormattedContests = ( items: IEvent[] ) => items.map( ( { id, start } ) => {
	const date = moment( start );
	return {
		date: getFormatted( longFormat, date ),
		monthYear: getFormatted( shirtFormat, date ),
		id
	}
} );

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ContestCalendar: React.FC = () => {
	const dispatch = useDispatch();
	const { completed = [], live = [], upcoming = [] } = useSelector( getAllEvents );
	const events = completed.concat( live, upcoming );
	const items: any[] = getItems( events );
	const [isOpen, toggleCalendar] = useState( false );

	useEffect( () => {
		toggleFixedBody( isOpen );
	}, [isOpen] );

	const handleOpenState = useCallback( () => {
		toggleCalendar( !isOpen );
	}, [isOpen] );

	const firstContest = first( items );
	const lastContest = last( items );

	const today = moment()
		.format( longFormat );
	const todayMonthYear = moment()
		.format( shirtFormat );
	const formattedContest = getFormattedContests( items );

	const onChange = useCallback( ( date ) => {
		const momentDate = moment( date )
			.format( longFormat );
		dispatch( setFilter( {
			date: momentDate
		} ) )
	}, [dispatch] );

	if ( !lastContest || !firstContest ) {
		return null;
	}


	const tileClassName = ( { date, view }: CalendarTileProperties ) => {
		const momentDate = moment( date );

		if ( view === 'month' ) {
			const formattedDate = momentDate.format( longFormat );
			if ( formattedDate === today ) {
				return 'today';
			}
			return formattedDate > today ? 'future' : '';
		}

		if ( view === 'year' ) {
			const formattedDate: string = momentDate.format( longFormat );
			if ( formattedDate === todayMonthYear ) {
				return 'today';
			}
			return formattedDate > todayMonthYear ? 'future' : '';
		}

		return '';
	}

	return isOpen ? (
		<CalendarContainer>

			<CalendarInner>
				<Header>
					<p>Find a game</p>
					<IconCross onClick={ handleOpenState }/>
				</Header>
				<Calendar next2Label={ null } prev2Label={ null } locale={ 'us-US' }
						  maxDate={ new Date( lastContest.end ) } minDate={ new Date( firstContest.start ) }
						  formatShortWeekday={ formatShortWeekday }
						  calendarType={ 'US' }
						  showNeighboringMonth={ false }
						  nextLabel={ <IconChevron to={ 'right' }/> }
						  prevLabel={ <IconChevron to={ 'left' }/> }
						  tileDisabled={ partial( isTileDisabled, formattedContest ) }
						  tileClassName={ tileClassName }
						  tileContent={ partial( tileContent, events ) }
						  onChange={ onChange }
				/>

				<CheckButton onClick={ handleOpenState }><IconTick color={ '#362E2C' }/></CheckButton>
			</CalendarInner>
		</CalendarContainer>
	) : (
		<CalendarBtn>
			{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
			<img onClick={ handleOpenState } src={ calendarIcon } alt={ 'Open Calendar' }/>
		</CalendarBtn>
	)
};

import styled from 'styled-components';

interface IProps {
	background_color?: string
}

export const LiveIcon = styled.div<IProps>`
	height: 50px;
	width: 50px;
	border-radius: 50%;
	background: ${props => props.background_color || 'transparent'};
	display: flex;
	margin: 0 auto 10px;
	align-items: center;
	justify-content: center;
	color: #FFFFFF;
	font-family: ${({theme}) => theme.fonts.bold};
	font-size: 10px;
	font-weight: bold;
	line-height: 10px;
	text-align: center;
	flex-direction: column;
	img {
		width: 8px;
	}
	position: relative;
	&:before {
		position: absolute;
		border: 1px solid #fff;
		content: '';
		display: block;
		border-radius: 50%;
		left: 5%;
		top: 5%;
		width: 90%;
		height: 90%;
		box-sizing: border-box;
		
	}
`;
import {keyframes} from 'styled-components';


export const slideUp = keyframes`
    from {
        transform: translateY(-100px);
        opacity: 1;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

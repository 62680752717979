import qs from 'qs';

export const getUserDataFromQueryString = (): Record<string, unknown> => {
	const {search} = window.location;
	return qs.parse(search, {
		ignoreQueryPrefix: true,
		decoder: e => decodeURIComponent(e)
	});
};

export const cleanSearchParams = (): void => {
	window.history.replaceState(null, '', window.location.pathname);
	window.location.reload();
};

export const isLocalEnv = window.location.href.includes( '.loc' );

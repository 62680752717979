import {createAction} from 'redux-act';
import {IRequestCode} from 'modules/types';


export const requestVerifyCode = createAction<IRequestCode>();
export const requestVerifyCodeSuccess = createAction();
export const requestVerifyCodeFailed = createAction<string>();

export const verifyCode = createAction<string>();
export const verifyCodeSuccess = createAction();
export const verifyCodeFailed = createAction<string>();
export enum EnumStatsType {
	pp = 'pp',
	trivia = 'trivia'
}

export interface IRewardPoints {
	type: EnumStatsType;
	value: number;
	percent: number;
	title?: string;
	color?: string;
}

export const getByType = (type: EnumStatsType) => {
	return {
		[EnumStatsType.pp]: {
			title: 'Picks & Props',
			color: '#FEC600',
		},
		[EnumStatsType.trivia]: {
			title: 'Trivia',
			color: '#2D5371',
		},
	}[type];
};

export const getProfileValue = ( value: number | null ) => value || 'N/A';

export const getPercent = (value: number, total: number) => {
	return Number((value / total * 100).toFixed(1));
};

import {IFilters} from 'modules/reducers/filters';
import {EnumTimesOfGame} from 'modules/utils/enums';
import {IconTick} from 'components/Icons/IconTick';
import {partial} from 'lodash';
import React from 'react';
import styled, {css} from 'styled-components';


const borderStyled = ( withBorder: boolean = false ) => withBorder && css`
	border-top: 1px solid #312A28;
	border-bottom: 1px solid #312A28;
`;
const Checked = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const NotChecked = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #fff;
	opacity: .2;
`;

interface IOption {
	withBorder?: boolean;
	isChecked?: boolean;
}

const Option = styled.div<IOption>`
	font-size: 12px;
	line-height: 16px;
	padding: 10px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #ffffff;
	${ ( { withBorder = false } ) => borderStyled( withBorder ) };
`;

interface IProps {
	selectedOption: string;
	changeCallBack: ( option: IFilters['times'] ) => void;
}

export const TimesFilter = ( { selectedOption, changeCallBack }: IProps ) => {
	const getChecked = ( option: IFilters['times']) => selectedOption === option ? (
		<Checked>
			<IconTick width={ 10 } height={ 10 } color={ '#1C1C1C' }/>
		</Checked>
	) : <NotChecked/>;

	return (
		<React.Fragment>
			<Option onClick={ partial( changeCallBack, EnumTimesOfGame.All ) } withBorder={ true }>
				All dates
				{ getChecked( EnumTimesOfGame.All  ) }
			</Option>
			<Option onClick={ partial( changeCallBack, EnumTimesOfGame.Live ) }>
				Live
				{ getChecked( EnumTimesOfGame.Live ) }
			</Option>
			<Option onClick={ partial( changeCallBack, EnumTimesOfGame.Upcoming ) }>
				Upcoming
				{ getChecked( EnumTimesOfGame.Upcoming ) }
			</Option>
		</React.Fragment>
	);
}

import * as actions from 'modules/actions';
import {getRegionByLocalTime} from 'modules/utils/dom';
import {createReducer} from 'redux-act';
import {EnumRegion} from 'modules/utils/enums';


export interface ISettingsReducer {
	be_time_diff: number;
	is_region_configured: boolean;
	region: EnumRegion
}

const defaultState: ISettingsReducer = {
	be_time_diff: 0,
	is_region_configured: false,
	region: getRegionByLocalTime()
};

export const settings = createReducer<ISettingsReducer>({}, defaultState)
	.on(actions.setBETimeDiff, (state, be_time_diff) => ({
		...state,
		be_time_diff,
	}))
	.on(actions.setRegion, (state, region) => ({
		...state,
		region,
		is_region_configured: true
	}))
;

import React, {useEffect, useState} from 'react';
import {PlayHubApi} from 'modules/utils';
import {ITriviaHelpItem} from 'modules/types';
import {QuestionExpandable} from 'pages/rules/QuestionExpandable';
import {defaultTo} from 'lodash';

export const TriviaFaqs = () => {
	const [faqs, setFaqs] = useState<ITriviaHelpItem[]>([{
		position: 0,
		name:     "",
		text:     "",
	}]);

	const fetchTerms = async () => {
		const response = await PlayHubApi.JSON.trivia_faq();
		if (response) {
			setFaqs(response.items);
		}
	}

	useEffect(() => {
		void fetchTerms();
	}, [])

	return (
		<React.Fragment>
			{faqs &&
				faqs.map((item, index) => (
					<QuestionExpandable
						question={ defaultTo(item?.name, "")}
						text={item.text}
						key={index}
					/>
				))}
		</React.Fragment>
	);
}

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {PlayHubApi} from 'modules/utils';

export interface ITriviaGuideLine {
	items: IGuideLineItem[];
}

export interface IGuideLineItem {
	position: number;
	name:     string;
	text:     string;
}


const Title = styled.div`
    font-family: ${({theme}) => theme.fonts.bold};
    font-size: 16px;
    margin-bottom: 10px;
`;
const ContentWrapper = styled.div``;

export const TriviaGuidelines = () => {
	const [terms, setTerms] = useState<IGuideLineItem[]>([{
		position: 0,
		name:     "",
		text:     "",
	}]);

	const fetchTerms = async () => {
		const response = await PlayHubApi.JSON.trivia_guidelines();
		if (response) {
			setTerms(response.items);
		}
	}

	useEffect(() => {
		void fetchTerms();
	}, [])

	return (
		<React.Fragment>
			{terms?.map((item) => (
				<React.Fragment key={item.name}>
					<Title>{item.name}</Title>
					{/* eslint-disable-next-line react/no-danger */}
					<ContentWrapper dangerouslySetInnerHTML={{__html: item.text}} />
				</React.Fragment>
			))}
		</React.Fragment>
	);
}

import {createAction} from 'redux-act';
import {EnumEventsPayloadGames, EnumEventsPayloadSort, EnumEventsPayloadTimes, EnumRegion} from 'modules/utils/enums';
import {IEvents} from 'modules/reducers/dashboard';

export interface IFetchEventsPayload {
	date?: string; // format YYYY-MM-DD
	sort?: EnumEventsPayloadSort;
	times?: EnumEventsPayloadTimes;
	games?: EnumEventsPayloadGames;
	region: EnumRegion;
	skipLoginCheck?: boolean;
}

export const fetchEvents = createAction<IFetchEventsPayload>();
export const fetchEventsSuccess = createAction<IEvents>();
export const fetchEventsFailed = createAction();

export const fetchIsBettingAllowed = createAction<boolean>();
export const fetchIsBettingAllowedSuccess = createAction<boolean>();

import React, {useCallback, useEffect, useState} from 'react';
import logoImage from 'assets/img/logo.svg';
import styled from 'styled-components';
import {SECRET_STORAGE_KEY} from 'modules/utils';

const PureButton = styled.button`
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
`;


export const SecretLogo: React.FC = () => {
	const [clicked, setClicked] = useState(0);

	useEffect(() => {
		if (clicked > 10) {
			localStorage.setItem(SECRET_STORAGE_KEY, JSON.stringify(true));
			window.location.reload();
		}
	}, [clicked])

	const logoClick = useCallback(() => {
		setClicked(clicked + 1);
	}, [clicked])

    return(
		<div>
			<PureButton onClick={logoClick}>
				<img src={ logoImage } alt="BWW logo"/>
			</PureButton>
		</div>
    );
}

import {EventCardWrapper} from 'components/EventCard';
import {IMgmCard} from 'modules/reducers/mgmCards';
import React, {useCallback} from 'react';
import styled from 'styled-components';
import {IconBetMGM} from 'components/Icons/IconBetMGM';
import {useSelector} from 'react-redux';
import {getUser} from 'modules/selectors';
import {partial} from 'lodash';


const EventCardMGMWrapper = styled(EventCardWrapper)`
	background-color: #1A1A1A;
	color: #fff;
	font-family: Barlow, sans-serif;
`;

const EventCardMGMContent = styled.div`
	padding: 20px 20px 15px;
`;

const EventCardMGMFooter = styled.div`
	background-color: #000;
	padding: 17px 20px 18px;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const EventCardMGMFooterText = styled.div`
	font-size: 14px;
	line-height: 16px;
	font-weight: 700;
	flex-grow: 1;
`;

const EventCardMGMFooterAction = styled.div`
	margin-left: 20px;
	flex-shrink: 0;
`;

const EventCardMGMButton = styled.button`
	background-color: #C1A971;
	border-radius: 2px;
	text-transform: uppercase;
	color: #fff;
	font-weight: 700;
	font-size: 12px;
	line-height: 13px;
	border: 0;
	padding: 9px 10px 8px;
	outline: none;
	cursor: pointer;
	margin: 0;

	&:hover,
	&:focus {
		background-color: #8e763e;
	}
`;

const MatchInfo = styled.div`
	font-weight: 700;
	font-size: 10px;
	line-height: 12px;
	text-transform: uppercase;
	margin-bottom: 10px;
`;

const MarketName = styled.div`
	font-size: 15px;
	line-height: 18px;
	font-weight: 700;
`;

// const BetInfo = styled(MarketName)`
// 	margin-bottom: 15px;
// `;

const MarketCard = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
`;

const MarketCardInfo = styled.div`
	flex-grow: 1;
	margin-right: 10px;
`;

const MarketCardOdds = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

// const PrevOdds = styled.div`
// 	display: flex;
// 	flex-direction: row;
// 	align-items: center;
// 	font-weight: 500;
// 	font-size: 12px;
// 	line-height: 14px;
// `;

// const PrevOddsTitle = styled.div`
// 	margin-right: 5px;
// `;

// const PrevOddsValue = styled.div`
// 	font-size: 20px;
// 	line-height: 20px;
// 	font-weight: 700;
// 	position: relative;
//
// 	&:before {
// 		content: '';
// 		width: 110%;
// 		height: 3px;
// 		background-color: #C1A971;
// 		position: absolute;
// 		left: 0;
// 		top: 50%;
// 		transform: rotate(-21deg);
// 	}
// `;

const Odds = styled.div`
	background-color: #C1A971;
	color: #1A1A1A;
	border-radius: 31px;
	text-align: center;
	min-width: 125px;
	padding: 8px 10px;
`;

const OddsValue = styled.div`
	font-size: 30px;
	line-height: 1;
	font-weight: 700;
`;

const OddsDescription = styled.div`
	font-weight: 500;
	font-size: 9px;
	line-height: 11px;
`;

const SponsorLogo = styled.div`
	margin-top: 15px;
	text-align: center;

	svg {
		width: 60px;
		height: auto;
	}
`;

const BET_VALUE = 25;

interface IEventCardMGM {
	card: IMgmCard;
}

export const EventCardMGM: React.FC<IEventCardMGM> = ({ card }: IEventCardMGM) => {
	const user = useSelector(getUser);
	const { is_legal_betting_state, device_type: deviceType } = user;

	const return_bet_value = ( BET_VALUE * Number(card.odds) ).toFixed(2);
	const not_legal_betting_link = 'https://promo.nj.betmgm.com/en/promo/sports/bww-switcher';
	const unitedURLScheme = "playmgmsportswrp://navigation?scheme=https&url=";

	const getBetMgmLink = useCallback((stake: number, option_id: number) => {
		const { legal_betting_state_abbreviation, checked_in, bww_id, store_id, is_guest, id } = user;

		const deep_link_without_info = `https://sports.${legal_betting_state_abbreviation}.betmgm.com/en/sports?options=${option_id}&stake=${stake}&wm=7028498`;
		const guest_deep_linking = deep_link_without_info + `&tdpeh=${id}`;
		const user_deep_linking = checked_in
			? deep_link_without_info + `&tdpeh=${ bww_id }&btag=${ store_id }`
			: deep_link_without_info + `&tdpeh=${ bww_id }`;
		const deep_linking = (is_guest) ? guest_deep_linking : user_deep_linking;
		const url_scheme = `betmgm${legal_betting_state_abbreviation}sportswrp://navigation?scheme=https&url=`;

		return {
			deep_linking,
			url_scheme,
		}
	}, [user]);

	const betSlipDeepLink = useCallback((option_id: number) => {
		const { deep_linking, url_scheme } = getBetMgmLink(BET_VALUE, option_id);
		const is_app = deviceType && (deviceType === 'ios' || deviceType === 'android');
		const firstScheme = unitedURLScheme + encodeURIComponent(deep_linking);
		const secondScheme = url_scheme + encodeURIComponent(deep_linking);
		const data = {command: "externalAppLink", appLinks: [firstScheme, secondScheme], webLink: deep_linking};
		if (is_app) {
			return window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(data));
		} else {
			return window.location.href = deep_linking;
		}
	}, [deviceType, getBetMgmLink]);

	const betNow = useCallback((option_id: number) => {
		if ( !is_legal_betting_state ) {
			window.location.href = not_legal_betting_link;
			return;
		}

		betSlipDeepLink(option_id);
	}, [is_legal_betting_state, betSlipDeepLink]);

	const market_text = card.match_info || card.market_name;

	return (
		<EventCardMGMWrapper>
			<EventCardMGMContent>
				<MatchInfo>{market_text}</MatchInfo>
				<MarketCard>
					<MarketCardInfo>
						<MarketName>{card.market_copy}</MarketName>
					</MarketCardInfo>
					<MarketCardOdds>
						<Odds>
							<OddsValue>{card.american_odds}</OddsValue>
							<OddsDescription>only available at BWW</OddsDescription>
						</Odds>
						<SponsorLogo>
							<IconBetMGM/>
						</SponsorLogo>
					</MarketCardOdds>
				</MarketCard>
			</EventCardMGMContent>
			<EventCardMGMFooter>
				<EventCardMGMFooterText>A ${BET_VALUE} bet on these odds will return you ${return_bet_value}</EventCardMGMFooterText>
				<EventCardMGMFooterAction>
					<EventCardMGMButton onClick={partial(betNow, card.option_id)}>Bet Now</EventCardMGMButton>
				</EventCardMGMFooterAction>
			</EventCardMGMFooter>
		</EventCardMGMWrapper>
	);
}

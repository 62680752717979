import {fetchAdSlotsJSONFailed, fetchAdSlotsJSONSuccess} from 'modules/actions/adSlots';
import {createReducer} from 'redux-act';

export interface IAdSlot {
	image: string;
	link: string;
	text: string;
	price: string;
	externalLink: string;
}

export interface IAdSlotsReducerState {
	adSlot: IAdSlot,
	isFetched: boolean
}

const initialState: IAdSlotsReducerState = {
	adSlot: {
		image: '',
		link: '',
		price: '',
		text: '',
		externalLink: '',
	},
	isFetched: false,
};

export const adSlots = createReducer<IAdSlotsReducerState>( {}, initialState );

adSlots.on(fetchAdSlotsJSONSuccess, (state: IAdSlotsReducerState, payload: IAdSlot) => ( {
	...state,
	adSlot: payload,
	isFetched: true
} ));
adSlots.on(fetchAdSlotsJSONFailed, () => ( initialState ));

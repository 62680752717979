import {IApiResponse} from 'modules/types';
import {find, isEmpty} from 'lodash';

/**
 * Custom Errors handler
 */
export class ApiError extends Error {
	public static AUTH_REQUIRED = 401;
	// public static USER_EXPIRED_TOKEN = 510;
	public static USER_NOT_REGISTERED = 404;
	public code?: number;
	public text?: string;

	constructor(m: string, code?: number) {
		super(m);
		this.text = m;
		this.name = 'API Error';
		this.code = code;
	}

	public static HAS_ERROR({errors}: IApiResponse) {
		return Boolean(Array.isArray(errors) && errors.length);
	}

	public static HAS_ERROR_MESSAGES({messages}: IApiResponse) {
		return Boolean(Array.isArray(messages) && find(messages, {status: 'error'}));
	}

	/**
	 * Should be called after each requests when we expect some errors or validations
	 */
	public static CHECK( response: any, checkEmptyFix = true) {
		if ( !response ) {
			ApiError.THROW_ERROR('No response from API');
		}

		if (ApiError.HAS_ERROR(response)) {
			const error = response.errors[0];
			throw new ApiError(error.text, error.code);
		}

		if (ApiError.HAS_ERROR_MESSAGES(response) && response.messages) {
			const error = response.messages[0];
			throw new ApiError(error.text, error.code);
		}

		this.checkEmptyResponse(response, checkEmptyFix);
	};

	private static checkEmptyResponse ( response: IApiResponse, checkIsEmpty: boolean ) {
		if ( !response || (isEmpty(response) && checkIsEmpty) ) {
			throw new ApiError(`Response shouldn't be empty`, 204);
		}
	}

	/**
	 * Should be called after each requests when we expect some errors or validations
	 */
	public static THROW_ERROR(message: string) {
		throw new ApiError(message, 0);
	};

}

import {createReducer} from 'redux-act';
import {setModalState} from 'modules/actions';

export interface IModalPayload {
	key: keyof IModalState;
	state: boolean;
}

export interface IModalState {
	isLeaderboardRoundModalOpen: boolean;
	isEcoTutorialOpen: boolean;
	isFilterStateOpen: boolean;
	isTriviaTutorialOpen: boolean;
	isLeaderboardDaysModalOpen: boolean;
}

const initialState: IModalState = {
	isLeaderboardRoundModalOpen: false,
	isEcoTutorialOpen: false,
	isFilterStateOpen: false,
	isTriviaTutorialOpen: false,
	isLeaderboardDaysModalOpen: false,
};

export const modals = createReducer<IModalState>( {}, initialState )
	.on( setModalState, ( state: IModalState, payload: IModalPayload ) => ({
		...state,
		[payload.key]: payload.state,
	}) );

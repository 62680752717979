import React, {lazy, Suspense} from 'react';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import 'assets/css/core.css';
import 'assets/css/reset.css';
import 'assets/fonts/fonts.css';

import ReactDOM from 'react-dom';
import {unregister as unregisterServiceWorker} from './serviceWorker';

import {store} from 'modules/store';
import {theme} from 'assets/css/theme';
import {Footer, HOCRegion, Menu, Preloader} from 'components';
import {MobileContainer} from 'components/MobileContainer';
import {HOCTermsChecking} from 'components/TermsChecking';
import {isLoggedIn} from 'modules/selectors/user';
import {BASE_URL, IS_UAT, retryFailLoad} from 'modules/utils';
import {RulesPage} from './pages/RulesPage';
import {Provider, useSelector} from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {UserProfile} from 'components/UserProfile';
import {HOCRunStart} from 'components/HOCRunStart';
import {TutorialModal} from 'components/Modals/TutorialModal';
import {AdSlots} from 'components/AdSlots';
import {Header} from 'components/Header';
import {Logout} from 'components/Logout';

const PlayHub = lazy(retryFailLoad(() => import('pages/PlayHub')));
const PlayHubDebug = lazy(retryFailLoad(() => import('pages/PlayHubDebug')));
const Landing = lazy(retryFailLoad(() => import('pages/Landing')));

Sentry.init({
	dsn: "https://6c239adcc6694d00926ceef1dd8f0c5e@o151969.ingest.sentry.io/5356427",
	environment: process.env.REACT_APP_SENTRY_ENV,
	release     : process.env.REACT_APP_RELEASE_VERSION,
	allowUrls   : [
		'play.buffalowildwings.com',
		'.fanhubmedia.com'
	],
	integrations: [
		new Integrations.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false
		})
	],
	denyUrls    : [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i
	],
	ignoreErrors: [
		/undefined is not an object\(evaluating '\w.user'\)/gm,
		"Cannot read properties of undefined (reading 'user')",
		"undefined is not an object (evaluating 'i.user')",
		"404 while login user, register according: 404, User_not_registered",
		"User_already_registered",
		"Bww_id must not be empty",
		"Please enter another name and refrain from using profanity.",
		"Authentication Required",
		"Load failed",
		"Failed to fetch",
		"Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target"
	],
	sampleRate: 0.1
})

const Routes: React.FC = () => {
	const is_logged_in = useSelector(isLoggedIn);

	if (!is_logged_in) {
		return (
			<Suspense fallback={<Preloader/>}>
				<Route exact={true} path="/" component={Landing}/>
				<Route exact={true} path="/rules" component={RulesPage}/>
			</Suspense>
		)
	}

	return (
		<MobileContainer>
			<HOCRegion>
				<HOCTermsChecking>
					<Menu/>
					<Header userProfile={UserProfile}
					        defaultOpenUserStats={true}
					        tutorialType={'eco'}/>

					<Suspense fallback={<Preloader/>}>
						<Switch>
							<Route exact={true} path="/" component={PlayHub}/>

							{IS_UAT && (
								<Route exact={true} path="/debug" component={PlayHubDebug}/>
							)}

							<Route exact={true} path="/landing" component={Landing}/>
							<Route exact={true} path="/rules" component={RulesPage}/>
							<Route exact={true} path="/logout" component={Logout}/>

						</Switch>
					</Suspense>
					<AdSlots/>
					<TutorialModal/>
					<Footer/>
				</HOCTermsChecking>
			</HOCRegion>
		</MobileContainer>
	)
};

const APP: React.FC = Sentry.withProfiler(() => (
	<Suspense fallback={<Preloader/>}>
		<Provider store={store}>
			<Router basename={BASE_URL}>
				<ThemeProvider theme={theme}>
					<HOCRunStart>
						<Routes/>
					</HOCRunStart>
				</ThemeProvider>
			</Router>
		</Provider>
	</Suspense>
));

ReactDOM.render(( <APP/> ), document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregisterServiceWorker();

import * as React from 'react';
import {IIconProps} from 'modules/types';

export const IconTick: React.FC<IIconProps> = ({
	                                           title,
	                                           width,
	                                           height,
	                                           color,
	                                           onClick,
	                                           className,
                                           }) => (
	<svg width={`${width}px`} height={`${height}px`} viewBox="0 0 13 9" onClick={onClick} className={className}>
		<title>{title}</title>
		<path
			fill={color}
			d="M12.6403683,1.99528302 L5.61959769,8.66037736 C5.14260266,9.11320755 4.35257964,9.11320755 3.86067852,8.66037736 L0.357746272,5.33490566 C-0.119248757,4.88207547 -0.119248757,4.13207547 0.357746272,3.66509434 C0.834741302,3.21226415 1.62476432,3.21226415 2.11666544,3.66509434 L4.7550442,6.16981132 L10.8963552,0.339622642 C11.3733502,-0.113207547 12.1633733,-0.113207547 12.6552744,0.339622642 C13.1173633,0.79245283 13.1173633,1.54245283 12.6403683,1.99528302 Z"
			id="tick"
		/>
	</svg>
);

IconTick.defaultProps = {
	title: 'Tick',
	width: 13,
	height: 9,
	color: '#000'
};

import * as React from 'react';
import {IIconProps} from 'modules/types';

export const IconCheckIn:React.FC<IIconProps> = ({ title, width, height, color, onClick }) => (
	<svg  width={`${ width }px`} height={`${ height }px`} onClick={ onClick } viewBox="0 0 20 30">
		<title>{ title }</title>
		<g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g  transform="translate(-5.000000, 0.000000)" fill={color}>
				<path d="M15,0 C20.5162608,0 25,4.60173237 25,10.2631579 C25,12.4335137 24.6004838,14.7549343 23.8266097,17.178312 C23.2320687,19.0377415 22.412865,20.9662978 21.3929925,22.9166353 C20.6410404,24.3544211 19.8420675,25.6918011 19.0418828,26.9020319 L18.7569854,27.327786 L18.4981203,27.7049829 L18.2214268,28.0964522 L17.9812795,28.4245884 C17.944272,28.4741997 17.9087658,28.5213219 17.8747503,28.5660298 C17.362588,29.2355294 16.646803,29.7026396 15.8478942,29.8979366 L15.66211,29.9381485 L15.521376,29.9647113 L15.1357495,30 L14.9988462,30 C13.9442643,30 12.9434582,29.5325886 12.2184022,28.6779996 L12.0857399,28.5132466 L11.7171189,28.0094741 C10.7220757,26.6143854 9.63747545,24.8883924 8.60692382,22.9164754 C7.58713497,20.9662978 6.76793131,19.0377415 6.17367224,17.1791933 C5.3995688,14.7605799 5,12.4365404 5,10.2631579 C5,4.60173237 9.48373923,0 15,0 Z M15,3.15789474 C11.1830769,3.15789474 8.07692308,6.34578947 8.07692308,10.2631579 C8.07692308,12.0821053 8.41961538,14.0775 9.09692308,16.1936842 C9.63346154,17.8717105 10.3811538,19.6314474 11.3180769,21.4231579 C12.3367521,23.3723496 13.3469146,24.9186814 13.9591971,25.8017515 L14.1999227,26.1441919 L14.5453846,26.6159211 C14.6367308,26.7353289 14.768141,26.8127961 14.9115705,26.8353015 L14.9988462,26.8421053 L15.1055815,26.8323379 C15.2149625,26.8116928 15.3165925,26.7589373 15.3977677,26.6799695 L15.4828109,26.5786583 L15.6950278,26.2902824 C16.2030581,25.5876264 17.4354196,23.806561 18.6819231,21.4231579 C19.6188462,19.6314474 20.3665385,17.8717105 20.9030769,16.1936842 C21.5792308,14.0763158 21.9230769,12.0821053 21.9230769,10.2631579 C21.9230769,6.34578947 18.8169231,3.15789474 15,3.15789474 Z M15,6 C17.2053333,6 19,7.79466667 19,10 C19,12.2053333 17.2053333,14 15,14 C12.7946667,14 11,12.2053333 11,10 C11,7.79466667 12.7946667,6 15,6 Z" />
			</g>
		</g>
	</svg>

);

IconCheckIn.defaultProps = {
	title: 'Checkin',
	width: 20,
	height: 30,
	color: '#000'
};


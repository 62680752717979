import logo from 'assets/img/logos/triviaLogoDark.svg';
import {EventCardLogo} from 'components/EventCard/EventCardLogo';
import {EventCardName} from 'components/EventCard/EventCardName';
import {EventCardTriviaHeader as EventCardTriviaHeaderBase} from 'components/EventCard/EventCardTriviaHeader';
import {EventCardWrapper} from 'components/EventCard/EventCardWrapper';
import {EventDate} from 'components/EventCard/EventDate';
import {EventTimer} from 'components/EventCard/EventTimer';
import {IEvent} from 'modules/reducers/dashboard';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import bg from 'assets/img/cards/trivia_herd.png';
import bgSmall from 'assets/img/cards/trivia_herd_small.png';


const EventCardWrapperStyled = styled(EventCardWrapper)`
	${EventCardName} {
		color: #372E2C;
	}
	${EventDate} {
		color: #372E2C;
		margin-bottom: 40px;
	}
	.event-timer {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin-bottom: 0;
	}
`;

const EventCardHeaderWrapper = styled.div`
	position:relative;
`;

const EventCardTriviaHeader = styled(EventCardTriviaHeaderBase)`
	background: url(${props => props.isSmall ? bgSmall : bg}) center bottom no-repeat transparent;
`;

interface IProps {
	event: IEvent
}

export const EventCardUpcomingTriviaHerd: React.FC<IProps> = ({event}) => {
	const start = moment(event.start)
		.format('hh:mma, ddd, MMM DD YYYY');

	return (
		<EventCardWrapperStyled>
			<EventCardHeaderWrapper>
				<EventCardTriviaHeader isSmall={true}>
					<EventCardLogo src={logo} alt={'Trivia'}/>
				</EventCardTriviaHeader>
				<EventTimer className={'event-timer'} date={event.start} uniq={event.id} wrapperBackground={'#1C1C1C'}/>
			</EventCardHeaderWrapper>

			<EventCardName>
				{event.name}
			</EventCardName>
			<EventDate>{start}</EventDate>

		</EventCardWrapperStyled>
	)
};

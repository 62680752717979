// import { ReactComponent as dulce_vida_milestone } from "assets/img/trivia/rounds/dulce_vida_milestone";
import {getParsedQS} from 'modules/utils/dom';

import {EnumTriviaContestType} from 'modules/utils/enums';

export const BWW_WEB_STORE_ID = Number(getParsedQS().BWW_store_id);

interface IContestTypeTitle {
	title?: string;
	short_title?: string;
}

const ContestTypeTitle: Record<EnumTriviaContestType, IContestTypeTitle> = {
	[ EnumTriviaContestType.lunchtime ]: {
		title: 'Lunchtime Trivia',
		short_title: 'Lunchtime'
	},
	[ EnumTriviaContestType.happy_hour ]: {
		title: 'Happy Hour Trivia',
		short_title: 'Happy Hour'
	},
	[ EnumTriviaContestType.late_night ]: {
		title: 'Late Night Trivia',
		short_title: 'Late Night'
	},
	[ EnumTriviaContestType.team_night ]: {
		title: 'Blazin\' Trivia',
		short_title: 'Blazin\''
	}
};

export const getContestTypeTitle = (type: EnumTriviaContestType | undefined) => {
	if ( !type ) {
		return {
			title: '',
			short_title: ''
		};
	}

	return ContestTypeTitle[ type ];
};



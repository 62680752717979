import {omit} from 'lodash';
import * as actions from 'modules/actions';
import {IActionBackdoor, IUserObject} from 'modules/reducers/user';
import {IError, IModelUser, ISagaAction} from 'modules/types';
import * as Sentry from '@sentry/browser';

import {User} from 'modules/utils';
import {Api} from 'modules/utils/Api';
import {ApiError} from 'modules/utils/Api/ApiError';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';


export const postUserLogoutSaga = function* (): SagaIterator {
	try {
		const response = yield* call(Api.Auth.logout);
		ApiError.CHECK(response);
	} catch (e: any) {
		const error = e as Error;
		yield* put(actions.globalError(error))
	}
};

export const postUserCreateSaga = function* (action: IUserObject): SagaIterator {
	const user_obj = action.user;
	try {
		yield* call(postUserLogoutSaga);

		const response = yield* call(Api.User.create, user_obj);

		if (!response || !response?.result) {
			const errorMessage = `Empty response: ${JSON.stringify(response?.result)}, errors: ${JSON.stringify(response?.errors)}`;
			Sentry.captureException(errorMessage);
		}

		ApiError.CHECK(response);

		const {
			result: {user, session_id, is_first_time_checked_in}
		} = response;

		User.SAVE(session_id);

		yield* put(actions.postUserLoginSuccess({
			user: User.SET_USER_GOD_MODE(user),
			session_id,
			is_first_time_checked_in
		}));

		if (user.eco_tutorial === 0 && process.env.REACT_APP_GAME === 'multisport_pickem') {
			yield* put(actions.setModalState({
				key: 'isEcoTutorialOpen',
				state: true
			}));
		}

	} catch (e: any) {
		const error = e as Error;
		yield* put(actions.postUserLoginFailed({text: error.message}));
		yield* put(actions.globalError(error));
	}
};

export const postUserLoginSaga = function* (action: ISagaAction<IUserObject>): SagaIterator {
	const {payload: {user: userPostData}} = action;

	try {
		const response = yield* call(Api.Auth.login, userPostData);
		ApiError.CHECK(response);

		const {
			result: {user, session_id, is_first_time_checked_in}
		} = response

		User.SAVE(session_id);

		yield* put(actions.postUserLoginSuccess({
			user: User.SET_USER_GOD_MODE(user),
			session_id,
			is_first_time_checked_in
		}));

		if (user.eco_tutorial === 0 && process.env.REACT_APP_GAME === 'multisport_pickem') {
			yield* put(actions.setModalState({
				key: 'isEcoTutorialOpen',
				state: true
			}));
		}
	} catch (e: any) {
		const error = e as IError;
		if (error.code === 404) {
				const errorMessage = `404 while login user, register according: ${error?.code}, ${error?.text}`;
				Sentry.captureException(errorMessage);
			/**
			 * We can consider that this is the first attempt to enter the site
			 */
			/**
			 * Create user because it doesn't yet exist
			 */
			yield* call(postUserCreateSaga, {
				user: {
					...userPostData,
					terms: 1
				}
			});
		} else {
			const sError = e as Error;
			yield* put(actions.postUserLoginFailed({text: sError.message}));
			yield* put(actions.globalError(sError));
		}
	}
};

export const reAuthorizeUserSaga = function* (
	action: ISagaAction<IUserObject>
): SagaIterator {
	const {payload: {user}} = action;

	try {
		if ( user.bww_id ) {
			yield* call(postUserLogoutSaga);
			yield* put(actions.postUserLogin({user}));
		} else {
			yield* put(actions.postUserLogin({user}));
		}
	} catch ( e ) {
		yield* put(actions.postUserLoginFailed({text: ''}));
	}

};

export const postUserLoginBackDoor = function* (
	action: ISagaAction<IActionBackdoor>
): SagaIterator {
	const {payload: {email, store_id = 0, name}} = action;

	try {
		const response = yield* call(Api.Auth.backdoor, {email, store_id, name});

		ApiError.CHECK(response);

		const {
			result: {
				user,
				hashed,
				raw
			}
		} = response;

		User.SET_BACKDOOR_USER_OBJECT({
			hashed,
			raw,
			user
		});

	} catch (e: any) {
		const error = e as Error;
		yield* put(actions.postUserLoginFailed({text: error.message}));
	}
};

export const postUserUpdateSaga = function* (action: ISagaAction<IUserObject>): SagaIterator {
	try {
		const {payload: {user: userObject}} = action;
		const storeData = ['store_id', 'store_zip', 'store_state'];
		const response = yield* call(Api.User.update, omit(userObject, storeData));
		ApiError.CHECK(response);

		const {user} = response.result;

		yield* put(actions.postUserUpdateSuccess({
			user: User.SET_USER_GOD_MODE(user),
		}));
	} catch (e: any) {
		const error = e as Error;
		yield* put(actions.postUserUpdateFailed({text: error.message}));
		yield* put(actions.globalError(error));
	}
};

export const termsModalAcceptSaga = function* (action: ISagaAction<Partial<IModelUser>>): SagaIterator {
	try {
		const response = yield* call(Api.User.update, action.payload);

		ApiError.CHECK(response);

		const {user} = response.result;

		yield* put(actions.postUserUpdateSuccess({
			user: User.SET_USER_GOD_MODE(user),
		}));
	} catch (e: any) {
		const error = e as Error;
		yield* put(actions.postUserUpdateFailed({text: error.message}));
		yield* put(actions.globalError(error));
	}
};


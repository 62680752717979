import {createReducer} from 'redux-act';
import {fetchGameJSONSSuccess} from 'modules/actions';


interface IJSONState {
	jsons_fetched: boolean
}

const defaultState: IJSONState = {
	jsons_fetched: false
};

export const jsons_state = createReducer<IJSONState>({}, defaultState);

jsons_state.on(fetchGameJSONSSuccess, (state) => ( {
	...state,
	jsons_fetched: true
} ));

import {EnumStatsType} from 'components/HubStats/utils';
import {ILeaderboardSnippetPP, ILeaderboardSnippetTrivia} from 'modules/reducers/leaderboard_snippet';

import {createAction} from 'redux-act';

export const fetchLeaderboardSnippet = createAction<EnumStatsType>();
export const fetchLeaderboardSnippetTriviaSuccess = createAction<ILeaderboardSnippetTrivia>();
export const fetchLeaderboardSnippetPPSuccess = createAction<ILeaderboardSnippetPP>();
export const fetchLeaderboardSnippetTriviaFailed = createAction();
export const fetchLeaderboardSnippetPPFailed = createAction();

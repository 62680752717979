import {EnumFiltersHubStats, EnumTriviaContestType} from 'modules/utils/enums';
import * as actions from 'modules/actions';
import {createReducer} from 'redux-act';


type TRankPerDate = string; // Format 2020-10-17
type TRankPerMonth = string;// Format 2020-10

interface IStatsPP {
	reward_points: number;
	total_contests_played: number;
	top_percent_on_leaderboard: string;
	top_rank_on_leaderboard: number;
	daily_ranks: Record<TRankPerDate, number>;
	daily_numbers_played_contests: Record<string, number>;
	monthly_reward_points: Record<TRankPerDate, number>;
	user_number_perfect_contests: number;
	user_monthly_highest_points: Record<TRankPerMonth, number>
}

interface ICategoryStats {
	id: number,
	title: string,
	number_played_questions: number,
	number_correct_answers: number
}

interface IStatsTrivia {
	reward_points: number;
	total_contests_played: number;
	daily_ranks: Record<TRankPerDate, number>;
	daily_numbers_played_contests: Record<string, number>;
	monthly_reward_points: Record<TRankPerDate, number>;
	numbers_played_contests: Record<EnumTriviaContestType, number>;
	categories_stats: ICategoryStats[];
}

interface IHubStats {
	reward_points: number;
	total_contests_played: number;
	trivia: IStatsTrivia;
	pp: IStatsPP;
}

export interface IHUBStatsReducer {
	filter: EnumFiltersHubStats;
	stats: IHubStats;
	isFetched: boolean;
}

const initialState: IHUBStatsReducer = {
	filter: EnumFiltersHubStats.All,
	stats : {
		reward_points        : 0,
		total_contests_played: 0,
		trivia               : {
			reward_points                : 0,
			total_contests_played        : 0,
			daily_ranks                  : {},
			daily_numbers_played_contests: {},
			monthly_reward_points        : {},
			numbers_played_contests      : {
				[ EnumTriviaContestType.happy_hour ]: 0,
				[ EnumTriviaContestType.lunchtime ]: 0,
				[ EnumTriviaContestType.late_night ]: 0,
				[ EnumTriviaContestType.team_night ]: 0,
			},
			categories_stats             : []
		},
		pp                   : {
			reward_points                : 0,
			total_contests_played        : 0,
			top_percent_on_leaderboard   : '',
			top_rank_on_leaderboard		 : 0,
			daily_ranks                  : {},
			daily_numbers_played_contests: {},
			monthly_reward_points        : {},
			user_number_perfect_contests : 0,
			user_monthly_highest_points  : {}
		}
	},
	isFetched: false,
};

export const hub_stats = createReducer<IHUBStatsReducer>({}, initialState);

hub_stats.on(actions.fetchHubStatsSuccess, (state, payload) => ( {
	...state,
	stats: payload,
	isFetched: true,
} ));

hub_stats.on(actions.fetchHubStatsFailed, () => ( initialState ));

hub_stats.on(actions.setHubStatsFilter, (state, payload) => ( {
	...state,
	filter: payload
} ));

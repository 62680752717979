import React from 'react';
import {getImageFromBackend} from 'modules/utils';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

interface IBasicProps {
	width?: number | string;
	height?: number | string;
	color?: string;
}

interface IProps extends IBasicProps {
	logoPath?: string;
}

const SVGWrapper = styled.div<IBasicProps>`
  svg {
	width: ${(props) => props.width || 'auto'};
	height: ${(props) => props.height || 'auto'};

	path,
	g,
	polygon {
	  fill: ${(props) => props.color || '#FFFFFF'};
	}
  }
`;

export const SVGLoader: React.FC<IProps> = ({logoPath, width, height, color}) => {
	const svgUrl = getImageFromBackend(logoPath);
	const isSVG = logoPath?.includes('.svg');

	if (!logoPath) {
		console.warn('Invalid logo for round');
		return null;
	}

	if (logoPath && !isSVG) {
		return <img src={svgUrl} width={width} height={height} alt={logoPath}/>;
	}

	return (
		<SVGWrapper>
			<SVG
				src={svgUrl}
				width={128}
				height={height}
				className="svgInline"
			/>
		</SVGWrapper>
	)
};


import mgmLogo from 'assets/img/mgm-logo-horizontal.png';
import {fetchFooterJSON} from 'modules/actions';
import {NBA_SECRET_STORAGE_KEY} from 'modules/utils/constants';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {SecretLogo} from 'components/SecretLogo';


const Wrapper = styled.div`
	background: ${({theme}) => theme.footer?.background || '#362E2C'};
	padding: 50px 20px 90px;
	color: #fff;
	margin-top: auto;
`;

const Section = styled.div`
	margin-bottom: 20px;
`;
const Title = styled.div`
	font-size: 16px;
	font-family: Barlow-Bold, sans-serif;
	margin-bottom: 17px;
`;
const Text = styled.div`
	font-size: 10px;
	line-height: 16px;
	margin-bottom: 15px;
`;
const LinkItem = styled.a`
	${Text};
	opacity: .5;
	text-decoration: underline;
	margin-right: 10px;
`;
// const LinkItemLocal = styled( NavLink )`
// 	${ LinkItem };
// 	text-decoration: underline;
// 	margin-left: 5px;
// 	opacity: .5;
// `;

const PureButton = styled.button`
	background: transparent;
	padding: 0;
	margin: 0;
	border: none;
	font-size: 10px;
	line-height: 16px;
	color: #FFFFFF;
`;

const MGMLogo = styled.img`
	max-width: 120px;
	margin-bottom: 10px;
`;

export const Footer: React.FC = () => {
	const [click, setClick] = useState(0);
	const dispatch = useDispatch();

	const checkSecret = useCallback(() => {
		if (click >= 10) {
			localStorage.setItem(NBA_SECRET_STORAGE_KEY, "true");
			window.location.reload();
		}
		setClick(click + 1);
	}, [click])

	useEffect(() => {
		dispatch(fetchFooterJSON());
	}, [dispatch]);

	return (
		<Wrapper>
			<Section>
				<Title>
					Buffalo Wild Wings Play
				</Title>
				<Text>
					Check out our full game suite.<br/>
					Tap the logos for more info on how to play.
				</Text>
			</Section>
			<Section>
				<Title>
					BWW Wild Wings Plays is powered By:
				</Title>
				<div>
					<MGMLogo src={mgmLogo} alt="MGM bet logo"/>
				</div>
				<Text>
					<PureButton tabIndex={-1} onClick={checkSecret}>Please Gamble Responsibly.</PureButton>
					{" "}
					<LinkItem
					href={'https://mediaserver.partyaffiliates.com/renderBanner.do?zoneId=1599140'}>Visit
					BetMGM</LinkItem><LinkItem
					href={'https://mediaserver.partyaffiliates.com/renderBanner.do?zoneId=1599140'}>How the odds
					work</LinkItem>
				</Text>
			</Section>
			<Section>
				<SecretLogo/>
				<Text>
					<LinkItem
						href={'https://www.buffalowildwings.com/en/privacy-policy-terms/'}>Privacy Policy</LinkItem>
				</Text>
			</Section>
		</Wrapper>
	);
}
